import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import BrandForm from "../../components/brandDialog";
import { recoilState } from "../../dataStructure";
import csvTOJSON from "../../import/importPlates";
import Eatery, { getAllEateries, getBrandEateries } from "../../model/eatery";
import Plate, { blankPlate, getPlates, platesRefreshState } from '../../model/plates';
import Tag, { getTags, tagRefreshState, tagType } from '../../model/tag';
import BrandGrid from "../../components/brandGrid";
import Brand, { brandRefreshState, getAllBrands } from "../../model/brand";
import { CustomBox, CustomHeadingBox } from "../../scenes/global/GlobalStyles";

function Brands() {
  const [appState, setAppState] = useRecoilState(recoilState);
  const brands = useRecoilValueLoadable<Brand[]>(getAllBrands);
  const restaurants = useRecoilValueLoadable<Eatery[]>(getBrandEateries);
  const plates = useRecoilValueLoadable<Plate[]>(getPlates);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const [tagState, setTagType] = useRecoilState(tagType);
  const setTagsRefresh = useSetRecoilState(tagRefreshState);
  const setBrandsRefresh = useSetRecoilState(brandRefreshState);
  // const [selectedPlate, setSelectedPlate] = useState<Plate>();
  const [open, setOpen] = useState(false);
  const [importResults, setImportResults] = useState({ recordsImported: 0, recordsRejected: 0 });
  const [uploadResultsOpen, setUploadResultsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadResults = () => setUploadResultsOpen(true);
  const closeUploadResults = () => setUploadResultsOpen(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    console.log("dialog open: " + appState.brandDialogOpen);
    setOpen(appState.brandDialogOpen);
  }, [appState.brandDialogOpen]);

  useEffect(() => {
    if (brands.state === "loading") {
      if (!loading) handleLoading();
    }
    else if (brands.state === "hasValue") {
      if (loading) handleLoaded();
    }
    else if (brands.state === "hasError") {
      handleLoaded();
    }
    if (tagState !== "cuisine_type") {
      setTagType("cuisine_type");
    }
  }, [brands.state, setTagType, loading]);

  async function setRefresh() {
    setTagsRefresh(new Date().getTime().toString());
    setBrandsRefresh(new Date().getTime().toString());
  }
 

  return (
    <Box m="10px">
      <CustomHeadingBox sx={{ display: "flex" }}>
        <Header
          title="Brands"
          subtitle="List of all Brands in the Only Plates Database" />
        <Box>
          <Button variant="contained" color="primary" onClick={() => { setAppState({ ...appState, currentPlate: { ...blankPlate }, plateDialogOpen: true }); }} disabled={(open) ? true : false} sx={{ ml: 2, fontWeight: "Bold" }}>
            Add a Brand
          </Button>
        </Box>
      </CustomHeadingBox>
      {/* {uploadResultsOpen &&
        <UploadComplete />} */}
      <CustomBox
        m="10px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            height:"auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "white",
            borderBottom: "none",
            height:"auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-virtualScroller": {
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "gray !important",
          },
        }}
      >
        <BrandGrid />
      </CustomBox>
      <BrandForm open={open} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleLoaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Brands;

