import { Box, Button, Chip, Stack, useTheme } from "@mui/material";
import { DataGrid, GridCellParams, GridColumns, GridComparatorFn, GridFilterItem, GridFilterModel, GridSortModel, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { styled } from '@mui/material/styles';
import { useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import CustomToolbar from "./CustomToolbar";
import { recoilState } from "../dataStructure";
import Eatery, { getAllEateries } from "../model/eatery";
import Plate, { getPlates, selectedPlateId } from '../model/plates';
import Tag, { getTags } from '../model/tag';
import { tokens } from "../theme";
import GridStyles from "./gridStyles";
import { getAllBrands, selectedBrandId } from "../model/brand";

// Define your custom styled Box
const CustomBox = styled(Box)(({ theme }) => ({
  padding: '0px', 
  height:"calc( 100vh - 120px)",
}));


function BrandGrid() {

  const [appState, setAppState] = useRecoilState(recoilState);
  const brands = useRecoilValueLoadable<Eatery[]>(getAllBrands);
  const plates = useRecoilValueLoadable<Plate[]>(getPlates);
  const setSelectedBrandId = useSetRecoilState(selectedBrandId);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const setSelectedBrand = (brand) => {
    console.log(brand);
    if (!brand) return;

    setSelectedBrandId(brand.brand_id);
    setAppState({ ...appState, currentBrand: brand, brandDialogOpen: true });
  }

    const tagsSortComparator: GridComparatorFn<any> = (tags1: any, tags2: any) => {
        return tags1.length - tags2.length;
      };
      const tagsFilterOperators = getGridSingleSelectOperators()
        .filter((operator) => operator.value === "isAnyOf")
        .map((operator) => {
          const newOperator = { ...operator };
          const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
            return (params: GridCellParams): boolean => {
              let isOk = true;
              filterItem?.value?.forEach((fv: any) => {
                isOk = isOk && params.value.includes(fv);
              });
              return isOk;
            };
          };
          newOperator.getApplyFilterFn = newGetApplyFilterFn;
          return newOperator;
        });
      const columns:GridColumns= [

        {
          field: "brand_id",
          headerName: "brand_id",
          valueFormatter: ({ value }) => `${value}`,
          hide: true,
        },
        {
          field: "name",
          headerName: "Name",
          sortable: false,
          flex: 2,
          valueFormatter: ({ value }) => `${value}`,
          renderCell: (params) => {
            const onClick = (e) => {
              e.stopPropagation(); // don't select this row after clicking
              const api = params.api; // @ts-ignore
              const thisRow: Brand = {};
    
              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                  (c) => (thisRow[c.field] = params.row[c.field])
                );
    
                setSelectedBrandId(thisRow.brand_id);
                setSelectedBrand({ ...brands.contents.find(({ brand_id }) => brand_id === thisRow.brand_id) });
              // return handleOpen()
            };
    
            return <Button key={`edit-button-${params.row.brand_id}`} onClick={onClick} fullWidth color="primary" style={{
              fontSize: '14px',
              textTransform: 'none',
              textAlign: 'left',
              justifyContent: 'flex-start'
            }}>{params.row.name}</Button>;
          }
        },
        {
          field: "description",
          headerName: "Description",
          flex: 1.5,
          cellClassName: "name-column--cell",
          disableExport: false,
        },
        {
          field: "website",
          headerName: "website",
          flex: 1,
        },
        {
          field: "eatery_count",
          headerName: "locations",
          flex: .5,
        },
        {
          field: "tags",
          headerName: "Tags",
          flex: 2,
          valueFormatter: ({ value }) => {
            if (value)
              return `*${value.join(",") + "*"}`;
    
            else
              return "**";
          },
          renderCell: (params) => (
            <Stack key={`chips-${params.row.plate_id}`} direction="row" spacing={0.25}>
              {params.row.tags && params.row.tags.map((tag: string) => (
                <Chip key={`chip-${tag}-${params.row.brand_id}`} label={tag} style={{
                  borderRadius: 10,
                  backgroundColor: colors.opGray[500],  
                  fontSize: '10px',
                  height: 'auto',
                  fontWeight:'900',
                  lineHeight: '2',
                  width:'auto',
                  padding:'0',
                  paddingLeft:'4px',
                  paddingRight:'4px',
                }}/>
              ))}
            </Stack>
          ),
          sortComparator: tagsSortComparator,
          filterOperators: tagsFilterOperators
        }
    ];
 return (

        (plates.state === "hasValue" && tags.state === "hasValue") &&
          // <PlateGrid/>
          <DataGrid
            onRowClick={(params) => {
              setSelectedBrandId(params.row.plate_id);
              setSelectedBrand({ ...brands.contents.find(({ brand_id }) => brand_id === params.row.brand_id) });
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            getRowId={(row) => row.brand_id}
            rows={brands.contents} // @ts-ignore
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            filterModel={appState.brandsFilterModel}
            onFilterModelChange={(newModel) => {
              setAppState({...appState, brandsFilterModel: newModel})
              setFilterModel(newModel);
            }} />
            
 )
}

export default BrandGrid;