import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import GooglePlaces from "./scenes/googleplaces";
import Plates from "./scenes/plates";
import Users from "./scenes/users";
import Reviews from "./scenes/reviews";
// import Invoices from "./scenes/eatery_tags";
import Eateries from "./scenes/eateries";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Box, Button, Dialog, Container, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { recoilState, LocalStorageKey } from './dataStructure';
import { useRecoilState } from 'recoil';
import { adminLogin } from "./model/user";
import Dashboard from "./scenes/opdashboard";
import Brands from "./scenes/brands";


function App() {
  const [appState, setAppState] = useRecoilState(recoilState);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [open, setOpen] = useState(!appState.isLoggedIn);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  useEffect(() => {
    window.localStorage.setItem(
        LocalStorageKey.APP_STATE,
        JSON.stringify(appState)
    )
}, [appState])

useEffect(() => {
  console.log("token received: ", appState.adminToken)
}, [appState.adminToken])

  const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    console.log("loggedIn: ", appState.loggedIn)
    async function login() {
      if (username !== "" && password !== "")
        {
            const loginResponse = await adminLogin(username, password)
            console.log("log in true");
            console.log(loginResponse);
            if (loginResponse.session_token) {
              setAppState({...appState, loggedIn: true, adminToken: loginResponse.session_token});
              handleClose();
              return
            } else {
              alert("log in falied");
              setAppState({...appState, loggedIn: false, adminToken: null});
              return
            }
        } else {
            alert("log in falied");
            setAppState({...appState, loggedIn: false, adminToken: null});
            return
        }
  }
  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        autoFocus
        sx={{
          backgroundColor: "white !important",
          "& .MuiDialog-container": {
            backgroundColor: "white !important",
          },
          "& .MuiPaper-root": {
            boxShadow: "none",
          },
          "& .MuiFormControl-root": {
            marginBottom:"10px",
            width: "100%"
          },
          "& .MuiDialogActions-root": {
            paddingRight:"25px"
          },
          "& .MuiButtonBase-root": {
            backgroundColor:"#DB0032",
            color:"white",
            textTransform:"none",
            fontWeight:"900",
            marginRight:"20px"
          },
          "& .MuiTypography-root": {
            fontSize: "20px",
            paddingLeft:"55px",
            fontWeight:"900"
          }
        }}
        
      >
        <Box sx={{ margin:"0 auto" }}>
          <img
          src="onlyplates-logo-opadmin.svg"
          alt="OnlyPlates Admin"
          width="250"
        />
        </Box>
        <DialogTitle id="form-dialog-title">Login</DialogTitle>
        <DialogContent>
        <Container>

        
        <Box sx={{ width: 400, marginTop: 2 }}>
          <TextField
            id="username"
            label="Username"
            defaultValue={"username"}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            fullWidth
          />
          <TextField
            id="password"
            label="password"
            type="password"
            sx={{ width: '34ch' }}
            defaultValue={"password"}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={login} color="secondary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    );
  }


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {(appState.loggedIn && appState.adminToken) &&
          <Sidebar isSidebar={isSidebar} />
          }
          <main className="content" style={{backgroundColor: "#F4F0EA"}}>
            {/* <Topbar setIsSidebar={setIsSidebar} /> */}
            {(!appState.loggedIn || !appState.adminToken) &&
              <Login/>
            }
            {(appState.loggedIn && appState.adminToken) &&
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/brands" element={<Brands />} />
              <Route path="/eatery" element={<Eateries />} />
              <Route path="/googleplaces" element={<GooglePlaces />} />
              <Route path="/plates" element={<Plates />} />
              <Route path="/plate_reviews" element={<Plates />} />
              <Route path="/users" element={<Users />} />
              <Route path="/reviews" element={<Reviews />} />
            </Routes>
            }
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
