import { putTag } from "../model/tag";

//var csv is the CSV file with headers
export default async function csvTOJSON(file, tagList = []): Promise<any> {

    var reader = new FileReader();
    return new Promise((resolve, reject) => {
    reader.onload = async (e) => {
        const csv = (e.target.result)
        // Convert the data to String and
        // split it in an array
        const importedPlates = csv.toString().split("\r");

        // All the rows of the CSV will be
        // converted to JSON objects which
        // will be added to result in an array
        let result = [];

        // The array[0] contains all the
        // header columns so we store them
        // in headers array
        let headers = importedPlates[0].split(",");
        // 4 headers if there are no tag exclusions 5 if there are
        if(headers.length < 4) resolve([]);
        // headers.length = 4;
        console.log(headers);
        // Since headers are separated, we
        // need to traverse remaining n-1 rows.
        for (let i = 1; i <= importedPlates.length - 1; i++) {
            try {
            let obj = {}

            // Create an empty object to later add
            // values of the current row to it
            // Declare string str as current array
            // value to change the delimiter and
            // store the generated string in a new
            // string s
            let str = importedPlates[i]
            let s = ''

            // clean up the strings in easch row so we have clear delimiters
            let flag = 0
            for (let ch of str) {
                if (ch === '"' && flag === 0) {
                    flag = 1;
                }
                else if (ch === '"' && flag == 1) flag = 0;
                if (ch === ', ' && flag === 0) ch = '|';
                if (ch !== '"') s += ch.replace("\n", "");
            }

            // console.log(s);
            let findTags = s.split(`,*`)
            let findExclusions = findTags.length < 2 ? [] :findTags[1].split(`,^`)
            let tags = findExclusions.length > 1 ? findExclusions[0].replace("*", "").split(",") : findTags[1].replace("*", "").split(",").filter((tag) => tag !== "");
            let properties = findTags[0].split(",")
            let exclusions = findExclusions.length === 1 ? [] : findExclusions[1].replace("^", "").split(",")
            // properties = findExclusions[0].split(",")
            console.log(exclusions);
            console.log(tags);
            for (let j in headers) {
                if(headers[j].toLocaleLowerCase().toLocaleLowerCase() == "tags") {
                    obj[headers[j].toLocaleLowerCase()] = findTags[1] === "*" ? [] : tags;
                }
                else if(headers[j].toLocaleLowerCase().toLocaleLowerCase() == "excludetags") {
                    obj[headers[j].toLocaleLowerCase()] = findExclusions[1] === "^" ? [] : exclusions;
                }
                else if (properties[j]) {
                    obj[headers[j].toLocaleLowerCase()] = properties[j].replace("|", ", ").trim();
                }
            }
            result.push(obj);
        } catch(error) {
            console.log(error);
            continue;
        }
        }

        console.log(result);
        resolve(result);
    };
    // read the text file...
    reader.readAsText(file);
    });

}