import Resizer from "react-image-file-resizer";

export interface PhotoResult {
  canceled: boolean;
  error?: Error;
}
export interface PhotoSource {
  uri: string;
  key?: string;
  headers?: [];
}

const apiURL = process.env.REACT_APP_ENVIRONMENT == "dev" ? process.env.REACT_APP_IMAGE_API_URL : process.env.REACT_APP_IMAGE_API_STAGE;
const imageReturnURL = process.env.REACT_APP_ENVIRONMENT == "dev" ? process.env.REACT_APP_IMAGE_S3_BUCKET : process.env.REACT_APP_IMAGE_S3_BUCKET_STAGE;
export const pickImage = async (file, user_id: string): Promise<PhotoResult | any> => {
  const resizeFile = (file, width, height) => new Promise<Blob>(resolve => {
    Resizer.imageFileResizer(file, width, height, 'JPEG', 80, 0,
      uri => {
        // @ts-ignore
        resolve(uri);
      }, 'blob');
  });
  const thumbnail: Blob = await resizeFile(file, 225, 225);
  const resizedImage: Blob = await resizeFile(file, 800, 800);
  try {
    const uploadedThumbnail = await uploadPhoto(thumbnail, user_id, false, true)
    const uploadedPhoto = await uploadPhoto(resizedImage, user_id, false, false, uploadedThumbnail.key)
    console.log(uploadedPhoto)
    return uploadedPhoto
  } catch (error) {
    console.log(error);
    return { canceled: true, error: error }
  }
}

export const uploadPhoto = async (uri: Blob, user_id: string, isProfilePhoto: boolean = true, isThumbnail: boolean = false, useExistingKey: string = "0"): Promise<Response | any> => {
  const blob = uri;
  return new Promise((resolve, reject) => {
    const timeStringUri = new Date().getTime();
    let requestUri: string;
    let returnUri: string;
    const uidKey: string = useExistingKey !== "0" ? useExistingKey : timeStringUri.toString() + '-' + user_id;
    const photoKey = (isThumbnail ? 'thumbnails/' : '') + uidKey;
    requestUri = apiURL + 'images/' + (isThumbnail ? 'thumbnail_' : '') + uidKey + '.jpg';
    returnUri = imageReturnURL + photoKey + '.jpg'
    console.log(requestUri)
    const fetchRequest = fetch(requestUri, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'image/jpeg',
      },
      body: blob
    }).then((response) => {
      console.log("uploaded");
      // console.log(JSON.stringify(response));
    }).finally(() => {
      resolve({ canceled: false, uri: returnUri, key: uidKey })
    }).catch(err => {
      console.log(err);
      throw new Error("photo upload failed")
      // return {canceled: false, error: err}
    })
  });
}
