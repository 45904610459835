import { Box, Button, Chip, Stack, useTheme } from "@mui/material";
import { DataGrid, GridCellParams, GridColumns, GridComparatorFn, GridFilterItem, GridFilterModel, GridSortModel, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import CustomToolbar from "./CustomToolbar";
import { recoilState } from "../dataStructure";
import Eatery, { getAllEateries } from "../model/eatery";
import Plate, { getPlateById, getPlates, selectedPlateId } from '../model/plates';
import Tag, { getTags } from '../model/tag';
import { tokens } from "../theme";
import GridStyles from "./gridStyles";
import { CustomBox, CustomHeadingBox } from "../scenes/global/GlobalStyles";

function PlateGrid({ loading, handleOpen }) {

  const [appState, setAppState] = useRecoilState(recoilState);
  const plates = useRecoilValueLoadable<Plate[]>(getPlates);
  const setSelectedPlateId = useSetRecoilState(selectedPlateId);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const setSelectedPlate = async (plate) => {
    console.log("setSelectedPlate");
    if (!plate) return;


    setSelectedPlateId(plate.plate_id);
    const fullPlate:Plate = await getPlateById(plate.plate_id);
    if (fullPlate){
      console.log("fullPlate");
      console.log(fullPlate);
      setAppState({ ...appState, currentPlate: fullPlate, plateDialogOpen: true });
    } else {

    setAppState({ ...appState, currentPlate: plate, plateDialogOpen: true });
    }
  }

  const tagsSortComparator: GridComparatorFn<any> = (tags1: any, tags2: any) => {
    return tags1 && tags2 ? tags1.length - tags2.length : 0;
  };
  const eaterySortComparator: GridComparatorFn<any> = (eatery1: any, eatery2: any) => {
    return eatery1.name - eatery2.name;
  };
  const tagsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === "isAnyOf")
    .map((operator) => {
      const newOperator = { ...operator };
      const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
        return (params: GridCellParams): boolean => {
          let isOk = true;
          filterItem?.value?.forEach((fv: any) => {
            isOk = isOk && params.value.includes(fv);
          });
          return isOk;
        };
      };
      newOperator.getApplyFilterFn = newGetApplyFilterFn;
      return newOperator;
    });
  const columns: GridColumns = [
    {
      field: "photo_url",
      headerName: "",
      sortable: false,
      renderCell: (params) => <img src={params.value} width="50" height="50" />,
      disableExport: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      cellClassName: "name-column--cell",
      disableExport: false,
    },
    {
      field: "restaurant_id",
      headerName: "Eatery",
      flex: 2,

      renderCell: (params) => params.row.eatery ? params.row.eatery.name : "",
      valueGetter: (params) => {
        if (params.value && params.row.restaurant_id)
          return params.row.restaurant_id;
        else {
          console.log(`bad restaurant id on plate ${params.row.plate_id} | ${params.row.name}`);
          return "";
        }
      },
      sortComparator: eaterySortComparator,
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: .5,
      disableExport: true,
    },
    {
      field: "rating_count",
      headerName: "#Ratings",
      flex: .5,
      disableExport: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: .5,
      disableExport: true,
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 2,
      valueFormatter: ({ value }) => {
        if (value)
          return `*${value.join(",") + "*"}`;
        else
          return "**";
      },
      renderCell: (params) => (
        <Stack flexWrap="wrap" key={`chips-${params.row.plate_id}`} direction="row" spacing={0.25}>
          {params.row.tags && params.row.tags.map((tag: string, index: number) => (
            <Chip style={{margin: "2px"}} size="small" key={`chip-${tag}-${index}-${params.row.plate_id}`} label={tag} />
          ))}
        </Stack>
      ),
      sortComparator: tagsSortComparator,
      filterOperators: tagsFilterOperators
    },
    {
      field: "plate_id",
      headerName: "",
      sortable: false,
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => {
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api; // @ts-ignore
          const thisRow: Plate = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.row[c.field])
            );
          setSelectedPlateId(thisRow.plate_id);
          setSelectedPlate(thisRow);
          // return handleOpen(true);
        };

        return <Button key={`edit-button-${params.row.plate_id}`} onClick={onClick} fullWidth color="primary" sx={{
          borderRadius: 10,
          backgroundColor: "rgb(219, 0, 50)",
          color: "white",
          fontWeight: "900",
          letterSpacing: "0.05rem",
          lineHeight: "1.5",
          width: "auto",
          padding: "0px 8px",
          maxWidth: "80px",
          minWidth: "unset",
          fontSize: "10px",

        }}>Edit</Button>;
      }
    }
  ];
  return (

    (plates.state === "hasValue" && tags.state === "hasValue" && !loading) &&
    // <PlateGrid/>
    <DataGrid
      autoHeight
      getRowHeight={() => "auto"}
      onRowClick={async (params) => {
        await setSelectedPlate(params.row);
        setSelectedPlateId(params.row.plate_id);
      }}
      loading={loading}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      getRowId={(row) => row.plate_id}
      rows={plates.contents} // @ts-ignore
      columns={columns}
      components={{ Toolbar: CustomToolbar }}
      filterModel={appState.platesFilterModel}
      onFilterModelChange={(newModel) => {
        setAppState({ ...appState, platesFilterModel: newModel })
        setFilterModel(newModel);
      }} />

  )
}

export default PlateGrid;