import { Alert, AlertTitle, Backdrop, Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import Header from "../../components/Header";
import PlateForm from "../../components/plateDialog";
import { recoilState } from "../../dataStructure";
import csvTOJSON from "../../import/importPlates";
import Eatery, { getAllEateries } from "../../model/eatery";
import Plate, { blankPlate, getPlate, getPlates, platesRefreshState } from '../../model/plates';
import Tag, { getTags, tagRefreshState, tagType } from '../../model/tag';
import PlateGrid from "../../components/plateGrid";
import { CustomBox, CustomHeadingBox } from "../../scenes/global/GlobalStyles";
import { RegionInfo, getRegions } from "../../model/region";

function Plates() {
  const [appState, setAppState] = useRecoilState(recoilState);
  const plate = useRecoilValueLoadable<Plate>(getPlate);
  const regions = useRecoilValueLoadable<RegionInfo[]>(getRegions);
  const restaurants = useRecoilValueLoadable<Eatery[]>(getAllEateries);
  const plates = useRecoilValueLoadable<Plate[]>(getPlates);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const [tagState, setTagType] = useRecoilState(tagType);
  const setTagsRefresh = useSetRecoilState(tagRefreshState);
  const setPlatesRefresh = useSetRecoilState(platesRefreshState);
  // const [selectedPlate, setSelectedPlate] = useState<Plate>();
  const [open, setOpen] = useState(false);
  const [importResults, setImportResults] = useState({ recordsImported: 0, recordsRejected: 0 });
  const [uploadResultsOpen, setUploadResultsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadResults = () => setUploadResultsOpen(true);
  const closeUploadResults = () => setUploadResultsOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    console.log("dialog open: " + appState.plateDialogOpen);
    setOpen(appState.plateDialogOpen);
  }, [appState.plateDialogOpen]);

  useEffect(() => {
    if (plates.state === "loading") {
      if (!loading) handleLoading();
    }
    else if (plates.state === "hasValue") {
      if (loading) handleLoaded();
    }
    else if (plates.state === "hasError") {
      handleLoaded();
    }
    if (tagState !== "plate_tag") {
      setTagType("plate_tag");
    }
  }, [plates.state, setTagType, loading, tagState]);

  async function setRefresh() {
    setTagsRefresh(new Date().getTime().toString());
    setPlatesRefresh(new Date().getTime().toString());
  }
  async function uploadFile(event) {
    handleLoading();
    // await new Promise(r => setTimeout(r, 2000));
    let file = event.target.files[0];
    console.log(file);
    const results = await csvTOJSON(file, tags.contents, restaurants.contents, appState.currentRegionId.toString());
    // @ts-ignore
    setImportResults(results);
    setRefresh();
    handleLoaded();
    uploadResults();
  }
  function UploadComplete() {
    return (
      <Alert variant="filled" severity="info" onClose={() => { closeUploadResults(); }}>
        <AlertTitle>Import Results</AlertTitle>
        {Number(importResults.recordsImported) - Number(importResults.recordsRejected)} plate(s) imported. {importResults.recordsRejected} plate(s) skipped.
      </Alert>
    );
  }

  return (
    <Box m="10px" height="100%" width="100%">
      <CustomHeadingBox display="flex" flexDirection="row" flex="1" margin="5px">
        <Header
          title="Plates"
          subtitle="List of all Plates in NWA" />
        <Box display="flex" flexDirection="row" flex="1">
          <Box>
          {(regions.state === "hasValue") &&
              <FormControl style={{width: "100%"}}>
                <Select
                  // labelId="region-select-small"
                  // label="Eatery"
                  sx={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", }}
                  id="region-select"
                  fullWidth
                  notched={true}
                  value={appState.plateViewParams ? appState.plateViewParams.region_id : appState.currentRegionId ? appState.currentRegionId :"select"}
                  onChange={e => {
                    console.log(e.target.value);
                    setAppState({...appState, currentRegionId: Number(e.target.value), plateViewParams: {...appState.plateViewParams, region_id: Number(e.target.value), status: 1, restaurant_id: undefined}})
                  }}
                >
                  <MenuItem key={`region-select--2`}
                    value={"select"}>Select an Region</MenuItem>
                  {regions.state === "hasValue" && regions.contents.map((r: RegionInfo, index) => (
                    <MenuItem key={`region-select-${index}`}
                      value={r.region_id}
                      selected={(appState.currentRegionId ? r.region_id === appState.currentRegionId : false)}>{r.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Box>
          <Box>
          <FormControl style={{width: "100%"}}>
                <Select
                  // labelId="region-select-small"
                  // label="Eatery"
                  sx={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", }}
                  id="status-select"
                  fullWidth
                  notched={true}
                  value={appState.plateViewParams.status ? appState.plateViewParams.status.toString() : 5}
                  onChange={e => {
                    console.log(e.target.value);
                    if (e.target.value === "5")
                      setAppState({...appState, plateViewParams: {...appState.plateViewParams, status: undefined}})
                    else
                      setAppState({...appState, plateViewParams: {...appState.plateViewParams, status: Number(e.target.value)}})
                  }}
                >
                <MenuItem key={`status-select--2`}
                  value={"5"}>Any</MenuItem>
                  <MenuItem key={`status-select--2`}
                    value={"1"}>Active</MenuItem>
                  <MenuItem key={`status-select--3`}
                    value={"0"}>Inactive</MenuItem>
                  <MenuItem key={`status-select--4`}
                    value={"-3"}>Templates</MenuItem>
                </Select>
              </FormControl>
          </Box>
          <Box>
            {(restaurants.state === "hasValue") &&
              <FormControl>
                <Select
                  // labelId="eatery-select-small"
                  // label="Eatery"
                  sx={{ textOverflow: "ellipsis", overflow: "hidden", }}
                  id="eatery-select"
                  fullWidth
                  notched={true}
                  value={appState.plateViewParams && appState.plateViewParams.restaurant_id ? appState.plateViewParams.restaurant_id : "select"}
                  onChange={e => {
                    console.log(e.target.value);
                    if (e.target.value === "select") {
                      setAppState({...appState, plateViewParams: {...appState.plateViewParams, restaurant_id: undefined}});}
                    else {
                      setAppState({...appState, plateViewParams: {...appState.plateViewParams, restaurant_id: e.target.value}});
                    }
                  }}
                >
                  <MenuItem key={`eatery-select--2`}
                    value={"select"}>Select an Eatery</MenuItem>
                  {restaurants.state === "hasValue" && restaurants.contents.map((e: Eatery, index) => (
                    <MenuItem key={`eatery-select-${index}`}
                      value={e.restaurant_id}
                      selected={(appState.plateViewParams && appState.plateViewParams.restaurant_id ? e.restaurant_id === appState.plateViewParams.restaurant_id : false)}>{e.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Box>
          <Box display="flex" flexDirection="row" >
            <Box>
              <Button variant="contained" color="secondary" onClick={() => { hiddenFileInput.current.click(); }} sx={{ ml: 2, fontWeight: "Bold" }}>Import</Button>
              <input type="file"
                ref={hiddenFileInput}
                name="myFile"
                onChange={uploadFile}
                hidden />
            </Box>
            <Box>
              <Button variant="contained" color="secondary" onClick={() => { setAppState({ ...appState, currentPlate: { ...blankPlate }, plateDialogOpen: true }); }} disabled={(open) ? true : false}  style={{padding:"10px"}} sx={{ml: 2, fontWeight: "Bold" }}>Add&nbsp;a&nbsp;Plate</Button>
            </Box>
          </Box>
        </Box>
      </CustomHeadingBox>
      {uploadResultsOpen &&
        <UploadComplete />}


      <CustomBox
        hidden={loading}
        m="0 0 0 0"
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            height: "auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "white",
            borderBottom: "none",
            height: "auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-virtualScroller": {
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "gray !important",
          },
        }}
      >
        <PlateGrid loading={loading} handleOpen={setOpen} />
      </CustomBox>
      {plate.state === "hasValue" &&
      <PlateForm open={open} plate={plate} />
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleLoaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Plates;

