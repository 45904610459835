import { Alert, AlertTitle, Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import Header from "../../components/Header";
import PlateForm from "../../components/plateDialog";
import { recoilState } from "../../dataStructure";
import csvTOJSON from "../../import/importPlates";
import Eatery, { getAllEateries } from "../../model/eatery";
import Plate, { blankPlate, getPlates, platesRefreshState } from '../../model/plates';
import Tag, { getTags, tagRefreshState, tagType } from '../../model/tag';
import PlateGrid from "../../components/plateGrid";
import { CustomBox, CustomHeadingBox } from "../../scenes/global/GlobalStyles";
import UserGrid from "../../components/userGrid";
import { blankUser } from "../../model/user";
import UserForm from "../../components/userDialog";

function Users() {
  const [appState, setAppState] = useRecoilState(recoilState);
  const restaurants = useRecoilValueLoadable<Eatery[]>(getAllEateries);
  const plates = useRecoilValueLoadable<Plate[]>(getPlates);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const [tagState, setTagType] = useRecoilState(tagType);
  const setTagsRefresh = useSetRecoilState(tagRefreshState);
  const setPlatesRefresh = useSetRecoilState(platesRefreshState);
  // const [selectedPlate, setSelectedPlate] = useState<Plate>();
  const [open, setOpen] = useState(false);
  const [importResults, setImportResults] = useState({ recordsImported: 0, recordsRejected: 0 });
  const [uploadResultsOpen, setUploadResultsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const uploadResults = () => setUploadResultsOpen(true);
  const closeUploadResults = () => setUploadResultsOpen(false);
  const handleLoading = () => setLoading(true);
  const handleLoaded = () => setLoading(false);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    console.log("dialog open: " + appState.userDialogOpen);
    setOpen(appState.userDialogOpen);
  }, [appState.userDialogOpen]);

  useEffect(() => {
    if (plates.state === "loading") {
      if (!loading) handleLoading();
    }
    else if (plates.state === "hasValue") {
      if (loading) handleLoaded();
    }
    else if (plates.state === "hasError") {
      handleLoaded();
    }
    if (tagState !== "plate_tag") {
      setTagType("plate_tag");
    }
  }, [plates.state, setTagType, loading, tagState]);

  async function setRefresh() {
    setTagsRefresh(new Date().getTime().toString());
    setPlatesRefresh(new Date().getTime().toString());
  }
  async function uploadFile(event) {
    handleLoading();
    // await new Promise(r => setTimeout(r, 2000));
    let file = event.target.files[0];
    console.log(file);
    const results = await csvTOJSON(file, tags.contents, restaurants.contents, plates.contents);
    // @ts-ignore
    setImportResults(results);
    setRefresh();
    handleLoaded();
    uploadResults();
  }
  function UploadComplete() {
    return (
      <Alert variant="filled" severity="info" onClose={() => { closeUploadResults(); }}>
        <AlertTitle>Import Results</AlertTitle>
        {Number(importResults.recordsImported) - Number(importResults.recordsRejected)} plate(s) imported. {importResults.recordsRejected} plate(s) skipped.
      </Alert>
    );
  }

  return (
    <Box m="10px">
      <CustomHeadingBox sx={{ display: "flex" }}>
        <Header
          title="User"
          subtitle="List of all Mobile App Users" />
        {/* <Box>
          <Button variant="contained" color="secondary" onClick={() => { hiddenFileInput.current.click(); }} sx={{ ml: 2, fontWeight: "Bold" }}>Import</Button>
          <input type="file"
            ref={hiddenFileInput}
            name="myFile"
            onChange={uploadFile}
            hidden />
        </Box> */}
        {/* <Box>
          <Button variant="contained" color="secondary" onClick={() => { setAppState({ ...appState, currentUser: { ...blankUser }, userDialogOpen: true }); }} disabled={(open) ? true : false} sx={{ ml: 2, fontWeight: "Bold" }}>Add a User</Button>
        </Box> */}
      </CustomHeadingBox>
      {/* {uploadResultsOpen &&
        <UploadComplete />} */}


      <CustomBox
        m="0 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            height:"auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "white",
            borderBottom: "none",
            height:"auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-virtualScroller": {
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiCheckbox-root": {
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "gray !important",
          },
        }}
      >
        <UserGrid />
      </CustomBox>
     
      <UserForm open={open} />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleLoaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Users;

