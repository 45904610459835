import { Box, Button, Chip, Stack, useTheme } from "@mui/material";
import { DataGrid, GridCellParams, GridColumns, GridComparatorFn, GridFilterItem, GridFilterModel, GridSortModel, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import CustomToolbar from "./CustomToolbar";
import { recoilState } from "../dataStructure";
import Eatery, { getAllEateries } from "../model/eatery";
import User, { getUsers, selectedUserId } from '../model/user';
import Tag, { getTags } from '../model/tag';
import { tokens } from "../theme";
import GridStyles from "./gridStyles";
import { CustomBox, CustomHeadingBox } from "../scenes/global/GlobalStyles";

function UserGrid() {

  const [appState, setAppState] = useRecoilState(recoilState);
  const users = useRecoilValueLoadable<User[]>(getUsers);
  const setSelectedUserId = useSetRecoilState(selectedUserId);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  const setSelectedUser = (user) => {
    console.log(user);
    if (!user) return;

    setSelectedUserId(user.user_id);
    setAppState({ ...appState, currentUser: user, userDialogOpen: true });
  }

    const tagsSortComparator: GridComparatorFn<any> = (tags1: any, tags2: any) => {
        return tags1.length - tags2.length;
      };
      const tagsFilterOperators = getGridSingleSelectOperators()
        .filter((operator) => operator.value === "isAnyOf")
        .map((operator) => {
          const newOperator = { ...operator };
          const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
            return (params: GridCellParams): boolean => {
              let isOk = true;
              filterItem?.value?.forEach((fv: any) => {
                isOk = isOk && params.value.includes(fv);
              });
              return isOk;
            };
          };
          newOperator.getApplyFilterFn = newGetApplyFilterFn;
          return newOperator;
        });
      const columns:GridColumns= [
        // {
        //   field: "user_id",
        //   headerName: "user_id",
        //   hide: true,
        // },
        {
          field: "email",
          headerName: "email",
          flex: 2,
          cellClassName: "name-column--cell",
          disableExport: false,
        },
        {
          field: "first_name",
          headerName: "first_name",
          flex: .5,
          disableExport: true,
        },
        {
          field: "last_name",
          headerName: "last_name",
          flex: .5,
          disableExport: true,
        },
        {
          field: "currentRegion",
          headerName: "Region",
          flex: .5,
          valueFormatter: ({ value }) => value !== undefined ? value : "",
          disableExport: true,
        },
        {
          field: "undefined",
          headerName: "Push",
          flex: .5,
          renderCell: (params) => (
            <Chip key={`chip-push-${params.row.user_id}`} style={{width: "100%"}} label={params.row.user_attributes && params.row.user_attributes.pushToken ? "yes" : "no"} color={params.row.user_attributes &&  params.row.user_attributes.pushToken ? "secondary" : "warning"} size="small" />
          ),
          disableExport: true,
        },
        {
          field: "user_attributes",
          headerName: "Tags",
          flex: 2,
        //   valueFormatter: ({ value }) => {
        //     if (value)
        //       return `*${value.join(",") + "*"}`;
    
        //     else
        //       return "**";
        //   },
          renderCell: (params) => (
            <Stack key={`chips-${params.row.user_id}`} direction="row" spacing={0.25} width={"100%"} flexWrap="wrap">
              {params.row.user_attributes && params.row.user_attributes.cusine_prefs && params.row.user_attributes.cusine_prefs.map((pref: any, index:number) => (
                <Chip key={`${index}chip-${pref.id}-${params.row.user_id}`} label={pref.name} sx={{marginTop: "1px"}} size="small" />
              ))}
            </Stack>
          ),
          sortComparator: tagsSortComparator,
          filterOperators: tagsFilterOperators
        },
        {
          field: "user_id",
          headerName: "",
          sortable: false,
          valueFormatter: ({ value }) => `${value}`,
          renderCell: (params) => {
            const onClick = (e) => {
              e.stopPropagation(); // don't select this row after clicking
              const api = params.api; // @ts-ignore
              const thisRow: User = {};
    
              api
                .getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach(
                  (c) => (thisRow[c.field] = params.row[c.field])
                );
    
                setSelectedUserId(thisRow.user_id);
                setSelectedUser({ ...users.contents.find(({ user_id }) => user_id === thisRow.user_id) });
            //   return handleOpen()
            };
    
            return <Button key={`edit-button-${params.row.user_id}`} onClick={onClick} fullWidth color="primary" sx={{
              borderRadius: 10,
              backgroundColor: "rgb(219, 0, 50)",
              color: "white",
              fontWeight: "900",
              letterSpacing: "0.05rem",
              lineHeight: "1.5",
              width:"auto",
              padding: "0px 8px",
              maxWidth: "80px",
              minWidth: "unset",
              fontSize: "10px",

            }}>View</Button>;
          }
        }
    ];
 return (
 
        (users.state === "hasValue" && tags.state === "hasValue") &&
          // <UserGrid/>
          <DataGrid
            onRowClick={(params) => {
              setSelectedUserId(params.row.user_id);
              setSelectedUser({ ...users.contents.find(({ user_id }) => user_id === params.row.user_id) });
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            getRowId={(row) => row.user_id}
            rows={users.contents} // @ts-ignore
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            filterModel={appState.usersFilterModel}
            onFilterModelChange={(newModel) => {
              setAppState({...appState, usersFilterModel: newModel})
              setFilterModel(newModel);
            }} />
            
 )
}

export default UserGrid;