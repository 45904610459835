import { authFetch } from "./authFetch";
import Region, { blankRegion } from "./region";
import { recoilState } from "../dataStructure";
import { getRecoil, setRecoil } from "recoil-nexus";
import { atom, selector } from "recoil";
// import bcrypt from 'bcryptjs'

export default interface Push {
    title: string;
    subTitle: string;
    message: string;
}
export async function bulkPush(message:Push): Promise<any> {
    console.log("push/all/");
    console.log(message)
    const appState = getRecoil(recoilState);
    const data = await authFetch("push/all/", {
        method: "POST",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(message)
    });
    const returnData = await data;
    console.log(returnData);
    return returnData
}
