import { atom, selector } from "recoil";
import { authFetch } from "./authFetch";

export default interface Tag {
    tag:         string;
    tag_type:      string;
}
export async function getTagsByType(type:string): Promise<any> {
    // const apiURL = "https://r4ybrlem56.execute-api.us-east-2.amazonaws.com/Prod/";
      const data = await authFetch("tags/" + type, {
          method: "GET",
          mode: 'cors',
          headers: { 'Content-Type': 'application/json' }
      });
      const returnData = await data;
      console.log({"get tags by type": returnData});
      return returnData
  }
  export async function putTag(tag:Tag): Promise<any> {
    console.log(JSON.stringify(tag));
    try {
        const data = await authFetch("tags", {
            method: "POST",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(tag)
        });
        const response = await data;
        return await response
    } catch(error) {
        console.log(error)
        return
    }
}
// Atoms...
  export const tagRefreshState = atom<string>({
    key: "tagRefreshState",
    default: new Date().getTime().toString()
  });
  export const tagType = atom<string>({
    key: "tagType",
    default: 'cuisine_type'
  }); 
  export const getTags = selector({
    key: 'getAllTags',
    get: async ({get}) => {
      const hookTags = get(tagRefreshState)
      const type = get(tagType)
      try {
        console.info("loading eateries");
        const tags = await getTagsByType(type)
  
        return tags || [];
      } catch (err) {
        console.info("error loading eateries");
        console.error(err);
        return [];
      }
    }
  });
  
  export const tagState = atom({
    key: 'tags',
    default: getTags
  })

enum TagType {
    cuisine_type = "Cusine",
    special_diet = "Special Diet",
    plate_type = "Plate Type",
    ingredient = "Ingredient",
  }