import { Autocomplete, Box, Checkbox, FormControl, FormGroup, FormControlLabel, InputLabel, TextField, useTheme, Button } from "@mui/material";
import Eatery, { getBrandEateries } from "../model/eatery";
import Plate, { platesRefreshState, selectedPlateId } from "../model/plates";
import { PlateTemplate } from "../model/brand";
import Tag, { getTags, putTag, tagRefreshState } from "../model/tag";
import { useState, useRef, useEffect } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { recoilState } from "../dataStructure";
import { tokens } from "../theme";
import SaveIcon from '@mui/icons-material/Save';

function BrandPlateForm({ plate, save }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [appSrestaurantstate, setAppState] = useRecoilState(recoilState);
    const restaurants = useRecoilValueLoadable<Eatery[]>(getBrandEateries);
    const tags = useRecoilValueLoadable<Tag[]>(getTags);
    const [currentPlate, setCurrentPlate] = useState<PlateTemplate>({ ...plate });
    const [updatedPlates, setUpdatedPlates] = useState<Plate[]>(plate.plates ? [...plate.plates] : []);
    const [plateId, setPlateId] = useState(currentPlate.template.plate_id);
    const [name, setName] = useState(currentPlate.template.name);
    const [restaurantIds, setRestaurantIds] = useState(currentPlate.template.restaurant_ids);
    const [rating, setRating] = useState("0");
    const [ratingCount, setRatingCount] = useState("0");
    const [photoUrl, setPhotoUrl] = useState("https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png");
    const [plateTags, setPlateTags] = useState(currentPlate.template.tags);
    const [plateStatus, setPlateStatus] = useState(0);
    const setSelectedPlateId = useSetRecoilState(selectedPlateId);
    const setTagsRefresh = useSetRecoilState(tagRefreshState);
    const setPlatesRefresh = useSetRecoilState(platesRefreshState);
    const hiddenPhotoFileInput = useRef(null);
    const [plateUpdated, setPlateUpdated] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let restaurant = JSON.parse(event.target.value);
        console.log(restaurant);
        let index = restaurantIds.indexOf(restaurant.restaurant_id);
        // console.log(event.target.checked);
        // console.log(index);
        // console.log(restaurantIds);
        if (index !== -1) {
            setRestaurantIds([...restaurantIds].toSpliced(index, 1));
            // plate will be removed from the unchecked location
            // check to see if the plate was already existing or was going to just be added
            const existingPlate = updatedPlates.findIndex((plate) => plate.restaurant_id === restaurant.restaurant_id);
            // remove the eatery from the template.
            setUpdatedPlates([...updatedPlates].toSpliced(existingPlate, 1));
        } else {
            const ids = [...restaurantIds]
            ids.push(event.target.name);
            setRestaurantIds([...ids]);
            const plates = [...updatedPlates]
            const template = {...currentPlate.template};
            delete template.restaurant_ids
            const updatedPlate: Plate = plate.plates.find((plate) => plate.restaurant_id === restaurant.restaurant_id) ?
                {
                    ...plate.plates.find((plate) => plate.restaurant_id === restaurant.restaurant_id),
                    name: name,
                    tags: plateTags,
                    status: 0,
                } : {
                    ...template,
                    plate_id: "",
                    name: name,
                    tags: plateTags,
                    reviews: [],
                    rating: 0,
                    rating_count: 0,
                    ratings: [],
                    users: [],
                    restaurant_id: restaurant.restaurant_id,
                    eatery: restaurant
                };
            plates.push({ ...updatedPlate })
            setUpdatedPlates([...plates]);
        }
        console.log(updatedPlates);
    };

    useEffect(() => {
        console.log(updatedPlates);
    }, [updatedPlates]);

    useEffect(() => {
        const plates = [...updatedPlates].map((plate) => {
            var newPlate = {...plate};
            newPlate.name = name;
            return newPlate;
          });
        setUpdatedPlates(plates);
    }, [name]);

    useEffect(() => {
        const plates = [...updatedPlates].map((plate) => {
            var newPlate = {...plate};
            newPlate.tags = plateTags;
            return newPlate;
          });
        setUpdatedPlates(plates);
    }, [plateTags]);

    return (
        <Box sx={{ m: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: "column", gap: .5, }}>
                <TextField
                    id="name-select-small"
                    label="Plate Name"
                    value={name}
                    fullWidth
                    sx={{ mt: 1 }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
                {tags.state === "hasValue" &&
                    <Autocomplete
                        multiple
                        freeSolo
                        sx={{ mt: 1 }}
                        options={tags.contents.map((tag) => (tag.tag))}
                        getOptionLabel={option => option}
                        value={plateTags}
                        onChange={(event: any, newValue: string[] | null) => {
                            newValue.forEach((newTag) => {
                                console.log(newTag);
                                console.log(tags.contents.findIndex((tag) => tag.tag === newTag));
                                if (tags.contents.findIndex((tag) => tag.tag === newTag) === -1)
                                    putTag({ tag: newTag, tag_type: "plate_tag" });
                            });
                            setPlateTags(newValue);
                        }}
                        renderInput={params => (
                            <TextField {...params} label="Tags" fullWidth />
                        )}
                    />
                }

                {(restaurants.state === "hasValue") &&
                    <FormControl sx={{ mt: 2 }}>
                        <InputLabel id="eatery-select-small" shrink={true} sx={{}} htmlFor="eatery-select">Plate Locations</InputLabel>
                        <FormGroup>
                            {restaurants.state === "hasValue" && restaurants.contents.map((restaurant: Eatery, index) => (//@ts-ignore
                                <FormControlLabel key={`plate-template-eatery-select${restaurant.restaurant_id}`}
                                    control={<Checkbox
                                        onChange={handleChange}
                                        checked={restaurantIds.findIndex((id) => id === restaurant.restaurant_id) > -1}
                                        value={JSON.stringify(restaurant)}
                                        name={restaurant.restaurant_id} />}
                                    label={restaurant.name + " - " + restaurant.city} />
                            ))}
                        </FormGroup>
                    </FormControl>
                }
                <Button startIcon={<SaveIcon />}
                    onClick={() => {save({
                        ...currentPlate,
                        template: {
                            ...currentPlate.template,
                            name: name, tags: plateTags
                        }
                    },
                        [...updatedPlates])
                        console.log(name);
                }
                    }
                    color="primary">Save Plate</Button>
            </Box>
        </Box>
    );
}
export default BrandPlateForm;
