import { atom, selector } from "recoil";
import { authFetch } from "./authFetch";
import Plate from "./plates";
import Eatery from "./eatery";
import UserPost from "./review";
// import bcrypt from 'bcryptjs'

export default interface Brand {
    brand_id: string;
    name: string;
    description: string;
    website: string;
    eatery_count: number;
    last_updated: number;
    tags: string[];
}
export interface IPlateTemplate extends Plate {
    restaurant_ids: string[]
  }
export interface PlateTemplate {
    template: IPlateTemplate,
    plates: Plate[]
  }
export const blankBrand: Brand = { brand_id: "", name: "", description: "", website: "", eatery_count: 0, tags: [], last_updated: new Date().getTime() };
// export const fallbackImage= {uri: process.env.USER_FALLBACK_IMAGE};
export async function getPlatesByBrandId(brand_id: string): Promise<Plate[]> {
    const data = await authFetch("brand/plates/" + brand_id, {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    console.log(returnData);
    return returnData

}
export async function getPlatesByTemplateId(brand_id: string, plate_template_id: string): Promise<any> {
    const data = await authFetch("brand/plates/" + brand_id + "/" + plate_template_id, {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    console.log(returnData);
    return returnData

}

export async function deleteBrandById(brand_id: string): Promise<any> {
    //   console.log("delete eatery");
    //   const data = await authFetch("deleatery/" + restaurant_id, {
    //       method: "GET",
    //       mode: 'cors',
    //       headers: { 'Content-Type': 'application/json' }
    //   });
    //   const returnData = await data;
    //   return returnData

}

export async function getBrands(): Promise<any> {
    const data = await authFetch("brands", {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    return returnData
}

export async function putBrand(brand: Brand): Promise<any> {
    try {
        const data = await authFetch("brands", {
            method: "POST",
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(brand)
        });
        const response = await data;
        return await response
    } catch (error) {
        console.log(error)
        return
    }
}

// Atoms...
export const selectedBrandId = atom({
    key: 'selectedBrandId',
    default: ''
})
export const selectedPlateTemplateId = atom({
    key: 'selectedPlateTemplateId',
    default: ''
})
export const brandRefreshState = atom<string>({
    key: "brandRefreshState",
    default: new Date().getTime().toString()
});
export const getAllBrands = selector({
    key: 'getAllBrands',
    get: async ({ get }) => {
        const hook = get(brandRefreshState);
        try {
            console.log(hook);
            console.info("loading eateries");
            const eateries = await getBrands();
            return eateries.sort(function (a: Brand, b: Brand) {
                var textA = a.name.toUpperCase().trim();
                var textB = b.name.toUpperCase().trim();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }) || [];
        } catch (err) {
            console.info("error loading eateries");
            console.error(err);
            return [];
        }
    }
});
export const getBrandPlateTemplates = selector({
    key: 'getBrandPlates',
    get: async ({ get }) => {
        const brandId = get(selectedBrandId);
        const hook = get(brandRefreshState);
        try {
            if (brandId === "") return;
            console.info("loading eateries");
            const platesResults = await getPlatesByBrandId(brandId);
            platesResults.forEach((plate) => {
                if (!plate.photo_url) {
                  plate.photo_url = "https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png";
                }
                if (!plate.photos) {
                  plate.photos = [];
                }
              });
            // get just the base templates
            const templates:Plate[] = [...platesResults].filter((plate) => plate.plate_id === plate.plate_template_id);

            const plateTemplates:PlateTemplate[] = [];
            templates.forEach((template) => {
                // @ts-ignore
                let platesTemplate:PlateTemplate = {}
                platesTemplate.template = {...template} as IPlateTemplate;
                platesTemplate.plates = [...platesResults].filter((plate: Plate) => (template.plate_template_id === plate.plate_template_id && plate.plate_template_id !== plate.plate_id)) as Plate[];
                platesTemplate.template.restaurant_ids = [];
                platesTemplate.plates.forEach((plate: Plate) => {
                    platesTemplate.template.restaurant_ids.push(plate.restaurant_id)
                    })
                    plateTemplates.push(platesTemplate);
            });
            console.log("templates")
            console.log(plateTemplates)
            return plateTemplates.sort(function (a: PlateTemplate, b: PlateTemplate) {
                var textA = a.template.name.toUpperCase().trim();
                var textB = b.template.name.toUpperCase().trim();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })

        } catch (err) {
            console.info("error loading plates for brand");
            console.error(err);
            return null;
        }
    }
});
export const getBrandPlatesForTemplate = selector({
    key: 'getBrandPlatesForTemplate',
    get: async ({ get }) => {
        const brandId = get(selectedBrandId);
        const plateTemplateId = get(selectedPlateTemplateId);
        try {
            console.info("loading eateries");
            const plates = await getPlatesByTemplateId(brandId, plateTemplateId);
            return plates.sort(function (a: Plate, b: Plate) {
                var textA = a.plate_template_id.toUpperCase().trim();
                var textB = b.plate_template_id.toUpperCase().trim();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }).filter((plate) => plate.plate_id === plate.plate_template_id) || [];
        } catch (err) {
            console.info("error loading plates for brand");
            console.error(err);
            return null;
        }
    }
});
export const brandsState = atom({
    key: 'brands',
    default: getAllBrands
})
