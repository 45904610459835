import { Box } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    label: string;
}
function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, label, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`${label}-tabpanel-${index}`}
        aria-labelledby={`${label}-tab-${index}`}
        style={{overflow: "hidden", maxHeight: "100%"}}
        {...other}
      >
        {value === index && (
          <Box sx={{maxHeight: "100%", overflow: "hidden", overflowY: "auto"}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
export default CustomTabPanel;
