import { Alert, AlertTitle, Autocomplete, Box, Button, ButtonProps, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tokens } from "../../theme";

// Define your custom styled Box
export const CustomBox = styled(Box)(({ theme }) => ({

  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: '0px', 
  height:"calc( 100vh - 90px)",
  "& .MuiDataGrid-row": {
    backgroundColor: 'white',
    // marginTop:"2px !important",
    // marginBottom:"2px !important",
    borderRadius:"10px",
    fontSize: "12px !important",
    // maxHeight: "unset !important",
    // minHeight: "unset !important",
    // height:"auto !important",
    // lineHeight:"1 !important",
    border:"none"
  },
  "& .MuiDataGrid-toolbarContainer": {
    // position:"absolute",
    // top:"-62px",
    display: "flex",
    flexDirection: "row-reverse",
    flex: 1,
    alignItems: "right",
    justify: "flex-end",
    marginBottom: "5px"
    // right:"212px",
    // zIndex:"4",

  },
  "& .MuiBox-root": {
    maxHeight: "unset !important",
    minHeight: "unset !important",
    height:"auto !important",
    lineHeight:"1 !important",
  },
  "& .MuiTextField-root": {
    background:"white",
    borderRadius:"30px",
    paddingBottom: "0",
  },
  "& .MuiSvgIcon-root": {
    paddingLeft:"10px",
    width:"30px",
  },
  "& .MuiInput-underline:before": {
    display:"none"
  },
  "& .MuiDataGrid-footerContainer": {
    backgroundColor:"white !important",
    padding:"0"
  }
}));

export const CustomHeadingBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "& .MuiBox-root:first-of-type": {
    flex:"1",
  },
  "& .MuiButtonBase-root:last-child": {
    borderRadius: "20px",
    boxShadow: "none",
    color: "white",
    padding:"0",
    paddingLeft:"6px",
    paddingRight:"6px",
    fontSize:"12px",
    textTransform:"none",
    backgroundColor:"#DB0032",
  },
  "& .MuiButtonBase-root": {
    background: "none",
    boxShadow: "none",
    color: "#25282F",
  }
}));