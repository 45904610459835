import { ButtonProps } from "@mui/material";
import { GridToolbarContainerProps, GridToolbarContainer, GridToolbarExportContainer, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarQuickFilter } from "@mui/x-data-grid";



function CustomToolbar(props: GridToolbarContainerProps, exportType: string) {
  const csvOptions: GridCsvExportOptions = { fileName: `OnlyPlatesExport-${new Date().getTime().toString()}` };
  function CustomExportButton(props: ButtonProps) {
    return (
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem options={csvOptions} />
      </GridToolbarExportContainer>
    );
  }
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
