import Region, { RegionInfo, blankRegion } from "./region";
import Location, { blankLocation } from "./location";
import { atom, selector } from "recoil";
import { authFetch } from "./authFetch";
import { selectedBrandId } from "./brand";
import { recoilState } from "../dataStructure";
import Plate from "./plates";
// import bcrypt from 'bcryptjs'

export default interface Eatery {
  restaurant_id:        string;
  brand_id?:        string;
  region_id:          string;
  name:  string;
  address:     string;
  city:      string;
  state:  string;
  zip:  string;
  location:      Location;
  tags:    string[];
  plates?: Plate[];
  inactivePlates?: Plate[];
  distance?: number | null;
  region?:          Region | RegionInfo;
  isNew?: boolean | false;
}
export const blankEatery:Eatery = {restaurant_id: "", region_id: "1", region: blankRegion, name: "", address: "", city: "", state: "", zip: "", location: blankLocation, tags: []};

// export const fallbackImage= {uri: process.env.USER_FALLBACK_IMAGE};
export async function getEateryById(restaurant_id: string): Promise<any> {
    const data = await authFetch("eatery/" + restaurant_id, {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    console.log(returnData);
    return returnData

}
export async function getEateriesByBrandId(brand_id: string): Promise<any> {
  const data = await authFetch("brand/eateries/" + brand_id, {
      method: "GET",
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  console.log(returnData);
  return returnData

}

export async function deleteEateryById(restaurant_id: string): Promise<any> {
  console.log("delete eatery");
  const data = await authFetch("deleatery/" + restaurant_id, {
      method: "GET",
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData

}

export async function getEateries(region_id:number = 1): Promise<Eatery[]> {
  const data = await authFetch("region/eateries/" + region_id, {
    method: "GET",
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  // console.log(returnData);
  return returnData;
}

export async function putEatery(eatery:Eatery): Promise<any> {
        try {
            const data = await authFetch("eatery", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(eatery)
            });
            const response = await data;
            console.log(response);
            return await response
        } catch(error) {
            console.log(error)
            return
        }
}

// Atoms...
export const selectedEateryId = atom({
  key: 'selectedEateryId',
  default: ''
})
export const eateryRefreshState = atom<string>({
    key: "eateryRefreshState",
    default: new Date().getTime().toString()
  });
  export const getBrandEateries = selector({
    key: 'getBrandEateries',
    get: async ({get}) => {
      const brandId = get(selectedBrandId);
      if (brandId === "") return [];
      try {
        console.info("loading eateries");

        const eateries = await getEateriesByBrandId(brandId)
        // const profilesList = await getProfiles()
  
        return eateries.sort(function(a:Eatery, b:Eatery) {
          var textA = a.name.toUpperCase().trim();
          var textB = b.name.toUpperCase().trim();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }) || [];
      } catch (err) {
        console.info("error loading eateries");
        console.error(err);
        return [];
      }
    }
  });
  export const getAllEateries = selector({
    key: 'getAllEateries',
    get: async ({get}) => {

      const state = get(recoilState);
      try {
        console.info("loading eateries");
        const eateries = await getEateries(state.currentRegionId);
        // const profilesList = await getProfiles()
  
        return eateries.sort(function(a:Eatery, b:Eatery) {
          var textA = a.name.toUpperCase().trim();
          var textB = b.name.toUpperCase().trim();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        }) || [];
      } catch (err) {
        console.info("error loading eateries");
        console.error(err);
        return [];
      }
    }
  });
  
  export const eateryState = atom({
    key: 'profiles',
    default: getAllEateries
  })
