import { Box, Grid, Typography, CircularProgress, TextField, Button } from "@mui/material";
import { useRecoilValueLoadable } from "recoil";
import { usersCountState } from "../../dataStructure";
import Header from "../../components/Header";
import { CustomHeadingBox } from "../global/GlobalStyles";
import { getPlates } from '../../model/plates';
import { getAllEateries } from '../../model/eatery';
import { useState } from "react";
import { bulkPush } from "../../model/push";


const Dashboard = () => {
  const platesLoadable = useRecoilValueLoadable(getPlates);
  const eateriesLoadable = useRecoilValueLoadable(getAllEateries);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [message, setMessage] = useState("");

  let plateCount = 0;
  let eateriesCount = 0;
  let isLoading = platesLoadable.state === 'loading' || eateriesLoadable.state === 'loading';

  // Plate Count
  if (platesLoadable.state === 'hasValue') {
    plateCount = platesLoadable.contents.length;
  }
  // Eatery Count
  if (eateriesLoadable.state === 'hasValue') {
    eateriesCount = eateriesLoadable.contents.length;
  }
  // Users Count
  const usersCountLoadable = useRecoilValueLoadable(usersCountState);
  let usersCount = 0;

  if (usersCountLoadable.state === 'hasValue') {
    usersCount = usersCountLoadable.contents;
  }


  async function clickSend(event: any): Promise<any> {
    const send = async () => {
      console.log("sending messages");
      await bulkPush({
        title: title,
        subTitle: subtitle,
        message: message
      })
    }
    return window.confirm(`Are you sure you to send this message?\n\n${title}\n${subtitle}\n${message}\n\nThis message be sent to all users and cannot be undone!`) ? await send() : null;
  }

  return (
    <Box m="20px">
      <CustomHeadingBox sx={{ display: "flex" }}>
        <Header
          title="Dashboard"
          subtitle="A current list of status for all things plate." />
      </CustomHeadingBox>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={2} sx={{
          margin: "20px 0px",
          '& h2': {
            fontSize: '16px !important',
          },
          '& .MuiGrid-item': {
            padding: "5px"
          },
          '& .MuiBox-root': {
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "6px"
          },
          '& .MuiBox-root .MuiBox-root': {
            padding: "0"
          }
        }}>
          <Grid item xs={4}>
            <Box>
              <Header title="Eateries" />
              <Typography sx={{ fontSize: '36px' }}>{eateriesCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Header title="Plates" />
              <Typography sx={{ fontSize: '36px' }}>{plateCount}</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Header title="Users" />
              <Typography sx={{ fontSize: '36px' }}>{usersCount}</Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Box m="20px">
        <Header title="Send a Bulk Push Notification" />
        <TextField
          id="title"
          label="Title"
          placeholder={"Title"}
          value={title}
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          fullWidth
          sx={{ backgroundColor: "white", marginBottom: "5px" }}
        />
        <TextField
          id="subtitle"
          label="Subtitle"
          placeholder={"Subtitle"}
          value={subtitle}
          onChange={(event) => {
            setSubtitle(event.target.value);
          }}
          fullWidth
          sx={{ backgroundColor: "white", marginBottom: "5px" }}
        />
        <TextField
          id="message"
          label="Message"
          placeholder={"Message"}
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
          }}
          multiline
          minRows={3}
          fullWidth
          sx={{ backgroundColor: "white", marginBottom: "5px" }}
        />
        <Button onClick={clickSend} color="warning" style={{
          fontSize: '14px',
          textTransform: 'none',
          textAlign: 'center',
          justifyContent: 'flex-start'
        }}>
          Send Push Notification
        </Button>
      </Box>
    </Box>
  );
};

export default Dashboard;

