
const GridStyles = (colors) => {
    return {
        "& .MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-cell": {
            borderBottom: "none",
            paddingBottom: 1,
            marginBottom: "1px !important",
            backgroundColor: colors.white[100],
        },
        "& .name-column--cell": {
            color: colors.redAccent[300],
            fontWeight: "bold",
        },
        "& .MuiDataGrid-root .name-column--row": {
            color: colors.white[100],
        },
        "& .review-column--cell": {
            fontSize: "-1",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold"
        },
        "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.white[100],
            borderBottom: "none",
            minHeight: "13px !important",
            lineHeight: "13px !important",
        },
        "& .MuiDataGrid-iconSeparator": {
            color: colors.redAccent[300],
        },
        "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.redAccent[700],
        },
        "& .MuiCheckbox-root": {
            color: `${colors.redAccent[200]} !important`,
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
            outline: "none !important"
        }
    }
}
export default GridStyles;