import Region, { blankRegion } from "./region";
import Location, { blankLocation } from "./location"
import Tag from "./tag"
import { atom, selector } from "recoil";
import Eatery, { blankEatery, selectedEateryId } from "./eatery";
import UserPost from "./review"
import { authFetch } from "./authFetch";
import { PlatesViewParams, recoilState } from "../dataStructure";

export default interface Plate {
  plate_id: string;
  region_id: string;
  restaurant_id: string;
  date_time?: string;
  name: string;
  b_average: number;
  rating: number;
  ratings: number[];
  rating_count: number;
  eatery: Eatery;
  photo_url: string;
  photos?: string[];
  photo_is_sticky?: boolean;
  reviews: UserPost[];
  townRank?: number;
  plate_template_id: string;
  brand_id: string;
  status: number;
  users: string[];
  tags: string[];
  view_count?: number;
}
export const blankPlate: Plate = { plate_id: "", status: 0, plate_template_id: "", brand_id: "", restaurant_id: "", name: "", b_average: 0, reviews: [], rating: 0, ratings: [], rating_count: 0, photo_url: "", eatery: blankEatery, users: [], tags: [], region_id: "1" };
export async function getPlateById(plate_id: string): Promise<any> {
  console.log("plates/" + plate_id);
  if(!plate_id || (plate_id === "0" || plate_id === "")) {
    return blankPlate;
  }
  const data = await authFetch("plates/" + plate_id, {
    method: "GET",
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData
}

export async function importCheck(criteria: {plate_id:string, name: string, restaurant_id: string}): Promise<Plate> {
  console.log(criteria);
  const data = await authFetch("plates/import/check/", {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify(criteria),
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData
}

export async function getPlatesByEatery(restaurant_id: string): Promise<any> {
  console.log("plates/eateries/" + restaurant_id);
  const data = await authFetch("plates/eateries/" + restaurant_id, {
    method: "GET",
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData
}

export async function getAllPlatesGrid(params: PlatesViewParams): Promise<any> {
  console.log(JSON.stringify(params));
  const data = await authFetch("plates/grid/", {
    method: "POST",
    mode: 'cors',
    body: JSON.stringify(params),
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData
}

export async function getAllPlates(): Promise<any> {
  const data = await authFetch("plates", {
    method: "GET",
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  return returnData
}

export async function createNewPlate(plate: Plate): Promise<any> {
  console.log(JSON.stringify(plate));
  try {
    const data = await authFetch("admin/plates/create", {
      method: "POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(plate)
    });
    const response = await data;
    // console.log(response);
    return await response
  } catch (error) {
    console.log(error)
    return
  }
}
export async function putPlate(plate: Plate): Promise<any> {
  console.log(JSON.stringify(plate));
  try {
    const data = await authFetch("plates", {
      method: "POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(plate)
    });
    const response = await data;
    // console.log(response);
    return await response
  } catch (error) {
    console.log(error)
    return
  }
}
export async function putPlates(plates: Plate[]): Promise<any> {
  console.log(JSON.stringify(plates));
  try {
    const data = await authFetch("plates/multi", {
      method: "POST",
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(plates)
    });
    const response = await data;
    // console.log(response);
    return await response
  } catch (error) {
    console.log(error)
    return
  }
}
export async function deletePlateById(plate_id: string): Promise<any> {
  console.log("delete eatery");
  const data = await authFetch("delplates/" + plate_id, {
    method: "GET",
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' }
  });
  const returnData = await data;
  console.log(returnData);
  return returnData

}
// Atoms...
export const platesRefreshState = atom<string>({
  key: "platesRefreshState",
  default: new Date().getTime().toString()
});
export const selectedPlateId = atom({
  key: 'selectedPlateId',
  default: '0'
})
export const getPlate = selector<Plate | any>({
  key: 'getPlate',
  get: async ({ get }) => {
    const hook = get(platesRefreshState);
    const plateId = get(selectedPlateId);
    try {
      console.info("loading plates");
      const plate = await getPlateById(plateId);

      console.log("get plate");
      console.log(plate)

      if (!plate) return {}
      if (!plate.photo_url) {
        plate.photo_url = "https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png";
      }
      if (plate.photos) {
        plate.photos = [];
      }
      return plate;

    } catch (err) {
      console.info("error loading plates");
      console.error(err);
      return [];
    }
  }
});
export const getPlates = selector({
  key: 'getAllPlates',
  get: async ({ get }) => {
    const hook = get(platesRefreshState);
    const state = get(recoilState);
    console.log(state.plateViewParams);
    if (!state.plateViewParams) return [];
    try {
      console.info("loading plates");
      const plates = await getAllPlatesGrid(state.plateViewParams);

      // console.log(plates)

      if (!plates || plates.length === 0) return []
      try {
        plates.forEach((plate) => {
          if (!plate.photo_url) {
            plate.photo_url = "https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png";
          }
          if (!plate.photos) {
            plate.photos = [];
          }
        });
        return plates
        // return plates.filter((plate: Plate) => plate.plate_template_id !== plate.plate_id)
      }
      catch {
        return [];
      }
    } catch (err) {
      console.info("error loading plates");
      console.error(err);
      return [];
    }
  }
});

export const platesState = atom({
  key: 'platesState',
  default: getPlates
})