import { atom, selector } from "recoil";
import { authFetch } from "./authFetch";

export default interface Region {
    region:         string;
    region_id:      string;
    location:        {};
}
export const blankRegion:Region = {region:"Northwest Arkansas", region_id:"1", location: {}}

export interface RegionInfo {
    region_id: number;
    name: string;
    name_short?: string;
    location: {
        center: {
            latitude: number,
            longitude: number,
        },
        radius: number
    };
    active_plate_count: number; // 34
    rating_count: number; // 79 rating_count/plate_count = avg num of ratings 2.32
    ratings_total: number; // 130.22 ratings_total/plate_count = avg rating = 3.8
    total_plate_count: number; // used for dashboard stats in admin
}
export async function getAllRegions(): Promise<any> {

    const data = await authFetch("regions", {
        method: "GET",
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    return returnData;
}
export async function checkRegion(latitude: number, longitude: number): Promise<any> {
    const data = await authFetch("regions", {
        method: "POST",
        mode: 'cors',
        body: JSON.stringify({latitude, longitude}),
        headers: { 'Content-Type': 'application/json' }
    });
    const returnData = await data;
    return returnData;
}


// Atoms...
export const regionRefreshState = atom<string>({
  key: "regionRefreshState",
  default: new Date().getTime().toString()
});

export const getRegions = selector({
  key: 'getAllRegions',
  get: async ({ get }) => {
    const hookRegions = get(regionRefreshState);
    try {
      console.info("loading regions: " + hookRegions);
      const regions = await getAllRegions();
      const plateSort = (a: RegionInfo, b: RegionInfo) => {

        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        }
        return 0;
        // return ratingB - ratingA
        // return (ratingB < ratingA) ? -1 : (ratingB > ratingA) ? 1 : (vcB < vcB) ? -1 : (vcB > vcA) ? 1 : 0;
    }
    //   console.info(regions);
      return regions.sort(plateSort) || [];
    } catch (err) {
      console.info("error loading regions");
      console.error(err);
      return [];
    }
  }
});

export const regionState = atom({
  key: 'regions',
  default: getRegions
});

