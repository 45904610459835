import readEateryCsv from "./readEateryImport";
import { putTag } from "../model/tag";
import Eatery, { putEatery } from "../model/eatery";
import GooglePlacesSearch, { GooglePlacesResult, _placesSearch } from "../model/googlePlacesSearch";
import { RegionInfo, blankRegion } from "../model/region";
import { locationBiasF } from "../model/location_defaults";

//var csv is the CSV file with headers
export default async function csvTOJSON(file, tagList = [], eateries = [], region: RegionInfo):Promise<{}> {

    const imports:Eatery[] = await readEateryCsv(file, tagList);
    const linesToOmit:number[] = [];

    function arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    console.log(imports.length);
    console.log(imports);

    for (let i in imports) {
        // process the tags...
        for (let j in imports[i].tags) {
            if (imports[i].tags[j] !== "" && tagList.findIndex((tag) => tag.tag === imports[i].tags[j]) === -1) {
                console.log(`new tag: ${imports[i].tags[j]}`);
                // add a new tag
                await putTag({tag: imports[i].tags[j], tag_type: "cuisine_type"});
            }
        }
        // check to see if we have of an existing eatery to update
        if (imports[i].restaurant_id !== "") {
            let existingEatery:Eatery = eateries.find((eatery) => eatery.restaurant_id === imports[i].restaurant_id)
            if (existingEatery) {
                
                console.log("Eatery Found:");
                console.log(existingEatery);

                // has the address changed?
                if (existingEatery && existingEatery.address !== imports[i].address) {
                    // different addreee
                    console.log("adddress has changed");
                    console.log(existingEatery.address);
                    console.log("to");
                    console.log(imports[i].address);
                    let searchCriteria:GooglePlacesSearch = {
                        textQuery: `${imports[i].address} ${imports[i].city}, ${imports[i].state}`
                    }
                    let placesResults = await _placesSearch(searchCriteria);
                    console.log(placesResults);
                    if(placesResults.length > 0) {
                        imports[i].location = { latitude: placesResults[0].location.latitude, longitude: placesResults[0].location.longitude };
                    } else {
                        console.log(`ommitting: line ${i}`)
                        linesToOmit.push(Number(i));
                    }
                    // this needs to be built-out to support multiple regions
                    imports[i].region = {...blankRegion, location: locationBiasF};
                } else {
                    console.log("address is the same");
                    if(existingEatery && arrayEquals(imports[i].tags, existingEatery.tags) && existingEatery.name === imports[i].name) {
                        console.log(`ommitting: line ${i}`)
                        linesToOmit.push(Number(i));
                    }
                    imports[i].location = existingEatery.location;
                    imports[i].region = existingEatery.region;
                }

            }
        } else {
            // this is potentially a new eatery
                // lets see if we already have one with that name or address
                let searchCriteria:GooglePlacesSearch = {
                    textQuery: `${imports[i].address} ${imports[i].city}, ${imports[i].state}`,
                    lat: region.location.center.latitude,
                    long: region.location.center.longitude,
                }
                let placesResults = await _placesSearch(searchCriteria);
                console.log(placesResults);
                console.log(placesResults.length);// @ts-ignore
                if(placesResults && placesResults.places) {// @ts-ignore
                    let place:GooglePlacesResult = placesResults.places[0];
                    imports[i].location = { latitude: place.location.latitude, longitude: place.location.longitude };
                    imports[i].restaurant_id = place.id;
                } else {
                    console.log(`ommitting: line ${i}`)
                    linesToOmit.push(Number(i));
                }
                // this needs to be built-out to support multiple regions
                // imports[i].region = {...blankRegion, location: locationBiasF};
        }
    }

    // now inset the resords on at a time
    console.log(linesToOmit);
    for (let i in imports) {
        console.log(i);
        console.log(!linesToOmit.includes(Number(i)))
        if(!linesToOmit.includes(Number(i)))  {
            await putEatery({...imports[i], region_id: region.region_id.toString()});
        }
    }
    
    return {recordsImported: imports.length, recordsRejected: linesToOmit.length}


}