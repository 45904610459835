
import Location, { LocationBias } from "../model/location";
import getRectangleBounds from "../utility/getRectangleBounds";

export default interface GooglePlacesSearch {
  textQuery?: string;
  lat?: number;
  long?: number;
  minRating?: string;
  includedType?: string;
  locationRestriction?: LocationBias;
  rankPreference?: string;
  radius?: number;
}
export type GooglePlacesResults = {places: GooglePlacesResult[]}
export type GooglePlacesResult = {
  id: string;
  displayName: displayName;
  formattedAddress: string;
  location: Location;
  types: string[];
  rating?: string;
  primaryType?: string;

}
export type displayName = {
  text: string;
  languageCode: string;

}
const googleEateryTypes = [
  "american_restaurant",
  "bakery",
  "bar",
  "barbecue_restaurant",
  "brazilian_restaurant",
  "breakfast_restaurant",
  "brunch_restaurant",
  "cafe",
  "chinese_restaurant",
  "coffee_shop",
  "fast_food_restaurant",
  "french_restaurant",
  "greek_restaurant",
  "hamburger_restaurant",
  "ice_cream_shop",
  "indian_restaurant",
  "indonesian_restaurant",
  "italian_restaurant",
  "japanese_restaurant",
  "korean_restaurant",
  "lebanese_restaurant",
  "meal_delivery",
  "meal_takeaway",
  "mediterranean_restaurant",
  "mexican_restaurant",
  "middle_eastern_restaurant",
  "pizza_restaurant",
  "ramen_restaurant",
  "restaurant",
  "sandwich_shop",
  "seafood_restaurant",
  "spanish_restaurant",
  "steak_house",
  "sushi_restaurant",
  "thai_restaurant",
  "turkish_restaurant",
  "vegan_restaurant",
  "vegetarian_restaurant",
  "vietnamese_restaurant"
];
const defaultLocationBias = {
  circle: {
    center: {
      latitude: 30.2135335,
      longitude: -97.87451969999999
    },
    radius: "33000"
  }
};

export const blankCriteria: GooglePlacesSearch = { textQuery: "", lat: defaultLocationBias.circle.center.latitude, long: defaultLocationBias.circle.center.longitude, radius: 30 };
export async function _placesSearch(criteria: GooglePlacesSearch): Promise<GooglePlacesResult[]> {
  // console.log(criteria);
  const loc = {
    circle: {
      center: {
        latitude: criteria.lat ? criteria.lat : defaultLocationBias.circle.center.latitude,
        longitude: criteria.long ? criteria.long : defaultLocationBias.circle.center.longitude,
      },
      radius: (criteria.radius ? criteria.radius : 33) * 0.621371
    }
  };
  const rect = getRectangleBounds(loc.circle.center, loc.circle.radius);
  console.log(loc);
  const search: GooglePlacesSearch = { textQuery: criteria.textQuery, locationRestriction: rect }
  search.includedType = "restaurant";
  // console.log(search);
  try {
  const data1 = await fetch("https://places.googleapis.com/v1/places:searchText", {
    method: "POST",
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': 'AIzaSyA20F12NUNJWkKf2Po0fI2RF9D73gvXs3I',
      'X-Goog-FieldMask': 'places.id,places.displayName,places.formattedAddress,places.location,places.rating,places.types,places.primaryType'
    },
    body: JSON.stringify(search)
  });
  //
  const returnData1 = await data1.json();
  delete search.includedType
  delete search.minRating
  const data2 = await fetch("https://places.googleapis.com/v1/places:searchText", {
    method: "POST",
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': 'AIzaSyA20F12NUNJWkKf2Po0fI2RF9D73gvXs3I',
      'X-Goog-FieldMask': 'places.id,places.displayName,places.formattedAddress,places.location,places.rating,places.types,places.primaryType'
    },
    body: JSON.stringify(search)
  });
  //
  const returnData2 = await data2.json();
const returnData: GooglePlacesResults = {
  places: [
    ...returnData2.places,
    ...returnData1.places.filter((result: GooglePlacesResult) => {
      // Check if the id of result is not present in returnData2.places
      return !returnData2.places.find(
        (result2: GooglePlacesResult) => result2.id === result.id
      );
    })
  ]
};
  // console.info(JSON.stringify(returnData, null, 4));
  return returnData.places.length === 0 ? returnData.places : {...returnData, places: returnData.places.filter((result:GooglePlacesResult) =>{
    const types:string[] = result.types;
    // return true
    return types.some(type => googleEateryTypes.includes(type)) && !types.includes("fast_food_restaurant");
  })}.places;
} catch (e:any) {
  // console.log(e);
  return []
}

}