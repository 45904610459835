import React, { useState } from 'react';
import {
    Box, Typography, Card, CardContent, CardActions, IconButton,
    Avatar, Button, Dialog, DialogTitle, DialogActions, DialogContent,
    Chip, Grid, Stack
} from '@mui/material';
import { Favorite, Comment, MoreVert, Flag, Bookmark, BookmarkBorder } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import UserPost from '../model/review';
import Plate, { getPlateById, selectedPlateId } from '../model/plates';
import formatDate from '../utility/formatDate';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import { recoilState } from '../dataStructure';
import Eatery, { getAllEateries } from '../model/eatery';

export type Props = {
    review: UserPost;
    plate: Plate;
    user_id: string;
    townRank: number | null;
    isSaved: boolean;
    setOpen: Function;
    setCurrentRestaurant: Function;
    handleSavePlate: (plateId: string) => Promise<void>;
};

const ExploreCard: React.FC<Props> = ({
    review, plate, user_id, townRank = null, isSaved = false, handleSavePlate, setOpen, setCurrentRestaurant
}) => {
    const [appState, setAppState] = useRecoilState(recoilState);
    const restaurants = useRecoilValueLoadable<Eatery[]>(getAllEateries);
    const theme = useTheme();
    const [showMore, setShowMore] = useState(false);
    const setSelectedPlateId = useSetRecoilState(selectedPlateId);
    const [isLiked, setIsLiked] = useState(review.likes?.includes(user_id) || false);
    const [likeCount, setLikeCount] = useState(review.likes ? review.likes.length : 0);
    const [modalOpen, setModalOpen] = useState(false);

    const setSelectedPlate = async () => {
        console.log("setSelectedPlate");
        if (!plate) return;


        setSelectedPlateId(review.plate_id);
        const fullPlate: Plate = await getPlateById(review.plate_id);
        if (fullPlate) {
            console.log("fullPlate");
            console.log(fullPlate);
            setAppState({ ...appState, currentPlate: fullPlate, plateDialogOpen: true });
            setModalOpen(true);
        } else {
            setModalOpen(false);
            setAppState({ ...appState, currentPlate: plate, plateDialogOpen: true });
        }
    }

    return (
        <Card sx={{ position: 'relative', mb: 4, overflow: 'hidden' }}>
            {/* Background Image with Overlay */}
            <Box
                sx={{
                    position: 'relative',
                    height: 400,
                    backgroundImage: `url(${review.photo_url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    color: 'white',
                }}
            >
                {/* Gradient Overlay */}
                <Box
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        // background: 'linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2))',
                    }}
                />
            </Box>
            <CardContent sx={{ pb: 0 }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        <a onClick={setSelectedPlate} style={{ cursor: "pointer" }}>
                            {plate.name}
                        </a>
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: theme.palette.text.secondary }}>
                        <a onClick={() => {
                            if (restaurants.state !== "hasValue") return
                            setAppState({ ...appState, plateViewParams: { ...appState.plateViewParams, status: undefined, restaurant_id: review.restaurant_id } })
                            setCurrentRestaurant(restaurants.contents.find(({ restaurant_id }) => restaurant_id === review.restaurant_id));
                        }} style={{ cursor: "pointer" }}>
                            @{plate.eatery.name}
                        </a>
                    </Typography>
                </Box>
                {/* User Info */}
                <Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 2 }}>

                    <Avatar src={`${review.user.user_id}.jpg`} />
                    <Box>
                        <Typography variant="subtitle1">{`${review.user.first_name} ${review.user.last_name[0]}.`}</Typography>
                        <Typography variant="caption">{formatDate(review.date_time)}</Typography>
                    </Box>
                </Stack>

                {/* Tags */}
                {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {review.plate.tags.map((tag) => (
                            <Chip key={tag} label={tag} color="primary" />
                        ))}
                    </Box> */}
            </CardContent>


            {/* Review Text */}
            <Typography variant="body2" gutterBottom sx={{ padding: 1, pt: 0 }}>
                "{review.review}"
            </Typography>
            <Stack direction="row" spacing={1}>
                {/* Like Button */}
                <IconButton>
                    <Favorite color={isLiked ? 'error' : 'inherit'} />
                </IconButton>
                <Typography variant="body2">{likeCount}</Typography>
                {/* Comment Button */}
                <IconButton>
                    <Comment />
                </IconButton>
                <Typography variant="body2">{review.comments ? review.comments.length : 0}</Typography>
            </Stack>
        </Card>
    );
};

export default ExploreCard;
