import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, FormControl, IconButton, Select, Typography, useTheme, MenuItem as MUIMenuItem } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { ReactComponent as IconBrands } from "../../assets/icon-brands.svg";
import { ReactComponent as IconEateries } from "../../assets/icon-eateries.svg";
import { ReactComponent as IconPlates } from "../../assets/icon-plates.svg";
import { ReactComponent as IconReviews } from "../../assets/icon-reviews.svg";
import { ReactComponent as IconUsers } from "../../assets/icon-users.svg";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useRecoilState, useRecoilValueLoadable } from "recoil";
import { RegionInfo, getRegions } from "../../model/region";
import { recoilState } from "../../dataStructure";

const Item = ({ title, to, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();

  return (
    <MenuItem
      className={location.pathname === to ? "active" : ""}
      style={{
        color: colors.grey[100],
      }}
    >


      <Typography>
        <Link to={to} style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
          {icon}
          <Typography style={{ marginLeft: icon ? "8px" : "0" }}>
            {title}
          </Typography>
        </Link>
      </Typography>
    </MenuItem>
  );
};

const Sidebar = () => {

  const [appState, setAppState] = useRecoilState(recoilState);
  const regions = useRecoilValueLoadable<RegionInfo[]>(getRegions);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");

  // Get the current location
  const location = useLocation();

  // Function to handle logout
  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  // Update the currentLocation state whenever the location changes
  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  return (
    <Box
      sx={{
        "& .collapsed": {
          width: "60px !important",
          maxWidth: "60px !important",
          minWidth: "60px !important",
        },
        "& .pro-sidebar-inner": {
          background: `${colors.opbrand[300]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
          padding: "0 !important",
          margin: "10px !important"
        },
        "& .pro-icon-wrapper .MuiBox-root": {
          marginRight: "10px !important"
        },
        "& .pro-inner-item": {
          padding: "2px 0px 2px 0px !important",
        },
        "& .pro-inner-item .pro-item-content": {
          fontWeight: "800",
        },
        "& .collapsed .pro-inner-item": {
          padding: "0px !important",
          display: "flex",
          margin: "10px 0 !important",
          alignItems: "center",
          justifyContent: "center !important",
        },
        "& .collapsed .pro-inner-item .pro-item-content": {
          //display:"none !important",
        },
        "& .pro-inner-item:hover": {
          color: "#DB0032 !important",
        },
        "& .pro-menu-item.active": {
          color: "#DB0032 !important",
          backgroundColor: "white",
          borderRadius: "6px"
        },
        "& .pro-menu-item a": {
          fontWeight: "800",
        },
        "& .pro-menu .pro-menu .pro-menu-item a": {
          fontSize: ".9rem",
          fontWeight: "500",
          padding: "0",
          margin: "0",
          lineHeight: "1"
        },
        "& .pro-menu-item svg": {
          maxWidth: "36px !important",
          height: "auto !important",
          minWidth: "36px",
          width: "36px",
        },
        "& .pro-menu-item.active .pro-icon svg *": {
          fill: "#DB0032 !important",
        },
        "& .pro-menu .pro-menu .pro-menu-item.active": {
          backgroundColor: "unset",
          color: "#DB0032 !important"
        }
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  src="onlyplates-logo-opadmin.svg"
                  alt="OnlyPlates Admin"
                  width="150"
                />
                <IconButton
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  style={{
                    margin: "0 10px",
                  }}>
                  <MenuOutlinedIcon style={{
                    color: colors.grey[100],
                  }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box padding="0 10px" display="flex" flex="1" width="100%">
            {(regions.state === "hasValue") &&
              <FormControl style={{width: "100%"}}>
                <Select
                  // labelId="eatery-select-small"
                  // label="Eatery"
                  sx={{ width: "100%", textOverflow: "ellipsis", overflow: "hidden", }}
                  id="eatery-select"
                  fullWidth
                  notched={true}
                  value={appState.currentRegionId ? appState.currentRegionId : "select"}
                  onChange={e => {
                    console.log(e.target.value);
                    setAppState({...appState, currentRegionId: Number(e.target.value), plateViewParams: {region_id: Number(e.target.value), status: 1, restaurant_id: undefined}})
                  }}
                >
                  <MUIMenuItem key={`eatery-select--2`}
                    value={"select"}>Select an Region</MUIMenuItem>
                  {regions.state === "hasValue" && regions.contents.map((r: RegionInfo, index) => (
                    <MUIMenuItem key={`eatery-select-${index}`}
                      value={r.region_id}
                      selected={(appState.currentRegionId ? r.region_id === appState.currentRegionId : false)}>{r.name}</MUIMenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          </Box>
          {appState.currentRegionId &&
          <Box padding="0 10px">
            <Item
              title="Dashboard"
              to="/dashboard"
              icon={<IconBrands />}//@ts-ignore
              className={currentLocation === '/dashboard' ? "active" : undefined}
            />
            <Item
              title="Brands"
              to="/brands"
              icon={<IconBrands />}//@ts-ignore
              className={currentLocation === '/brands' ? "active" : undefined}
            />
            <Item
              title="Eateries"
              to="/eatery"
              icon={<IconEateries />}//@ts-ignore
              className={currentLocation === '/eatery' || currentLocation === '/googleplaces' ? "active" : undefined}
            />

            {isCollapsed ? null : (
              currentLocation === '/eatery' || currentLocation === '/googleplaces' ? (
                <Menu iconShape="square">
                  <Item
                    title="Find New Eateries"
                    to="/googleplaces"
                    icon={undefined}
                  />
                </Menu>
              ) : (
                <></>
              )
            )}
            <Item
              title="Plates"
              to="/plates"
              icon={<IconPlates />}
            />
            {isCollapsed ? null : (
              currentLocation === '/plates' ? (
                <Menu iconShape="square">
                  <Item
                    title="Manage Plates"
                    to="/plates"
                    icon={undefined}
                  />
                </Menu>
              ) : (
                <></>
              )
            )}
            <Item
              title="Reviews"
              to="/reviews"
              icon={<IconReviews />}//@ts-ignore
              className={currentLocation === '/reviews' ? "active" : undefined}
            />
            <Item
              title="Users"
              to="/users"
              icon={<IconUsers />}//@ts-ignore
              className={currentLocation === '/users' ? "active" : undefined}
            />
          </Box>
          }
          <Box sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px 15px"
          }}>
            <MenuItem
              onClick={handleLogout}
              style={{
                margin: "5px 0",
                fontWeight: "300",
                fontSize: "12px",
                color: colors.grey[100],
              }}
            >
              Log Out
            </MenuItem>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
