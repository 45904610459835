export default function getRectangleBounds(location: { latitude: number; longitude: number; }, distanceInMiles: number) {
    // Earth's radius in miles
    const latitude = location.latitude;
    const longitude = location.longitude;
    const earthRadius = 3958.8; // miles

    // Convert distance to radians
    const distanceLat = distanceInMiles / earthRadius;
    const distanceLon = distanceInMiles / (earthRadius * Math.cos((Math.PI / 180) * latitude));

    // Calculate rectangle bounds
    const north = latitude + (distanceLat * (180 / Math.PI));
    const south = latitude - (distanceLat * (180 / Math.PI));
    const east = longitude + (distanceLon * (180 / Math.PI));
    const west = longitude - (distanceLon * (180 / Math.PI));

  // Create the rectangle bounds.
  const rectangle = {
    rectangle: {
      low: {
        latitude: south,
        longitude: west
      },
      high: {
        latitude: north,
        longitude: east
      }
    }
  };

  return rectangle;
}
