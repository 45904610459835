import { Card, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const LocationCard = ({ eatery }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Card key={eatery.restaurant_id} sx={{ backgroundColor: colors.opbrand[300] }} raised>
            <CardMedia component="img" sx={{ height: "175px" }}
                image={`https://maps.googleapis.com/maps/api/staticmap?center=${eatery.location.latitude},${eatery.location.longitude}&zoom=18&size=400x400&scale=2&markers=color:red%7Clabel:*%7C${eatery.location.latitude},${eatery.location.longitude}&key=AIzaSyA20F12NUNJWkKf2Po0fI2RF9D73gvXs3I`}
            />
            <CardContent color={colors.grey[100]} sx={{ display: 'flex', flexDirection: 'column', fontWeight: "bold" }}>
                {eatery.name}
                <Typography
                    color={colors[100]}>{eatery.address}</Typography>
                <Typography
                    color={colors.grey[100]}>{eatery.city}, {eatery.state}, {eatery.zip}</Typography>

            </CardContent>
        </Card>
    );
};

export default LocationCard;
