export default interface Location {
    latitude:      number;
    longitude:     number;
}
export const blankLocation:Location = {latitude: 0, longitude: 0}

export interface LocationBias {
    circle?: Circle;
    rectangle?: Rectangle;
}
export interface Circle{
    center: Location
    radius: string;
}

export interface LatLong{
    latitude: number
    longitude: number;
}
export interface Rectangle{
    low: LatLong;
    high: LatLong;
  }