import type { RecoilState } from 'recoil'
import { atom } from 'recoil'
import Eatery from './model/eatery'
import GooglePlacesSearch, { blankCriteria } from './model/googlePlacesSearch'
import Plate from './model/plates'
import Tag from './model/tag'
import { GridFilterModel } from '@mui/x-data-grid'
import Brand from './model/brand'
import { selector } from 'recoil';
import User, { getUsersCount } from './model/user'; // Import the function you created to fetch the user count



export type Routes = '/eateries' | '/googleplaces' | '/plates' | '/reviews'| '/brands'| '/dashboard'
export interface PlatesViewParams {
  region_id: number,
  status?: number,
  restaurant_id?: string,
}
export interface AppState {
  loggedIn: Boolean,
  adminToken: string | null,
  restaurantData?: Eatery[],
  restaurantTags?: Tag[],
  searchCriteria: GooglePlacesSearch,
  plateViewParams: PlatesViewParams,
  currentRegionId: number | null,
  currentBrand: Brand | null,
  currentEatery: Eatery | null,
  currentPlate: Plate | null,
  currentUser: User | null,
  brandDialogOpen: boolean,
  plateDialogOpen: boolean,
  userDialogOpen: boolean,
  brandsFilterModel: GridFilterModel,
  platesFilterModel: GridFilterModel,
  reviewFilterModel: GridFilterModel,
  usersFilterModel: GridFilterModel,
}

export enum LocalStorageKey {
  APP_STATE = 'APP_STATE',
}

function LoadAppStateFromLocalStorage(): AppState {
  const stringifiedJSON: string | null = window.localStorage.getItem(
    LocalStorageKey.APP_STATE
  )
  if (typeof stringifiedJSON === 'string') {
    const Loaded: AppState = JSON.parse(stringifiedJSON);
    return Loaded;
  }

  const BlankAppState: AppState = {// @ts-ignore
    loggedIn: false,
    adminToken: null,
    restaurantData: [],
    restaurantTags: [],
    searchCriteria: blankCriteria,
    plateViewParams: {
      region_id: 1,
      status: 1
    },
    currentRegionId: null,
    currentBrand: null,
    currentEatery: null,
    currentPlate: null,
    currentUser: null,
    brandDialogOpen: false,
    plateDialogOpen: false,
    userDialogOpen: false,
    brandsFilterModel: { items: [] },
    platesFilterModel: { items: [] },
    reviewFilterModel: { items: [] },
    usersFilterModel: { items: [] },
  }

  return BlankAppState
}

export const recoilState: RecoilState<AppState> = atom({
  default: LoadAppStateFromLocalStorage(),
  key: 'initialAppState',
})

// Selector for getting the users count
export const usersCountState = selector({
  key: 'usersCountState',
  get: async ({get}) => {
      try {
          const count = await getUsersCount();
          return count;
      } catch (error) {
          console.error("Error fetching users count:", error);
          return 0;
      }
  },
});
