import { recoilState } from "../dataStructure";
import { getRecoil, setRecoil } from "recoil-nexus";
const apiURL = process.env.REACT_APP_ENVIRONMENT === "dev" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_API_STAGE;
export const authFetch = async (requestPath, requestParams) => {
    try {
        const appState = getRecoil(recoilState);
        const token = appState.adminToken || "loginOptionsRequest" + new Date().getTime().toString();
        console.log(token);
        const headers = { ...requestParams.headers, Authorization: token };
        console.log(headers);
        console.log(requestPath);
        const data = await fetch(apiURL + requestPath, {
            ...requestParams,
            headers: headers
        });
        const response = await data.json();
        // console.log("response: " + JSON.stringify(response));
        console.log("check status code: " + data.status);
        if (response.message && requestPath === "admin/login/") {
            setRecoil(recoilState, { ...appState, adminToken: null, loggedIn: false });
            return [];

        } else {
            return response;
        }
    } catch (e: any) {
        console.log(requestPath, requestParams);
        console.error(e);
        return {};
    }
}

