import { Alert, AlertTitle, Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, getGridSingleSelectOperators, GridComparatorFn, GridCellParams, GridFilterItem, GridColDef, GridSortModel, GridFilterModel } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import Eatery, { putEatery, getAllEateries, eateryRefreshState, deleteEateryById, blankEatery } from '../../model/eatery';
import Brand, { brandRefreshState, getAllBrands, putBrand } from "../../model/brand";
import { useRecoilValueLoadable, useSetRecoilState, useRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import Tag, { getTags, putTag, tagRefreshState, tagType } from '../../model/tag';
import csvTOJSON from "../../import/importEateries";
import CustomToolbar from "../../components/CustomToolbar";
import { locationBiasF } from "../../model/location_defaults";
import Region, { RegionInfo, blankRegion, getRegions } from "../../model/region";
import GooglePlacesSearch, { _placesSearch } from "../../model/googlePlacesSearch";
import Location from "../../model/location";
import { CustomBox, CustomHeadingBox } from "../../scenes/global/GlobalStyles";
import { recoilState } from "../../dataStructure";
import Plate, { getPlateById, getPlates, platesRefreshState, putPlate } from "../../model/plates";
import { SubTitle } from "chart.js";


const Eateries = () => {
  const [appState, setAppState] = useRecoilState(recoilState);
  const brands = useRecoilValueLoadable<Brand[]>(getAllBrands);
  const setPlatesRefresh = useSetRecoilState(platesRefreshState);
  const regions = useRecoilValueLoadable<RegionInfo[]>(getRegions);
  const restaurants = useRecoilValueLoadable<Eatery[]>(getAllEateries);
  const [tagState, setTagType] = useRecoilState(tagType);
  const tags = useRecoilValueLoadable<Tag[]>(getTags);
  const setRestaurantsRefresh = useSetRecoilState(eateryRefreshState);
  const setTagsRefresh = useSetRecoilState(tagRefreshState);
  const [currentRestaurant, setCurrentRestaurant] = useState<Eatery | null>();
  const [columns, setColumn] = useState(false);
  const [open, setOpen] = useState(false);
  const [importResults, setImportResults] = useState({ recordsImported: 0, recordsRejected: 0 });
  const [uploadResultsOpen, setUploadResultsOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const uploadResults = () => setUploadResultsOpen(true);
  const closeUploadResults = () => setUploadResultsOpen(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const hiddenFileInput = useRef(null);
  const handleLoading = () => {
    setLoading(true);
  };
  const handleLoaded = () => {
    setLoading(false);
  };
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'name',
      sort: 'asc',
    },
  ]);

  useEffect(() => {
    if (tagState != "cuisine_type")
      setTagType("cuisine_type")
  }, []);

  async function uploadFile(event) {
    handleLoading();
    // await new Promise(r => setTimeout(r, 2000));
    let file = event.target.files[0];
    console.log(file);
    const region = regions.contents.find(({ region_id }) => region_id === appState.currentRegionId);
    const results = await csvTOJSON(file, tags.contents, restaurants.contents, region);
    // @ts-ignore
    setImportResults(results);
    setTagsRefresh(new Date().getTime().toString());
    setRestaurantsRefresh(new Date().getTime().toString());
    handleLoaded();
    uploadResults();
  }
  function UploadComplete() {
    return (
      <Alert variant="filled" severity="info" onClose={() => { closeUploadResults() }}>
        <AlertTitle>Import Results</AlertTitle>
        {Number(importResults.recordsImported) - Number(importResults.recordsRejected)} eateries imported. {importResults.recordsRejected} eateries skipped.
      </Alert>
    )
  }
  function EateryForm() {
    const [appState, setAppState] = useRecoilState(recoilState);
    const plates = useRecoilValueLoadable<Plate[]>(getPlates);
    const brands = useRecoilValueLoadable<Brand[]>(getAllBrands);
    const [eateryName, setEateryName] = useState(currentRestaurant ? currentRestaurant.name : "");
    const [brand, setBrand] = useState<string>(currentRestaurant ? currentRestaurant.brand_id : "select");
    const [address, setAddress] = useState(currentRestaurant ? currentRestaurant.address : "");
    const [city, setCity] = useState(currentRestaurant ? currentRestaurant.city : "");
    const [state, setState] = useState(currentRestaurant ? currentRestaurant.state : "");
    const [zip, setZip] = useState(currentRestaurant ? currentRestaurant.zip : "");
    const [restaurantTags, setRestaurantTags] = useState(currentRestaurant ? currentRestaurant.tags : []);
    const setBrandsRefresh = useSetRecoilState(brandRefreshState);
    const [tagAutocomplete, setTagAutoComplete] = useState("");
    // const findBrand: Brand = brands.contents.find(({ brand_id }) => brand_id === brand);

    // useEffect(() => {
    //   // const findBrand: Brand = brands.contents.find(({ brand_id }) => brand_id === brand);
    //   if (!getBrand())
    //     setBrand("select")
    // }, [brands.contents]);
    async function remove(): Promise<any> {
      handleClose();
      handleLoading();
      await deleteEateryById(currentRestaurant.restaurant_id);
      setRestaurantsRefresh(new Date().getTime().toString());
      handleLoaded();
      return
    }
    async function createBrand(): Promise<string> {
      //create the brand
      const newBrand: Brand = {
        brand_id: "",
        name: eateryName,
        eatery_count: 1,
        description: "",
        website: "",
        last_updated: new Date().getTime(),
        tags: restaurantTags
      }
      const response = await putBrand(newBrand);
      setBrand(response.brand_id);
      setBrandsRefresh(new Date().getTime().toString());
      return response.brand_id;
    }
    async function updatePlates(eatery): Promise<any> {
      //update the plates
      await Promise.all(plates.contents.map(async (plate) => {
        let existingPlate: Plate = await getPlateById(plate.plate_id);
        if (!existingPlate || existingPlate.status === -3) return

        try {
          await putPlate({...existingPlate, brand_id: eatery.brand_id, eatery: eatery});
        } catch (e:any) {
          console.log(e);
        }
      }))
      return
    }
    function getBrand() {
      const findBrand: Brand = brands.contents.find(({ brand_id }) => brand_id === brand)
      if (currentRestaurant && currentRestaurant.brand_id) {
        if (findBrand) {
          console.log("valid brand id selected")
          return brand
        };
        return brand
        // if we are here this means the brand_id is
        // 'unassigned' or just a copy of the restaurant_id
        // so we make a brand
      }

    }
    async function saveEatery(): Promise<any> {
      console.log(getBrand());
      let brandId = brand
      if(getBrand() === "new") {
        brandId = await createBrand();
      }
      const updatedEatery: Eatery = {
        ...currentRestaurant,
        name: eateryName,
        address: address,
        city: city,
        state: state,
        zip: zip, // @ts-ignore
        tags: restaurantTags,
        brand_id: brandId
      }

      await updatePlates(updatedEatery);
      handleClose();
      handleLoading();
      await putEatery(updatedEatery);
      setTagsRefresh(new Date().getTime().toString());
      setRestaurantsRefresh(new Date().getTime().toString());
      setPlatesRefresh(new Date().getTime().toString());
      handleLoaded();
      return
    }
    async function createEatery(): Promise<any> {
      handleClose();
      handleLoading();
      const searchCriteria: GooglePlacesSearch = {
        textQuery: `${address} ${city}, ${state}`
      }
      let location: Location;
      let google_id: string;
      // let region: Region;
      const region: RegionInfo = regions.contents.find(({ region_id }) => region_id === appState.currentRegionId);
      searchCriteria.lat = region.location.center.latitude;
      searchCriteria.long = region.location.center.longitude;
      const placesResults = await _placesSearch(searchCriteria);
      console.log(placesResults);
      console.log(placesResults.length);// @ts-ignore
      if (placesResults && placesResults.length > 0) {// @ts-ignore
        let place: GooglePlacesResult = placesResults[0];
        location = { latitude: place.location.latitude, longitude: place.location.longitude };
        google_id = place.id;
        // region = { ...blankRegion, location: locationBiasF };
      } else {
        setTagsRefresh(new Date().getTime().toString());
        setRestaurantsRefresh(new Date().getTime().toString());
        handleLoaded();
        alert(`address not found ${address}, ${city}, ${state}, ${zip}`)
        return
      }
      // new brand
      let brandId: string = brand;
      if (brandId === "new") {
        let dt = new Date().getTime();
        const newBrand: Brand = {
          brand_id: "",
          name: eateryName,
          eatery_count: 1,
          description: "",
          website: "",
          last_updated: dt,
          tags: restaurantTags
        }
        const response = await putBrand(newBrand);
        console.log(response);
        brandId = response.brand_id;
        setBrandsRefresh(dt.toString());
      }
      // this needs to be built-out to support multiple regions
      const newEatery: Eatery = {
        ...currentRestaurant,
        restaurant_id: google_id,
        brand_id: brandId,
        name: eateryName,
        address: address,
        city: city,
        state: state,
        zip: zip, // @ts-ignore
        tags: restaurantTags,
        region: region,
        location: location
      }

      await putEatery(newEatery);
      setTagsRefresh(new Date().getTime().toString());
      setRestaurantsRefresh(new Date().getTime().toString());
      handleLoaded();
      handleClose();
      return
    }
    return (
      <Dialog
        key={`currentRestaurant`}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        disableEnforceFocus
      >
        <DialogTitle id="form-dialog-title">
          {currentRestaurant && currentRestaurant.restaurant_id === "" ? (
            [`Create a new Eatery`]
          ) : (
            eateryName
          )}
        </DialogTitle>
        <DialogContent>

          <Box>
            {plates.state === "hasValue" ? (
              <Typography>{plates.contents.length} plates</Typography>
            ) :
              (<Typography>&nbsp;</Typography>)
            }
          </Box>
          <Box sx={{ width: "100%", marginTop: 2 }}>
            {brands.state === "hasValue" && (
              <FormControl sx={{ mt: 1, width: "100%" }}>
                <InputLabel id="eatery-select-small" shrink={true} htmlFor="eatery-select">Brand</InputLabel>
                <Select
                  labelId="eatery-select-small"
                  label="Brand"
                  sx={{ textOverflow: "ellipsis", overflow: "hidden", }}
                  id="eatery-select"
                  fullWidth
                  notched={true}
                  value={brands.contents.find(({ brand_id }) => brand_id === brand) || brand ==="new" ? brand.toString() : "select"}
                  onChange={e => {
                    console.log(e.target.value);
                    if (e.target.value === "new") {
                      setBrand("new");
                    } else {
                      setBrand(e.target.value.toString());
                    }
                  }}
                >
                  {/* <MenuItem key={`brand-select--2`}
                                                value={""}
                                                selected={!brand}>----------</MenuItem> */}
                  <MenuItem key={`brand-select--2`}
                    value={"select"}>Select a Brand</MenuItem>
                  <MenuItem key={`brand-select--1`}
                    value={"new"}>Create a New Brand Using Name</MenuItem>
                  {brands.state === "hasValue" && brands.contents.map((b: Brand, index) => (
                    <MenuItem key={`brand-select-${index}`}
                      value={b.brand_id}
                      selected={(b.brand_id === brand)}>{b.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
            }
            <TextField
              label="Eatery Name"
              defaultValue={eateryName}
              sx={{ mt: 1 }}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.value !== eateryName) setEateryName(event.target.value);
              }}
            />

            <TextField
              label="Address"
              sx={{ mt: 1 }}
              defaultValue={address}
              fullWidth
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setAddress(event.target.value);
              }}
            />
            <TextField
              label="City"
              sx={{ width: '34ch', mt: 1 }}
              defaultValue={city}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setCity(event.target.value);
              }}
            />
            <TextField
              label="State"
              sx={{ width: '12ch', mt: 1 }}
              defaultValue={state}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setState(event.target.value);
              }}
            />
            <TextField
              label="Zip"
              sx={{ width: '12ch', mt: 1 }}
              defaultValue={zip}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setZip(event.target.value);
              }}
            />
            {/* {currentRestaurant && currentRestaurant.restaurant_id === "" &&
            <Box sx={{ width: '100%' }} >
            <TextField
              label="location-latitude"
              sx={{ width: '25ch', mt: 1  }}
              defaultValue={0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setZip(event.target.value);
              }}
            />
            <TextField
              label="location-longitude"
              sx={{ width: '25ch', mt: 1  }}
              defaultValue={0}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setZip(event.target.value);
              }}
            />
            </Box>
            } */}
            {tags.state === "hasValue" &&
              <Autocomplete
                multiple
                freeSolo
                sx={{ mt: 1 }}
                options={tags.contents.map((tag) => (tag.tag))}
                getOptionLabel={option => option}
                value={restaurantTags}
                onChange={(event: any, newValue: string[] | null) => {
                  newValue.forEach((newTag) => {
                    console.log(newTag);
                    console.log(tags.contents.findIndex((tag) => tag.tag === newTag));
                    if (tags.contents.findIndex((tag) => tag.tag === newTag) === -1)
                      putTag({ tag: newTag, tag_type: "cuisine_type" });
                  });
                  setRestaurantTags(newValue);
                }}
                renderInput={params => (
                  <TextField {...params} label="Tags" fullWidth />
                )}
              />
            }
          </Box>
        </DialogContent>
        {plates.state === "hasValue" &&
          <DialogActions>
            <Button onClick={() => {
              return window.confirm(`Are you sure you want to delete ${currentRestaurant.name}?`) ? remove() : null;
            }}
              color="warning">
              Remove
            </Button>
            <Button onClick={() => {
              setTagsRefresh(new Date().getTime().toString());
              handleClose()
            }} color="warning">
              Cancel
            </Button>
            <Button onClick={() => {
              if (currentRestaurant.restaurant_id === "") createEatery()
              else saveEatery()
            }} color="secondary">
              Save
            </Button>
          </DialogActions>
        }
      </Dialog>
    );
  }
  const tagsSortComparator: GridComparatorFn<any> = (tags1: any, tags2: any) => {
    return tags1.length - tags2.length
  }
  const tagsFilterOperators = getGridSingleSelectOperators()
    .filter((operator) => operator.value === "isAnyOf")
    .map((operator) => {
      const newOperator = { ...operator }
      const newGetApplyFilterFn = (filterItem: GridFilterItem) => {
        return (params: GridCellParams): boolean => {
          let isOk = true
          filterItem?.value?.forEach((fv: any) => {
            isOk = isOk && params.value.includes(fv)
          })
          return isOk
        }
      }
      newOperator.getApplyFilterFn = newGetApplyFilterFn
      return newOperator
    })
  const getColumns = () => {
    return [
    { field: "brand_id", headerName: "brand", hide: false, disableExport: true,
    valueGetter: ({ value }) => brands.contents.find(({ brand_id }) => brand_id === value) ? value : "unassigned", },
    { field: "region", headerName: "region", hide: true, disableExport: false,},
    {
      field: "location", headerName: "location", hide: true, disableExport: false,
      valueFormatter: ({ value }) => `"${value}"`,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1.75,
      cellClassName: "name-column--cell",
      valueFormatter: ({ value }) => `${value}`,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;// @ts-ignore
          const thisRow: Eatery = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.row[c.field])
            );
          setAppState({ ...appState, plateViewParams: { ...appState.plateViewParams, status: undefined, restaurant_id: thisRow.restaurant_id } })
          setCurrentRestaurant(restaurants.contents.find(({ restaurant_id }) => restaurant_id === thisRow.restaurant_id));
          return handleOpen();
        };

        return <Button key={`edit-button-${params.row.restaurant_id}`} onClick={onClick} fullWidth color="primary" style={{

          fontSize: '14px',
          textTransform: 'none',
          textAlign: 'left',
          justifyContent: 'flex-start'
        }}>{params.row.name}</Button>;
      }
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueFormatter: ({ value }) => `${value}`,
    },
    {
      field: "city",
      headerName: "City",
      flex: .5,
      valueFormatter: ({ value }) => `${value.trim()}`,
    },
    {
      field: "state",
      headerName: "State",
      flex: 0.25,
      valueFormatter: ({ value }) => `${value}`,
    },
    {
      field: "zip",
      headerName: "Zip",
      flex: 0.25,
      valueFormatter: ({ value }) => `${value}`,
    },
    // {
    //   field: "tags",
    //   hide: true
    // },
    {
      field: "tags",
      headerName: "Tags",
      flex: 2,
      valueFormatter: ({ value }) => `*${value.join(",") + "*"}`,
      renderCell: (params) => (
        <Stack key={`chips-${params.row.restaurant_id}`} direction="row" spacing={0.25}>
          {params.row.tags.map((tag: string) => (
            <Chip key={`chip-${tag}-${params.row.restaurant_id}`} label={tag} style={{
              borderRadius: 10,
              backgroundColor: colors.opGray[500],
              fontSize: '10px',
              height: 'auto',
              fontWeight: '900',
              lineHeight: '2',
              width: 'auto',
              padding: '0',
              paddingLeft: '4px',
              paddingRight: '4px',
            }} />
          ))}
        </Stack>
      ),
      sortComparator: tagsSortComparator,
      filterOperators: tagsFilterOperators
      // valueFormatter: ( tags ) => {return tags ? tags.value.toString().replace(",", ", ") : ""},
    },
    {
      field: "restaurant_id",
      headerName: "",
      valueFormatter: ({ value }) => `${value}`,
      sortable: false,
      disableExport: false,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          const api = params.api;// @ts-ignore
          const thisRow: Eatery = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.row[c.field])
            );
          console.log("set plateViewParams");
          console.log(appState.plateViewParams)
          setCurrentRestaurant(restaurants.contents.find(({ restaurant_id }) => restaurant_id === thisRow.restaurant_id));

          setAppState({ ...appState, plateViewParams: { ...appState.plateViewParams, status: undefined, restaurant_id: thisRow.restaurant_id } })
          return handleOpen()
        };

        return <Button key={`edit-button-${params.row.restaurant_id}`} onClick={onClick} fullWidth color="primary" style={{
          borderRadius: 10,
          backgroundColor: colors.opRed[500],
          color: colors.white[100],
          fontSize: '10px',
          fontWeight: '900',
          letterSpacing: '.05rem',
          lineHeight: '1.5',
          width: 'auto',
          padding: '0',
          paddingLeft: '10px',
          paddingRight: '10px',
          maxWidth: '80px',
          minWidth: 'unset',
        }}>Edit</Button>;
      }
    }
  ];
}

  return (
    <Box m="10px">
      <CustomHeadingBox m="10px">
        <Header
          title="Eateries"
          subtitle="List of Restaurants Currently in the database."
        />
        <Box>
          <Button variant="contained" color="secondary" onClick={() => { hiddenFileInput.current.click() }} sx={{ ml: 2, fontWeight: "Bold" }} >Import</Button>
          <input type="file"
            ref={hiddenFileInput}
            name="myFile"
            onChange={uploadFile}
            hidden />
          {uploadResultsOpen &&
            <UploadComplete />
          }
          <Button variant="contained" color="secondary" onClick={() => { setCurrentRestaurant(blankEatery); handleOpen(); }} disabled={(open) ? true : false} sx={{ ml: 2, fontWeight: "Bold" }}>+ Add an Eatery</Button>
        </Box>
      </CustomHeadingBox>
      <CustomBox
        m="10px 0 0 0"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.redAccent[300],
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            height: "auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.white[100],
            borderBottom: "none",
            height: "auto !important",
            minHeight: "unset !important"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.redAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.redAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {(restaurants.state === "hasValue" && tags.state === "hasValue" && brands.state === "hasValue") &&
          <DataGrid
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            getRowId={(row) => row.restaurant_id}
            rows={restaurants.contents}// @ts-ignore
            columns={getColumns()}
            components={{ Toolbar: CustomToolbar, }}
            filterModel={filterModel}
            onFilterModelChange={(newModel) => {
              setFilterModel(newModel)
            }}
          />}
      </CustomBox>
      <EateryForm />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={handleLoaded}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Eateries;

