import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Box, FormControl, InputLabel, Typography, useTheme, MenuItem, DialogTitle, Slider, Paper, Stack, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { DataGrid, getGridSingleSelectOperators, GridToolbar, GridComparatorFn, GridCellParams, GridFilterItem, GridColDef, GridSortModel } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataGooglePlaces } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";

import { useRecoilState, useRecoilValueLoadable } from 'recoil'
import Eatery, { putEatery, getAllEateries, eateryRefreshState } from '../../model/eatery'
import GooglePlacesSearch, { blankCriteria, _placesSearch, GooglePlacesResult } from '../../model/googlePlacesSearch';
import { RegionInfo, blankRegion, getRegions } from '../../model/region';
import { locationBiasA, locationBiasB, locationBiasR, locationBiasF, locationBiasS } from '../../model/location_defaults';
import { recoilState, LocalStorageKey } from '../../dataStructure'

const GooglePlaces = () => {
  const [appState, setAppState] = useRecoilState(recoilState);
  const regions = useRecoilValueLoadable<RegionInfo[]>(getRegions);
  const [searchResults, setSearchResults] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [rating, setRating] = useState("4.0");
  const [searchCriteria, setSearchCriteria] = useState(blankCriteria);//@ts-ignore
  const restaurants = useRecoilValueLoadable(getAllEateries);
  const [hasResults, setHasResults] = useState(Boolean(searchResults.length > 0));
  const [restaurantsRefresh, setRestaurantsRefresh] = useRecoilState(eateryRefreshState);
  const [locationBias, setLocationBias] = useState(locationBiasF);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const columns = [
    { field: "id", hide: true },
    {
      field: "displayName",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      valueFormatter: (params) => {
        return `${params.value.text} `;
      },
    },
    {
      field: "formattedAddress",
      headerName: "Address",
      flex: 1
    },
    {
      field: "rating",
      headerName: "Google Biz Rating",
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      flex: .5,
      renderCell: (params) => {
        // 
        const api = params.api;// @ts-ignore
        const thisRow:GooglePlacesResult = {};

        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.row[c.field])
          );
        const onClick = (e:any) => {
          e.stopPropagation(); // don't select this row after clicking
          // alert(JSON.stringify(searchResults.find(({ id }) => id === thisRow.id), null, 4));
          saveEatery(thisRow.id);
        }
        // setCurrentRestaurant(restaurants.contents.find(({ restaurant_id }) => restaurant_id === thisRow.restaurant_id));
        // return handleOpen()
        // return alert(JSON.stringify(thisRow, null, 4));
        // };
        return <Box>
          {restaurants.state === "hasValue" && !restaurants.contents.find(({ restaurant_id }) => restaurant_id === thisRow.id) &&
            <Button onClick={onClick} fullWidth color="primary" sx={{
              borderRadius: 10,
              backgroundColor: "rgb(219, 0, 50)",
              color: "white",
              fontWeight: "900",
              letterSpacing: "0.05rem",
              lineHeight: "1.5",
              width: "auto",
              padding: "0px 8px",
              maxWidth: "80px",
              minWidth: "unset",
              fontSize: "10px",
    
            }}>Add</Button>
          }
        </Box>
      }
    },
  ];

  // useEffect(() => {
  //   if (restaurants.state === "hasValue") {
  //     console.info("setRestaurants");
  //     setAppState({ ...appState, restaurantData: restaurants.contents })
  //   }
  // }, [restaurants.state]);
  // useEffect(() => {
  //   console.log("locationBias set")
  //   setSearchCriteria({ ...searchCriteria, textQuery: textSearch })
  //   setAppState({ ...appState, searchCriteria: searchCriteria })
  //   // fetchData();
  // }, [locationBias]);
  useEffect(() => {
    console.log("searchResults")
    fetchData();
  }, [searchCriteria]);

  useEffect(() => {
    console.log("rerender")
  }, [searchResults]);

  const fetchData = async () => {
    console.log("preFetch");
    console.log(searchCriteria);
    if (searchCriteria.textQuery.length > 4) {
      setHasResults(false);
      setAppState({ ...appState, searchCriteria: searchCriteria });
      const results = await _placesSearch(searchCriteria);
      if (results && results.length > 0) {
        console.log("google places results");
        console.log(results);
        setSearchResults(results);
        setHasResults(Boolean(searchResults.length > 0));
      } else {
        // build out error dialog
        alert("Goggle API Error")
      }
    }
  }

  const saveEatery = async (placeId) => {
    const googlePlace = searchResults.find(({ id }) => id === placeId);
    const addressArray = googlePlace.formattedAddress.split(",");
    const stateZip = addressArray[2].trim().split(" ");
    let region = blankRegion;
    // console.log(locationBias);
    // if (appState.searchCriteria.locationBias.circle.center.latitude === "30.2135335" && appState.searchCriteria.locationBias.circle.center.longitude === "-97.87451969999999")
    //   region = { ...region, region: "Austin Metro", region_id: "2", location: locationBias }
    // else
    //   region.location = locationBiasF;
    const loc = { latitude: googlePlace.location.latitude, longitude: googlePlace.location.longitude };
    console.log(stateZip);
    const newRestaurant = {
      // region: blankRegion,
      restaurant_id: googlePlace.id,
      brand_id: "googlePlace.id",
      name: googlePlace.displayName.text.replace(",", " "),
      address: addressArray[0].replace(",", " "),
      city: addressArray[1],
      state: stateZip[0],
      zip: stateZip[1],
      region_id: appState.currentRegionId.toString(),
      location: loc,
      tags: []
    }

    await putEatery(newRestaurant);
    setRestaurantsRefresh(new Date().getTime().toString());
    return
  }

  const search = async (event) => {

    const region = regions.contents.find(({region_id}) => region_id === appState.currentRegionId);
    let crit = {...appState.searchCriteria, lat: region.location.center.latitude, long: region.location.center.longitude};
    if (rating.toString().length === 0 || rating.toString() === "0") {
      crit = { ...crit, textQuery: textSearch };
      delete searchCriteria.minRating;
    } else {
      let roundedRating = rating.toString().split(".");

      if (roundedRating.length > 1) {
        roundedRating[1] = (Number(roundedRating[1]) >= 0 && Number(roundedRating[1]) < 3) ? "0" : "5";

      }
      setRating(roundedRating.join("."))
      crit = { ...crit, textQuery: textSearch, minRating: rating.toString() }
    }
    setSearchCriteria(crit);
    setAppState({ ...appState, searchCriteria: crit })
    return
  };
  return (
    <Box m="20px">
      <Header title="Find Eateries" subtitle="Search Google Place for New Restaurants" />
      <Box
        width="100%">
        <Stack direction="row"
          sx={{ width: "70vw" }}>
          {/* <Item> */}
          <Box>
            <InputLabel id="search-criteria-label">Search Query</InputLabel>
            <TextField
              aria-label="search-criteria-label"
              id="search-criteria"
              defaultValue={textSearch}
              onChange={(e) => { setTextSearch(e.target.value) }}
              sx={{
                width: '45ch',
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "2px solid red"
                  }
                }
              }}
            />
          </Box>
          {/* </Item>
          <Item> */}
          {/* </Item>
      <Item> */}
          <Box>
            <InputLabel id="rating-select-small-label">Minimum Rating</InputLabel>
            <Select
              aria-label="demo-select-small-label"
              id="demo-select-small"
              value={rating}
              label="Age"
              onChange={e => setRating(e.target.value)}
              sx={{
                width: '25ch',
                "&.Mui-focused": {
                  border: "2px solid red"
                }
              }}
            >
              <MenuItem value={"0.5"}>0.5 Stars</MenuItem>
              <MenuItem value={"1"}>1.0 Stars</MenuItem>
              <MenuItem value={"1.5"}>1.5 Stars</MenuItem>
              <MenuItem value={"2"}>2.0 Stars</MenuItem>
              <MenuItem value={"2.5"}>2.5 Stars</MenuItem>
              <MenuItem value={"3"}>3.0 Stars</MenuItem>
              <MenuItem value={"3.5"}>3.5 Stars</MenuItem>
              <MenuItem value={"4.0"}>4.0 Stars</MenuItem>
              <MenuItem value={"4.5"}>4.5 Stars</MenuItem>
              <MenuItem value={"5.0"}>5.0 Stars</MenuItem>
            </Select>
          </Box>
          <Box sx={{
            width: 120, marginTop: 3, marginLeft: 1
          }}>
            <Button onClick={search} variant="outlined" color="secondary" sx={{ml:1, fontWeight: "Bold"}}>Search</Button>
          </Box>
          {/* </Item> */}
        </Stack>
      </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        // sx={{
        //   "& .MuiDataGrid-root": {
        //     border: "none",
        //   },
        //   "& .MuiDataGrid-cell": {
        //     borderBottom: "none",
        //   },
        //   "& .name-column--cell": {
        //     color: colors.redAccent[300],
        //   },
        //   "& .MuiDataGrid-columnHeaders": {
        //     backgroundColor: colors.blueAccent[700],
        //     borderBottom: "none",
        //   },
        //   "& .MuiDataGrid-virtualScroller": {
        //     backgroundColor: colors.primary[400],
        //   },
        //   "& .MuiDataGrid-footerContainer": {
        //     borderTop: "none",
        //     backgroundColor: colors.blueAccent[700],
        //   },
        //   "& .MuiCheckbox-root": {
        //     color: `${colors.redAccent[200]} !important`,
        //   },
        // }}
      >
        {(searchResults.length > 0 && restaurants.state === "hasValue") &&
          <DataGrid
            rows={searchResults} columns={columns} />
        }
      </Box>
    </Box>
  );
};

export default GooglePlaces;
