import { Tabs, Tab, Grid, Autocomplete, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, Slider, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { recoilState } from "../dataStructure";
import Eatery, { blankEatery, getAllEateries } from "../model/eatery";
import { pickImage } from "../model/photos";
import Plate, { blankPlate, deletePlateById, platesRefreshState, createNewPlate, putPlate, getPlate } from "../model/plates";
import Tag, { getTags, putTag, tagRefreshState } from "../model/tag";
import { tokens } from "../theme";
import RatingGrid from "./ratingGrid";
import { selectedPlateId } from "../model/plates";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { RemoveCircle } from "@mui/icons-material";

function PlateForm({ open, plate }) {
    const theme = useTheme();
    // const plate = useRecoilValueLoadable<Plate>(getPlate);
    const [appState, setAppState] = useRecoilState(recoilState);
    const restaurants = useRecoilValueLoadable<Eatery[]>(getAllEateries);
    const tags = useRecoilValueLoadable<Tag[]>(getTags);
    const [currentPlate, setCurrentPlate] = useState(plate);
    const [plateId, setPlateId] = useState("");
    const [name, setName] = useState("");
    const [editPlate, setEditPlate] = useState(false);
    const [rating, setRating] = useState("0.0");
    const [ratingCount, setRatingCount] = useState(0);
    const [eatery, setEatery] = useState({ ...blankEatery });
    const [photos, setPhotos] = useState([]);
    const [photoUrl, setPhotoUrl] = useState("https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png");
    const [stickyPhoto, setStickyPhoto] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState("");
    const [plateTags, setPlateTags] = useState([]);
    const [plateStatus, setPlateStatus] = useState(0);
    const setSelectedPlateId = useSetRecoilState(selectedPlateId);
    const setTagsRefresh = useSetRecoilState(tagRefreshState);
    const setPlatesRefresh = useSetRecoilState(platesRefreshState);
    const hiddenPhotoFileInput = useRef(null);
    const [plateUpdated, setPlateUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const handleLoading = async () => {
        setLoading(true);
    };
    const handleLoaded = () => setLoading(false);
    const handleClose = () => {
        setAppState({ ...appState, plateDialogOpen: false });
        setEditPlate(false);
        setActiveTab(0);
    }

    const toggleEditPlate = () => {
        setEditPlate(prevEditPlate => !prevEditPlate);
    };

    // useEffect(() => {
    //     console.log(appState.currentPlate);
    //     setCurrentPlate({ ...appState.currentPlate });
    // }, [appState.currentPlate]);

    // useEffect(() => {
    //     console.log(appState.currentPlate);
    //     setSelectedPlateId(currentPlate.plate_id);
    // }, [currentPlate]);

    useEffect(() => {
        console.log("Active Tab:", activeTab);
    }, [activeTab]);

    let updatedPhotos = [...photos];
    let newPhotoUrl = "";

    useEffect(() => {
        // this instanitates the selected plated when it changes
        if (!appState.currentPlate) return
        if (plate.state !== "hasValue") return
        
        console.log("setting plate");
        const currentPlate = appState.currentPlate
        setCurrentPlate(currentPlate);
        
        // setAppState({ ...appState, currentPlate: plate.contents });
        console.log(currentPlate);
        // setPlateId(currentPlate.plate_id);
        setPlateId(currentPlate.plate_id);
        setEatery(currentPlate.eatery || blankEatery);
        setName(currentPlate.name);

        const ratingNumber = Number(currentPlate.rating);
        if (!isNaN(ratingNumber)) {
            setRating(ratingNumber.toFixed(1));
        } else {
            // Handle the case where currentPlate.rating is not convertible to a number
        }


        setRatingCount(currentPlate.rating_count);
        setPhotos(currentPlate.photos && currentPlate.photos.length > 0 ? currentPlate.photos : [currentPlate.photo_url]);
        setPhotoUrl(currentPlate.photo_url);
        setStickyPhoto(currentPlate.photo_is_sticky);
        setCurrentPhoto(currentPlate.photo_url || "");
        setPlateTags(currentPlate.tags || []);
        setPlateStatus(currentPlate.status || 0);
        updatedPhotos = [...photos];
        newPhotoUrl = "";
        console.log(appState.currentPlate);

    }, [open]);

    const marks = [
        {
            value: -2,
            label: 'Archive',
        },
        {
            value: -1,
            label: 'Moderate',
        },
        {
            value: 0,
            label: 'Approved',
        },
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 2,
            label: 'Featured',
        },
    ];

    function setRefresh() {
        setTagsRefresh(new Date().getTime().toString());
        setPlatesRefresh(new Date().getTime().toString());
    }
    async function remove(): Promise<any> {
        handleLoading();
        await deletePlateById(currentPlate.plate_id);
        setPlatesRefresh(new Date().getTime().toString());
        setCurrentPlate(blankPlate);
        handleLoaded();
        handleClose();
        return
    }
    async function createPlate(): Promise<any> {
        handleLoading();
        const ts = new Date().getTime().toString();
        const plate_template_id = crypto.randomUUID();
        const plateTemplate = { ...currentPlate, plate_id: plate_template_id, plate_template_id: plate_template_id, region_id: appState.currentRegionId.toString(), brand_id: currentPlate.eatery.brand_id, restaurant_id: currentPlate.eatery.brand_id + "_template" };
        await createNewPlate(plateTemplate);
        const updatedPlate: Plate = {
            ...currentPlate,
            date_time: ts,
            name: name,
            restaurant_id: eatery.restaurant_id,
            brand_id: eatery.brand_id,
            eatery: eatery,
            tags: plateTags,
            reviews: [],
            users: [],
            rating: 0,
            rating_count: 0,
            photo_url: newPhotoUrl === "" ? photoUrl : newPhotoUrl,
            photo_is_sticky: false,
            photos: (newPhotoUrl === "" ? photoUrl === "https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png" ? [] : [photoUrl] : [newPhotoUrl]),
            status: 0
        }
        console.log("new plate");
        console.log(updatedPlate);
        var response = await createNewPlate(updatedPlate);
        console.log(response);
        setAppState({ ...appState, currentPlate: { ...response } });
        setPlateUpdated(true);
        handleLoaded();
        return
    }
    async function savePlate(): Promise<any> {
        handleLoading();
        const updatedPlate: Plate = {
            ...currentPlate,
            name: name,
            tags: plateTags,
            photo_url: newPhotoUrl === "" ? photoUrl : newPhotoUrl,
            eatery: eatery,
            photo_is_sticky: stickyPhoto,
            photos: updatedPhotos,
            status: plateStatus,
        }
        console.log("existing plate")
        console.log(updatedPlate);
        // return
        setCurrentPlate(updatedPlate);
        await putPlate(updatedPlate);
        setPlateUpdated(true);
        await new Promise(resolve => setTimeout(resolve, 500));
        handleLoaded();
        return
    }
    async function uploadPhoto(event) {
        handleLoading();

        let file = event.target.files[0];
        console.log(file);
        const results = await pickImage(file, "op-admin");

        console.log(results);
        newPhotoUrl = results.uri;
        updatedPhotos.unshift(newPhotoUrl);
        setPhotos(updatedPhotos);
        setCurrentPhoto(newPhotoUrl);
        savePlate();
    }
    function onDragEnd(result) {
        console.log(result);
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        const reorderedPhotos = reorder(
            photos,
            result.source.index,
            result.destination.index
        );

        setPhotos([...reorderedPhotos]);
    }


    return (
        <Box>
            <Dialog
                key={currentPlate.plate_id}
                autoFocus
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
                maxWidth="xl"
                sx={{
                    margin: "10px !important",
                    left: "270px",
                    borderRadius: "3px",
                    overflow: "hidden"
                }}
            >
                {/* Form Tital and Ratings Data */}
                {(plate.state == "hasValue") &&
                    <DialogActions>
                        {loading &&
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                            </Box>}
                        <Box sx={{
                            display: "flex",
                            width: "100%"
                        }}>
                            <Box sx={{
                                width: "50%",
                                flex: "1 1 50%"
                            }}>
                                <Button onClick={() => {
                                    if (plateUpdated) {
                                        setPlateUpdated(false);
                                        setRefresh();
                                    }
                                    handleClose();
                                }}
                                    sx={{

                                    }}
                                >
                                    <img
                                        src="icon-arrow-left.svg"
                                        alt="Close"
                                        width="15"
                                        height="15"
                                    />
                                    <Typography
                                        sx={{
                                            textTransform: "none",
                                            color: "#DB0032",
                                            marginLeft: "5px",
                                            fontWeight: "900",
                                            fontSize: "14px"
                                        }}>
                                        Back
                                    </Typography>
                                </Button>
                            </Box>

                            <Box sx={{
                                width: "50%",
                                flex: "1 1 50%",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                {plateStatus < 1 &&
                                    <Button onClick={() => {
                                        return window.confirm(`Are you sure you want to delete ${currentPlate.name}?`) ? remove() : null;
                                    }}

                                        sx={{
                                            textTransform: "none",
                                            color: "#DB0032",
                                            marginLeft: "5px",
                                            fontWeight: "900",
                                            fontSize: "14px"
                                        }}>
                                        <img
                                            src="icon-x.svg"
                                            alt="Remove"
                                            width="15"
                                            height="15"
                                        />
                                        <Typography
                                            sx={{
                                                textTransform: "none",
                                                color: "#DB0032",
                                                marginLeft: "5px",
                                                fontWeight: "900",
                                                fontSize: "14px"
                                            }}>
                                            Remove
                                        </Typography>
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </DialogActions>
                }
                {plate.state == "hasValue" &&
                    <DialogTitle id="form-dialog-title" sx={{
                        display: 'flex',
                        margin: "0",
                        padding: "0 18px 10px 18px",
                        lineHeight: "1"
                    }}>
                        <Typography
                            sx={{
                                flex: 3,
                                fontSize: "30px",
                                color: "#25282F",
                                fontWeight: "900",
                                margin: "0",
                                padding: "0",
                                lineHeight: "1"
                            }}
                        >
                            {currentPlate && currentPlate.plate_id !== "" ? (currentPlate.name) : ("Add a Plate")}</Typography>
                    </DialogTitle>
                }

                <Box sx={{ borderBottom: "1px solid #F4F0EA", width: "100%" }}>
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} sx={{
                        "& .MuiButtonBase-root": {
                            textTransform: "none",
                            fontWeight: "900",
                        }
                    }}>
                        <Tab label="Settings"></Tab>
                        <Tab label="Reviews"></Tab>
                        <Tab label="Photos"></Tab>
                        <Tab label="Tags"></Tab>
                    </Tabs>
                </Box>

                {/* Form Content */}
                {(plate.state == "hasValue" && restaurants.state == "hasValue" && tags.state === "hasValue") &&
                    <DialogContent>

                        {activeTab === 0 && (
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ border: "1px solid #F4F0EA", borderRadius: "6px", padding: "20px", marginBottom: "15px" }}>
                                    {editPlate ? (
                                        <Box
                                            sx={{
                                                backgroundColor: "#F4F0EA",
                                                borderRadius: "6px",
                                                display: "flex",
                                                padding: "15px 25px",
                                                width: "100%",
                                                textAlign: "left",
                                                textTransform: "none",
                                                color: "black"
                                            }}>

                                            <Typography sx={{
                                                flex: "1"
                                            }}>
                                                Editing the plate...</Typography>
                                            <Button onClick={() => {
                                                toggleEditPlate();
                                            }}
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "#DB0032",
                                                    borderRadius: "4px",
                                                    display: "flex",
                                                    padding: "0 10px"
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                if (currentPlate.plate_id === "") {
                                                    createPlate();
                                                    toggleEditPlate();
                                                } else {
                                                    savePlate();
                                                    toggleEditPlate();
                                                }
                                            }}
                                                sx={{
                                                    backgroundColor: "#DB0032",
                                                    color: "white",
                                                    borderRadius: "4px",
                                                    display: "flex",
                                                    padding: "0 10px"
                                                }}>
                                                Save
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Button sx={{
                                            backgroundColor: "#F4F0EA",
                                            borderRadius: "6px",
                                            display: "flex",
                                            padding: "15px 25px",
                                            width: "100%",
                                            textAlign: "left",
                                            textTransform: "none",
                                            color: "black"
                                        }}
                                            onClick={toggleEditPlate}
                                        >
                                            <Typography sx={{
                                                flex: "1"
                                            }}>
                                                Edit plate details</Typography>
                                            <Box sx={{
                                                backgroundColor: "#DB0032",
                                                color: "white",
                                                borderRadius: "4px",
                                                display: "flex",
                                                padding: "0 10px"
                                            }}>
                                                Edit Plate
                                            </Box>
                                        </Button>
                                    )}

                                    <Box sx={{ display: "flex", padding: "20px 0", width: "100%" }}>
                                        <Box sx={{ width: "400px", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                                            <Box sx={{ position: "relative", width: "200px", height: "200px", overflow: "hidden", borderRadius: "6px", marginTop: "10px", }}>
                                                <img src={currentPhoto} style={{ objectFit: "cover", width: "100%", height: "100%", position: "absolute" }} />
                                            </Box>
                                            <Button sx={{
                                                padding: "5px 25px",
                                                marginTop: "10px",
                                                width: "200px",
                                                textAlign: "center",
                                                textTransform: "none",
                                                color: "#DB0032",
                                            }}
                                                onClick={() => {
                                                    setActiveTab(2)
                                                }}
                                            >
                                                See all photos
                                            </Button>
                                        </Box>
                                        <Box sx={{ width: "100%" }}>


                                            {editPlate ? (
                                                <TextField
                                                    id="name-select-small"
                                                    label="Plate Name"
                                                    value={name}
                                                    fullWidth
                                                    sx={{ mt: 1 }}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        setName(event.target.value);
                                                    }}
                                                />
                                            ) : (
                                                <Typography sx={{ fontSize: "22px", fontWeight: "900" }}>
                                                    {currentPlate.name}
                                                </Typography>
                                            )}

                                            {editPlate ? (
                                                eatery && (
                                                    <FormControl sx={{ mt: 1, position: "relative" }}>
                                                        <InputLabel id="eatery-select-small" shrink={true} htmlFor="eatery-select">Eatery</InputLabel>
                                                        <Select
                                                            labelId="eatery-select-small"
                                                            label="Eatery"
                                                            sx={{ textOverflow: "ellipsis", overflow: "hidden", }}
                                                            id="eatery-select"
                                                            notched={true}
                                                            value={eatery.restaurant_id}
                                                            onChange={e => {
                                                                console.log(e.target.value);
                                                                setEatery(restaurants.contents.find(({ restaurant_id }) => restaurant_id === e.target.value))
                                                            }}
                                                        >
                                                            {restaurants.state === "hasValue" && restaurants.contents.map((restaurant: Eatery, index) => (
                                                                <MenuItem key={`restaurant-select-${index}`}
                                                                    value={restaurant.restaurant_id}
                                                                    selected={eatery.restaurant_id === restaurant.restaurant_id}>{restaurant.name + " - " + restaurant.city}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )
                                            ) : (
                                                <Typography sx={{ fontSize: "14px" }}>
                                                    at {eatery.name}
                                                </Typography>
                                            )}


                                            <Box sx={{ display: "flex", margin: "10px 0" }}>
                                                {editPlate ? (
                                                    <Box sx={[{ width: "100%", padding: "0 0 20px 0" }]}>
                                                        <Typography sx={{ fontSize: "13px", fontWeight: "900", width: "100%" }}>
                                                            Status
                                                        </Typography>
                                                        {/* Status Slider */}
                                                        <Slider
                                                            sx={[{ width: "80%", margin: "0 10%" }]}
                                                            color={plateStatus < 0 ? "primary" : "secondary"}
                                                            aria-label="Plate Status"
                                                            value={plateStatus}
                                                            onChange={(event, value) => setPlateStatus(value as number)}
                                                            getAriaValueText={(value) => value.toString()}
                                                            step={1}
                                                            valueLabelDisplay="auto"
                                                            marks={marks}
                                                            min={-2}
                                                            max={2}
                                                        />
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                                        <Typography sx={{ fontSize: "13px", fontWeight: "900", width: "60px" }}>
                                                            Status
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "13px", padding: "0 6px", }}>
                                                            Active
                                                        </Typography>
                                                    </Box>
                                                )}

                                            </Box>

                                            <Box sx={{ display: "flex", margin: "10px 0" }}>
                                                <Typography sx={{ fontSize: "13px", fontWeight: "900", width: "60px" }}>
                                                    Rating
                                                </Typography>
                                                <img
                                                    src="icon-star.png"
                                                    alt="Star Rating"
                                                    width="20"
                                                    height="20"
                                                />
                                                {rating === '0.0' ? (
                                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                        <Typography sx={{ fontSize: "16px", color: "gray", fontWeight: "900", padding: "0 6px", }}>
                                                            No Rating Yet
                                                        </Typography>
                                                    </Box>
                                                ) : (

                                                    <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
                                                        <Typography sx={{ fontSize: "22px", fontWeight: "900", padding: "0 6px", }}>
                                                            {rating}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "14px", fontWeight: "900", }}>
                                                            ({ratingCount})
                                                        </Typography>
                                                    </Box>
                                                )}

                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ border: "1px solid #F4F0EA", borderRadius: "6px", padding: "20px", marginBottom: "15px" }}>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "900" }}>
                                        Rankings
                                    </Typography>
                                    <Grid container spacing={2} sx={{ margin: "0px 0px 20px 0" }}>
                                        <Grid item xs={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "900" }}>In Region</Typography>
                                                <Typography sx={{ fontSize: '36px' }}>#34</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "900" }}>On Menu</Typography>
                                                <Typography sx={{ fontSize: '36px' }}>#34</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography sx={{ fontSize: "22px", fontWeight: "900" }}>
                                        Tag Rankings
                                    </Typography>
                                    <Grid container spacing={2} sx={{ margin: "0px 0px 20px 0" }}>
                                        <Grid item xs={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "900" }}>American</Typography>
                                                <Typography sx={{ fontSize: '36px' }}>#34</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "900" }}>Burger</Typography>
                                                <Typography sx={{ fontSize: '36px' }}>#34</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "900" }}>Gastropub</Typography>
                                                <Typography sx={{ fontSize: '36px' }}>#34</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Box>
                                                <Typography sx={{ fontSize: "14px", fontWeight: "900" }}>Bar Food</Typography>
                                                <Typography sx={{ fontSize: '36px' }}>#34</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        )}
                        {activeTab === 1 && (
                            <Box>

                                <RatingGrid plate={currentPlate} />
                            </Box>
                        )}
                        {activeTab === 2 && (
                            <Box sx={{ border: "1px solid #F4F0EA", borderRadius: "6px", padding: "20px", marginBottom: "15px" }}>
                                {/* Photos Right Column move this to component */}
                                {plateId !== "" &&
                                    <Box sx={{ width: "100%", marginBottom: "10px" }}>
                                        {/* <AddAPhotoIcon
                                onClick={event => { hiddenPhotoFileInput.current.click() }}
                                sx={{ color: colors.redAccent[400], fontSize: "3em", m: 0 }}></AddAPhotoIcon> */}
                                        <Button sx={{
                                            backgroundColor: "#F4F0EA",
                                            borderRadius: "6px",
                                            display: "flex",
                                            padding: "15px 25px",
                                            width: "100%",
                                            textAlign: "left",
                                            textTransform: "none",
                                            color: "black"
                                        }}
                                            onClick={() => { hiddenPhotoFileInput.current.click(); }}
                                        >
                                            <Typography sx={{
                                                flex: "1"
                                            }}>
                                                Add a photo, delete a photo, and drag & drop to re-order them. To make a photo the primary, star it in the list below. </Typography>
                                            <Box sx={{
                                                backgroundColor: "#DB0032",
                                                color: "white",
                                                borderRadius: "4px",
                                                display: "flex",
                                                padding: "0 10px"
                                            }}>
                                                Add a Photo
                                            </Box>
                                        </Button>
                                        <input type="file"
                                            ref={hiddenPhotoFileInput}
                                            name={plateId}
                                            onChange={uploadPhoto}
                                            hidden />
                                    </Box>
                                }
                                <Box sx={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(8, 1fr)",
                                    gridGap: "10px;",
                                    width: "100%"
                                }}>
                                    <Box sx={{
                                        position: "relative",
                                        gridColumn: "span 2",
                                        gridRow: "span 2",
                                    }}>

                                        <img src={currentPhoto} style={{ objectFit: "cover", width: "100%", aspectRatio: "1/1", display: "block", borderRadius: "4px" }} />
                                        {plateId !== "" &&
                                            <Box sx={{ borderRadius: "4px", overflow: "hidden" }}>
                                                {currentPhoto === photoUrl ?
                                                    <Box sx={{ position: "absolute", p: 0, right: 5, left: 5, bottom: 5, borderRadius: "5px", backgroundColor: "rgba(219,0,50,0.8)", color: "white" }}>
                                                        <Typography sx={{ display: "flex", alignItems: "center", position: "relative", padding: "6px" }}>
                                                            <StarIcon sx={{ color: "white", fontSize: "1.3em !important", marginRight: "10px" }}></StarIcon>
                                                            Primary Image
                                                        </Typography>
                                                    </Box>
                                                    :
                                                    <Box onClick={() => {
                                                        setPhotoUrl(currentPhoto);
                                                        updatedPhotos.splice(updatedPhotos.indexOf(currentPhoto), 1);
                                                        updatedPhotos.unshift(currentPhoto);
                                                        setPhotos(updatedPhotos);
                                                        setStickyPhoto(true);
                                                    }}
                                                        sx={{
                                                            position: "absolute", p: 0, right: 5, left: 5, bottom: 5, borderRadius: "5px", backgroundColor: "rgba(10,20,30,0.8)", color: "white"
                                                        }}>
                                                        <Typography sx={{ display: "flex", alignItems: "center", position: "relative", padding: "6px" }}>
                                                            <StarBorderIcon sx={{ outline: "white", fontSize: "1.3em !important", marginRight: "10px" }}></StarBorderIcon>
                                                            Set as Primary
                                                        </Typography>
                                                    </Box>
                                                }
                                                {currentPhoto !== photoUrl &&

                                                    <Box onClick={() => {
                                                        updatedPhotos.splice(updatedPhotos.indexOf(currentPhoto), 1);
                                                        setPhotos(updatedPhotos);
                                                    }}
                                                        sx={{ position: "absolute", p: 0, right: 5, left: 5, bottom: -40, borderRadius: "5px", color: "#DB0032" }}>
                                                        <Typography sx={{ display: "flex", alignItems: "center", position: "relative", padding: "6px" }}>
                                                            <RemoveCircle sx={{ color: "#DB0032", fontSize: "1.2em", marginRight: "10px" }}></RemoveCircle>
                                                            Remove Image
                                                        </Typography>
                                                    </Box>
                                                }
                                                {/* <Typography sx={{ fontSize: "9px", m: "0 40px 0 40px", maxWidth: "100%", textAlign: "center" }}>{currentPhoto}</Typography> */}
                                            </Box>
                                        }
                                    </Box>
                                    <Box sx={{
                                        width: "100%",
                                        gridColumn: "span 6",
                                        gridRow: "span 6",

                                    }}>
                                        {(photos.length > 0) &&

                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(6, 1fr)', width: "100%" }}>
                                                    {photos.map((photo, index) => (

                                                        <Droppable droppableId={"photodrop_" + index} direction="horizontal" key={"photodrop_" + index} ignoreContainerClipping>
                                                            {provided => (
                                                                <Box {...provided.droppableProps} ref={provided.innerRef}>
                                                                    <Draggable
                                                                        {...provided.droppableProps}
                                                                        draggableId={photo}
                                                                        index={index}
                                                                        key={"box_" + photo}>
                                                                        {provided => (
                                                                            <Box>
                                                                                <Box ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <img src={photo}
                                                                                        style={{ borderRadius: "4px", objectFit: "cover", width: "100%", aspectRatio: "1/1" }}
                                                                                        key={photo}
                                                                                        onClick={() => {
                                                                                            setCurrentPhoto(photo);
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        )}
                                                                    </Draggable>
                                                                    {provided.placeholder}
                                                                </Box>
                                                            )
                                                            }
                                                        </Droppable>
                                                    ))}
                                                </Box>
                                            </DragDropContext>
                                        }
                                    </Box>
                                </Box>

                            </Box>
                        )}
                        {activeTab === 3 && (
                            <Box>
                                {tags.state === "hasValue" &&
                                    <Autocomplete
                                        multiple
                                        freeSolo
                                        sx={{ mt: 1 }}
                                        options={tags.contents.map((tag) => (tag.tag))}
                                        getOptionLabel={option => option}
                                        value={plateTags}
                                        onChange={(event: any, newValue: string[] | null) => {
                                            newValue.forEach((newTag) => {
                                                console.log(newTag);
                                                console.log(tags.contents.findIndex((tag) => tag.tag === newTag));
                                                if (tags.contents.findIndex((tag) => tag.tag === newTag) === -1)
                                                    putTag({ tag: newTag, tag_type: "plate_tag" });
                                            });
                                            setPlateTags(newValue);
                                        }}
                                        renderInput={params => (
                                            <TextField {...params} label="Tags" fullWidth />
                                        )}
                                    />
                                }
                            </Box>
                        )}
                    </DialogContent>
                }

                {/* Bottom Buttons and Progress Bar */}
                {((currentPlate && activeTab !==0) || editPlate) &&
                    <DialogActions>
                        {loading &&
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                            </Box>}
                        {plateStatus < 1 &&
                            <Button onClick={(e) => {
                                return window.confirm(`Are you sure you want to delete ${currentPlate.name}?`) ? remove() : null;
                            }}
                                color="warning">
                                Remove
                            </Button>
                        }
                        <Button style={{backgroundColor: "#DB0032", color: "white"}} onClick={(e) => {
                            if (plateUpdated) {
                                setPlateUpdated(false);
                                setRefresh();
                            }
                            handleClose();
                        }} color="warning">
                            Close
                        </Button>
                        <Button style={{backgroundColor: "#DB0032", color: "white"}} onClick={() => {
                            if (currentPlate.plate_id === "")
                                createPlate()
                            else
                                savePlate()
                        }} color="secondary">
                            Save
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleLoaded}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export default PlateForm;