import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import Eatery, { putEatery, eateryRefreshState } from '../model/eatery'
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import Tag, { getTags } from "../model/tag";

    const EateryDialog = ({currentRestaurant}) => {
        const tags = useRecoilValueLoadable<Tag[]>(getTags);
        const setRestaurantsRefresh = useSetRecoilState(eateryRefreshState);
        const [name, setName] = useState(currentRestaurant ? currentRestaurant.name : "");
        const [address, setAddress] = useState(currentRestaurant ? currentRestaurant.address : "");
        const [city, setCity] = useState(currentRestaurant ? currentRestaurant.city : "");
        const [state, setState] = useState(currentRestaurant ? currentRestaurant.state : "");
        const [zip, setZip] = useState(currentRestaurant ? currentRestaurant.zip : "");
        const [restaurantTags, setRestaurantTags] = useState(currentRestaurant ? currentRestaurant.tags : []);
        const [open, setOpen] = useState(false);
        const handleOpen = () => setOpen(true);
        const handleClose = () => setOpen(false);
        const [tagAutocomplete, setTagAutoComplete] = useState("");
        async function saveEatery(): Promise<any> {
          const updatedRestaurant:Eatery = {
              ...currentRestaurant,
              address: address,
              city: city,
              state: state,
              zip: zip, // @ts-ignore
              tags: restaurantTags
          }
          
          // alert(JSON.stringify(updatedRestaurant, null, 4));
    
          await putEatery(updatedRestaurant);
          setRestaurantsRefresh(new Date().getTime().toString());
          return
      }
        return (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableEnforceFocus
          >
            <DialogTitle id="form-dialog-title">{name}</DialogTitle>
            <DialogContent>
            <Box sx={{ width: 400, marginTop: 2 }}>
              <TextField
                id="outlined-controlled"
                label="Address"
                defaultValue={address}
                fullWidth
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAddress(event.target.value);
                }}
              />
              <TextField
                id="outlined-controlled"
                label="City"
                sx={{ width: '34ch' }}
                defaultValue={city}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setCity(event.target.value);
                }}
              />
              <TextField
                id="outlined-controlled"
                label="State"
                sx={{ width: '12ch' }}
                defaultValue={state}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setState(event.target.value);
                }}
              />
              <TextField
                id="outlined-controlled"
                label="Zip"
                sx={{ width: '12ch' }}
                defaultValue={zip}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setZip(event.target.value);
                }}
              />
              {tags.state === "hasValue" &&
              <Autocomplete
                  multiple
                  freeSolo
                  options={tags.contents.map((tag) => (tag.tag))}
                  getOptionLabel={option => option}
                  value={restaurantTags}
                  onChange={(event: any, newValue: [] | null) => {
                    setRestaurantTags(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Tags" fullWidth />
                  )}
                />
              }
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="warning">
                Cancel
              </Button>
              <Button onClick={saveEatery} color="secondary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        );
      }

export default EateryDialog;
