const formatDate = (timestamp) => {
    timestamp = parseInt(timestamp, 10); 

    const date = new Date(timestamp);
    const now = new Date();
    const timeDiff = now - date;
    const minutes = Math.floor(timeDiff / 60000);
    const hours = Math.floor(timeDiff / 3600000);
    const days = Math.floor(timeDiff / 86400000);

    if (minutes < 1) return `just now`;
    if (minutes < 60) return `${minutes}m ago`;
    if (hours < 24) return `${hours}h ago`;
    if (days <= 7) return `${days}d ago`;

    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    
    if (now.getFullYear() === year) {
        return `${month} ${day}`;
    }
    return `${month} ${day}, ${year}`;
};

// Correct way to export the function
export default formatDate;
