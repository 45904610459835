import { Box, Button, Menu, MenuItem, Popover, Rating, Typography, useTheme } from "@mui/material";
import { DataGrid, GridColumns, GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { recoilState } from "../dataStructure";
import Plate, { getPlateById, platesRefreshState, putPlate } from '../model/plates';
import { tokens } from "../theme";
import GridStyles from "./gridStyles";
import UserPost, { getUserReviews, reviewRefreshState, updateReview } from "../model/review";
import Checkbox from '@mui/material/Checkbox';

function UserReviewGrid({ user }) {

    const [appState, setAppState] = useRecoilState(recoilState);
    const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
    const [imagePopover, setImagePopOver] = useState(false);
    const actionMenuOpen = Boolean(menuAnchor);
    const [selectedReviews, setSelectedReviews] = useState([]);
    const plateReviews = useRecoilValueLoadable<Plate[]>(getUserReviews);
    const setReviewsRefresh = useSetRecoilState(reviewRefreshState);
    const setPlatesRefresh = useSetRecoilState(platesRefreshState);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'date_time',
            sort: 'desc',
        },
    ]);

    const handleActionMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };
    const handleActionMenuClose = () => {
        setMenuAnchor(null);
    };
    let mouseoverTimer;
    const handleOver = (event: React.MouseEvent<HTMLImageElement>) => {

        if (!anchorEl) {
            setAnchorEl(event.currentTarget);
            mouseoverTimer = setTimeout(() => handleClose(), 2000);
            return;
        }
        if (anchorEl && event.currentTarget.src !== anchorEl.src) {
            handleClose();
            setAnchorEl(event.currentTarget);
            mouseoverTimer = setTimeout(() => handleClose(), 2000);
            return;
        }
        if (anchorEl && event.currentTarget.src === anchorEl.src) {
            clearTimeout(mouseoverTimer);
            return
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, review) => {
        if (!selectedReviews.find(({ review_id }) => review_id === review.review_id)) {
            console.log("add to change set : " + review.review_id);
            // add the review to the change set
            setSelectedReviews([...selectedReviews, review]);
        } else {
            console.log("remove from chage set : " + review.review_id);
            // remove the review from the change set
            let modifableSelection = [...selectedReviews];
            let selectedIndex = [...selectedReviews].findIndex(({ review_id }) => review_id === review.review_id)
            modifableSelection.splice(selectedIndex, 1)
            setSelectedReviews(modifableSelection);
        }
    };

    useEffect(() => {
        console.log(selectedReviews);
    }, [selectedReviews]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const columns: GridColumns = [
        {
            field: "review_id",
            headerName: "",
            sortable: false,
            flex: .25,
            valueFormatter: ({ value }) => `${value}`,
            renderCell: (params) => {

                return <Checkbox
                    key={params.row.review_id}
                    checked={Boolean(selectedReviews.find(({ review_id }) => review_id === params.row.review_id))}
                    onChange={(e) => {
                        handleChange(e, params.row);
                    }} />
            }
        },
        {
            field: "status",
            headerName: "",
            align: "center",
            flex: .75,
            sortable: false,
            renderCell: (params) => {
                return <Typography sx={{ display: "inline-block", whiteSpace: "pre-line" }}>{
                    (params.row.status === 0) ? "Removed" : (params.row.status === 1) ? "New" : "Approved"
                }</Typography>
            },
            disableExport: true,
        },
        {
            field: "date_time",
            headerName: "TS",
            flex: 1,
            align: "center",
            sortable: false,
            renderCell: (params) => {
                let d = new Date(Number(params.value));
                return <Typography sx={{ display: "inline-block", whiteSpace: "pre-line" }}>{d.getMonth() + "-" + d.getDay() + "-" + d.getFullYear()}<br />{d.toTimeString().split(" ")[0]}</Typography>
            },
            disableExport: true,
        },
        {
            field: "photo_url",
            headerName: "<img>",
            flex: .25,
            sortable: false,
            renderCell: (params) => {
                return <Box>
                    <img src={params.value}
                        width="100%"
                        onMouseEnter={handleOver}
                        style={{ aspectRatio: "4,3" }} />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <img src={params.value} width="200" style={{ aspectRatio: "4,3" }}
                            onMouseOver={handleOver} onMouseOut={handleClose} />
                    </Popover>
                </Box>
            },
            disableExport: true,
        },
        {
            field: "review",
            headerName: "Review",
            flex: 2.5,
            cellClassName: "review-column--cell",
            disableExport: false,
        },
        {
            field: "plate",
            headerName: "Plate",
            flex: 1.5,
            cellClassName: "review-column--cell",
            disableExport: false,
            renderCell: (params) => params.row.plate.name,
        },
        {
            field: "rating",
            headerName: "rating",
            align: "center",
            flex: 1.25,
            cellClassName: "review-column--cell",
            renderCell: (params) => {
                // let rating = Number(appState.currentPlate.ratings[appState.currentPlate.reviews.findIndex(({ review_id }) => review_id === params.row.review_id)])
                let rating = Number(params.row.rating)
                return <Rating name="half-rating" size="small" defaultValue={rating} precision={0.5} readOnly />
            },
            sortable: true,
            disableExport: false,
        }
    ];
    async function moderateSelectedReviews(status): Promise<any> {
        await Promise.all(selectedReviews.map(async (review: UserPost) => {
            const plate:Plate = await getPlateById(review.plate_id);
            if (plate && plate.plate_id) {
                console.log("plate found");
                console.log(plate);
                const modifiedPlateReviews = [...plate.reviews];
                const modifiedPlateRatings = [...plate.ratings];
                const modifiedPlateGallery = [...plate.photos];
                let modifiedRatingCount = Number(plate.rating_count);
                let modifiedDefaultPhoto = plate.photo_url;
                const approvedReview = {
                    ...review,
                    status: status
                }
                // if its changing to a zero we are going to next have to update the plate and remove images and ratings
                if (review.status && review.status > 0 && status === 0) {
                    console.log("remove review");
                    let selectedIndex = plate.reviews.findIndex(({ review_id }) => review_id = review.review_id);
                    if(selectedIndex === -1) {
                        // this means this review is some form of test data that either never got on the plate, or was manually removed
                        await updateReview({...review, status: 0});
                    }
                    modifiedPlateReviews.splice(selectedIndex, 1);
                    modifiedPlateRatings.splice(selectedIndex, 1);

                    // have to find photos in this list separately since not all plate photos are attached to reviews and photos can be sorted
                    selectedIndex = plate.photos.findIndex((photo) => photo === review.photo_url);
                    console.log(selectedIndex);
                    modifiedPlateGallery.splice(selectedIndex, 1);

                    if (review.photo_url === plate.photo_url)
                        modifiedDefaultPhoto = "https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png";


                    modifiedRatingCount--;
                } // if its changing from a zero to a 2 we need to add the photos and rating back into the aggregate plate
                else if (review.status === 0 && status === 2) {
                    modifiedPlateReviews.push(review);
                    modifiedPlateRatings.push(Number(review.rating));
                    modifiedPlateGallery.push(review.photo_url);

                    if (plate.photos.length === 0)
                        modifiedDefaultPhoto = review.photo_url;

                    modifiedRatingCount++;
                }

                // need to make an api method to do these as a batch
                console.log(approvedReview);
                await updateReview(approvedReview);
                console.log("rating change?", modifiedRatingCount !== plate.rating_count);
                console.log("rating change?", modifiedRatingCount);
                console.log("rating change?", plate.rating_count);
                if (modifiedRatingCount !== plate.rating_count) {
                    const modifiedPlate:Plate = {
                        ...plate,
                        status: modifiedRatingCount === 0 ? 0 : plate.status,
                        reviews: [...modifiedPlateReviews],
                        ratings: modifiedRatingCount < 0 ? [] : [...modifiedPlateRatings],
                        photo_url: modifiedDefaultPhoto,
                        photos: [...modifiedPlateGallery],
                        rating_count: modifiedRatingCount < 0 ? 0 : modifiedRatingCount,
                        rating: (modifiedRatingCount === 0 || modifiedPlateRatings.length === 0) ? 0 : (Number(modifiedPlateRatings.reduce((a, b) => a + b)) / modifiedRatingCount)
                    }
                    console.log(modifiedPlate);
                    await putPlate(modifiedPlate);
                    setAppState({ ...appState, currentPlate: { ...modifiedPlate } });
                }
            } else {
                await updateReview({...review, status: 0});
            }
        }));
        // console.log(plate.reviews);
        // console.log(modifiedPlateReviews);
        // console.log(plate.ratings);
        // console.log(modifiedPlateRatings);
        // console.log(plate.photos);
        // console.log(modifiedPlateGallery);

        // setPlatesRefresh(new Date().getTime().toString());
        setReviewsRefresh(new Date().getTime().toString());
        handleActionMenuClose();
        setSelectedReviews([]);


    }

    return (
        <Box
            m="10px 0 0 0"
            height="35vh"
            sx={GridStyles(colors)}>
            <Box sx={{ display: "flex", width: "100%" }}>
                <Typography sx={{ fontWeight: "bold", flex: 1 }}>Ratings & Reviews</Typography>
                <Box>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleActionMenuOpen}
                    >
                        Action
                    </Button>
                    <Menu
                        id="review-action-menu"
                        aria-labelledby="action-menu-button"
                        anchorEl={menuAnchor}
                        open={actionMenuOpen}
                        onClose={handleActionMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MenuItem onClick={() => moderateSelectedReviews(2)}>Set as Approved</MenuItem>
                        <MenuItem onClick={() => moderateSelectedReviews(0)}>Set as Removed</MenuItem>
                    </Menu>
                </Box>
            </Box>

            {(plateReviews.state === "hasValue") &&
                // <PlateGrid/>
                <DataGrid
                    onRowClick={(params) => handleChange(null, params.row)}
                    headerHeight={35}
                    hideFooter={true}
                    autoHeight={true}
                    getRowHeight={() => 'auto'}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    getRowId={(row) => row.review_id}
                    rows={plateReviews.contents}
                    columns={columns}
                    showColumnRightBorder={false}
                />
            }
        </Box>
    )
}

export default UserReviewGrid;