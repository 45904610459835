import Eatery from "../model/eatery";
import Plate, { createNewPlate, importCheck, putPlate } from "../model/plates";
import { putTag } from "../model/tag";
import readPlatesCsv from "./readPlateImport";

//var csv is the CSV file with headers
export interface Import extends Plate {
    excludetags?: string[] | null;
    exists?: boolean
}
export default async function csvTOJSON(file, tagList = [], eateries = [], region_id:string) {

    const imports: Import[] = await readPlatesCsv(file, tagList);
    const photoUrl = "https://d3mfg3sbygk1hl.cloudfront.net/temp-plate.png";
    const linesToOmit: number[] = [];

    function arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    function combinedTags (eateryTags, platetags, excludeTags:string[]|null = null):string[] {
        const tags = Array.from(new Set([...platetags, ...eateryTags]));
        return !excludeTags ? tags : tags.filter((tag) => !excludeTags.includes(tag));
        // if (!excludeTags || excludeTags.findIndex((tag) => tag === tags[j]) === -1)
    }

    console.log(imports.length);
    console.log(imports);
    const ts = new Date().getTime().toString();
    for (let i in imports) {
        // process the tags...
        for (let j in imports[i].tags) {
            if (imports[i].tags[j] !== "" && tagList.findIndex((tag) => tag.tag === imports[i].tags[j]) === -1) {
                console.log(`new tag: ${imports[i].tags[j]}`);
                // add a new tag
                await putTag({ tag: imports[i].tags[j], tag_type: "plate_tag" });
            }
        }
        // check to see if we have of an existing eatery to update
        let existingEatery: Eatery = eateries.find((eatery) => eatery.restaurant_id === imports[i].restaurant_id);

        if (!existingEatery) {
            // the restaurant_id for the eatery is invalid so we can't import this plate
            console.log("invalid eatery");
            linesToOmit.push(Number(i));
            continue; //exit the loop
        }
        
        // we have to look these up separately becuase we don't have the complete plate anymore
        // let existingPlate: Plate = plates.find((plate) => (plate.plate_id === imports[i].plate_id || (plate.name.trim() === imports[i].name.trim() && plate.restaurant_id === imports[i].restaurant_id)));
        let existingPlate: Plate = await importCheck({plate_id: imports[i].plate_id , restaurant_id: imports[i].restaurant_id, name: imports[i].name});
        // console.log({plate_id: imports[i].plate_id , restaurant_id: imports[i].restaurant_id, name: imports[i].name});
        if (existingPlate) {
            imports[i].plate_id = existingPlate.plate_id;
            imports[i].exists = true;
            console.log("Plate Found:");
            console.log(existingPlate);

            // has the name of the plate changed?
            if (existingPlate &&
                existingPlate.name === imports[i].name
                && existingPlate.restaurant_id === imports[i].restaurant_id
                && (existingPlate.tags === undefined
                    || (existingPlate.tags
                        && arrayEquals(Array.from(new Set([...existingPlate.tags, ...imports[i].tags, ...existingEatery.tags])), existingPlate.tags)))) {
                console.log("no changes");
                // nothing has changed so we omit this line
                linesToOmit.push(Number(i));
                continue; //exit the loop
            }

            // since we know something has changed we will replace the current row with the existing object
            imports[i] = {
                ...existingPlate,
                region_id: region_id,
                name: imports[i].name,
                restaurant_id: imports[i].restaurant_id,
                tags: combinedTags(imports[i].tags, existingEatery.tags, imports[i].excludetags),
                exists: true,
            }
            continue;

        } else {
            console.log("New Plate");
            imports[i] = {
                ...imports[i],
                region_id: region_id,
                tags: combinedTags(imports[i].tags, existingEatery.tags, imports[i].excludetags),
                date_time: ts,
                eatery: existingEatery,
                brand_id: existingEatery.brand_id,
                ratings: [],
                rating_count: 0,
                reviews: [],
                users: [],
                photo_url: photoUrl,
                photos: [],
                photo_is_sticky: false,
                status: 0
            }
            // console.log(imports[i]);
            continue;
        }
    }

    // now inset the resords on at a time
    console.log(linesToOmit);
    for (let i in imports) {
        console.log(i);
        console.log(!linesToOmit.includes(Number(i)))
        if (!linesToOmit.includes(Number(i))) {
            if (imports[i].excludetags)
                delete imports[i].excludetags;
            // return { recordsImported: imports.length, recordsRejected: linesToOmit.length }
            if (imports[i].exists) {
                delete imports[i].exists;
                await putPlate(imports[i]);
            } else {
                await createNewPlate(imports[i]);
            }
            console.log("Import:");
            console.log(JSON.stringify(imports[i], null, 4));
        }
    }
    return { recordsImported: imports.length, recordsRejected: linesToOmit.length }


}