import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Button, Backdrop, CircularProgress, Grid, Typography
} from '@mui/material';
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from 'recoil';
import Header from '../../components/Header';
import { recoilState } from '../../dataStructure';
import Eatery, { getAllEateries } from '../../model/eatery';
import Plate, { getPlate, getPlateById, getPlates, platesRefreshState, selectedPlateId } from '../../model/plates';
import Tag, { getTags, tagRefreshState, tagType } from '../../model/tag';
import { blankUser } from '../../model/user';
import ExploreCard from '../../components/ExploreCard';
import UserPost, { getReviewsByRegion } from '../../model/review';
import PlateForm from '../../components/plateDialog';
import EateryForm from '../../components/EateryDialog';

function Reviews() {
  const [appState, setAppState] = useRecoilState(recoilState);
  const [open, setOpen] = useState(false);
  const plate = useRecoilValueLoadable<Plate>(getPlate);
  const reviewsLoadable = useRecoilValueLoadable<UserPost[]>(getReviewsByRegion);
  const setTagsRefresh = useSetRecoilState(tagRefreshState);
  const setPlatesRefresh = useSetRecoilState(platesRefreshState);
  const [tagState, setTagType] = useRecoilState(tagType);
  const [loading, setLoading] = useState(false);
  const [currentRestaurant, setCurrentRestaurant] = useState<Eatery | null>();

  // Handle loading state based on Recoil value loadable state
  useEffect(() => {
    if (reviewsLoadable.state === 'loading') setLoading(true);
    else setLoading(false);

    if (tagState !== 'plate_tag') {
      setTagType('plate_tag');
    }
  }, [reviewsLoadable.state, tagState, setTagType]);

  
  // Extract plate data when it is available
  const reviews = reviewsLoadable.state === 'hasValue' ? reviewsLoadable.contents : [];

  return (
    <Box m={2}>
      {/* Header */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Header title="Reviews" subtitle="Recent Reviews" />
      </Box>

      {/* Loading Indicator */}
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* Reviews Grid */}
      {!loading && reviews.length > 0 && (
        <Grid container spacing={3}>
          {reviews.map((review) => (
            <Grid
              key={review.review_id}
              item
              xs={12}  // Full width on extra-small screens
              sm={6}   // 2 cards per row on small screens
              md={4}   // 3 cards per row on medium screens
              lg={3}   // 4 cards per row on large screens
              xl={2.4} // 5 cards per row on extra-large screens
            >
              <ExploreCard
                review={review}
                plate={review.plate}
                user_id={review.user_id}
                setOpen={setOpen}
                setCurrentRestaurant={setCurrentRestaurant}
                townRank={0}
                isSaved={false}
                handleSavePlate={async (plateId) => {
                  console.log(`Saving plate: ${plateId}`);
                  setPlatesRefresh(new Date().getTime().toString());
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {plate.state === "hasValue" &&
      <PlateForm open={appState.plateDialogOpen} plate={plate} />
      }

      <EateryForm currentRestaurant={currentRestaurant}/>
      {/* No Plates Available */}
      {!loading && reviews.length === 0 && (
        <Typography variant="h6" color="textSecondary" align="center" mt={4}>
          No reviews available.
        </Typography>
      )}
    </Box>
  );
}

export default Reviews;
