export const locationBiasF = { //Fayetteville AR
    circle: {
        center: {
            latitude: "36.082157",
            longitude: "-94.171852"
        },
        "radius": "45000"
    }
 }
 export const locationBiasR = { //Rogers AR
    circle: {
        center: {
            latitude: "36.333412",
            longitude: "-94.125809"
        },
        "radius": "45000"
    }
 }
 export const locationBiasB = { //Rogers AR
    circle: {
        center: {
            latitude: "36.372852",
            longitude: "-94.208817"
        },
        "radius": "45000"
    }
 }
 export const locationBiasS = { //Rogers AR
    circle: {
        center: {
            latitude: "36.186745",
            longitude: "-94.128815"
        },
        "radius": "45000"
    }
 }
 export const locationBiasA = { //Austin TX
    circle: {
        center: {
            latitude: "30.2135335",
            longitude: "-97.87451969999999"
        },
        "radius": "33000"
    }
 }