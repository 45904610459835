import { Autocomplete, Backdrop, Box, Button, Card, CardMedia, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, LinearProgress, MenuItem, Select, Slider, Tab, Tabs, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { recoilState } from "../dataStructure";
import Eatery, { getAllEateries, getBrandEateries } from "../model/eatery";
import { pickImage } from "../model/photos";
import Tag, { getTags, putTag, tagRefreshState } from "../model/tag";
import { tokens } from "../theme";
import RatingGrid from "./ratingGrid";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Brand, { blankBrand, deleteBrandById, selectedBrandId, brandRefreshState, putBrand, getBrandPlateTemplates, PlateTemplate } from "../model/brand";
import LocationCard from "./LocationCard";
import BrandPlatesForm from "./BrandPlatesForm";
import Plate, { createNewPlate, putPlates } from "../model/plates";
// import { CardMedia } from "material-ui";

function BrandForm({ open }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [appState, setAppState] = useRecoilState(recoilState);
    const restaurants = useRecoilValueLoadable<Eatery[]>(getBrandEateries);
    const plates = useRecoilValueLoadable(getBrandPlateTemplates);
    const tags = useRecoilValueLoadable<Tag[]>(getTags);
    const [brandTags, setBrandTags] = useState([]);
    const [currentBrand, setCurrentBrand] = useState({ ...blankBrand });
    const [brandId, setBrandId] = useState("");
    const [name, setName] = useState("");
    const [eateryCount, setEateryCount] = useState(0);
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const setSelectedBrandId = useSetRecoilState(selectedBrandId);
    const setBrandsRefresh = useSetRecoilState(brandRefreshState);
    const hiddenPhotoFileInput = useRef(null);
    const [brandUpdated, setBrandUpdated] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleLoading = async () => {
        setLoading(true);
    };
    const handleLoaded = () => setLoading(false);
    const handleClose = () => {
        setAppState({ ...appState, brandDialogOpen: false });
    }

    useEffect(() => {
        console.log(appState.currentBrand);
        setCurrentBrand({ ...appState.currentBrand });
    }, [appState.currentBrand]);

    // useEffect(() => {
    //     console.log(appState.currentBrand);
    //     setSelectedBrandId(currentBrand.brand_id);
    // }, [currentBrand]);

    useEffect(() => {
        // this instanitates the selected brand when it changes
        setSelectedBrandId(currentBrand.brand_id);
        if (!appState.currentBrand) return

        setBrandId(appState.currentBrand.brand_id);
        setName(currentBrand.name);
        setDescription(currentBrand.description);
        setEateryCount(currentBrand.eatery_count);
        setBrandTags(currentBrand.tags);

    }, [currentBrand]);

    function setRefresh() {
        setBrandsRefresh(new Date().getTime().toString());
    }
    async function createBrand(): Promise<any> {
        handleLoading();
        const ts = new Date().getTime();
        const updatedBrand: Brand = {
            ...currentBrand,
            last_updated: ts,
            name: name,
            description: description,
            website: website,
        }
        console.log("new brand");
        console.log(updatedBrand);
        var response = await putBrand(updatedBrand);
        console.log(response);
        // setCurrentBrand({ ...updatedBrand });
        setAppState({ ...appState, currentBrand: { ...response } });
        setBrandUpdated(true);
        handleLoaded();
        return
    }
    async function saveBrand(): Promise<any> {
        handleLoading();
        const ts = new Date().getTime();
        const updatedBrand: Brand = {
            ...currentBrand,
            last_updated: ts,
            name: name,
            description: description,
            website: website,
        }
        await putBrand(updatedBrand);
        setBrandUpdated(true);
        await new Promise(resolve => setTimeout(resolve, 500));
        handleLoaded();
        return
    }

    async function saveBrandPlates(plateTemplate: PlateTemplate, updatedPlates: Plate[]): Promise<any> {
        
        handleLoading();
        console.log("Save plate template");
        console.log(plateTemplate);
        console.log(plateTemplate.plates);
        console.log(updatedPlates);
        const template = plateTemplate.template;
        delete template.restaurant_ids;
        await createNewPlate(template);
        await putPlates(updatedPlates);
        
        // const ts = new Date().getTime();
        // const updatedBrand: Brand = {
        //     ...currentBrand,
        //     last_updated: ts,
        //     name: name,
        //     description: description,
        //     website: website,
        // }
        // await putBrand(updatedBrand);
        // setBrandUpdated(true);
        // await new Promise(resolve => setTimeout(resolve, 500));
        // handleLoaded();
        // return
        await new Promise(resolve => setTimeout(resolve, 500));
        handleLoaded();
    }

    // Tab Functions
    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`brand-tabpanel-${index}`}
            aria-labelledby={`brand-tab-${index}`}
            style={{overflow: "hidden", maxHeight: "100%"}}
            {...other}
          >
            {value === index && (
              <Box sx={{maxHeight: "100%", overflow: "hidden", overflowY: "auto"}}>
                {children}
              </Box>
            )}
          </div>
        );
      }
      const [currentTab, setCurrentTab] = useState(0);

      const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
      };
    return (
        <Box sx={{ borderRadius: 30 }}>
            <Dialog
                key={currentBrand.brand_id}
                autoFocus
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
                sx={{ margin: "10px !important" }}
                PaperProps={{
                    style: {
                        backgroundColor: colors.opbrand[200],
                        boxShadow: 'none',
                        borderRadius: 20,
                        marginLeft: 40,
                        marginRight: 40
                    },
                }}
                maxWidth="xl"
            >
                {/* Form Tital and Ratings Data */}
                {(currentBrand) &&
                    <DialogActions sx={{ backgroundColor: colors.opbrand[300], flexDirection: "row-reverse" }}>
                        <CloseIcon onClick={(e) => {
                            if (brandUpdated) {
                                setBrandUpdated(false);
                                setRefresh();
                            }
                            handleClose();
                        }} color="secondary" />
                    </DialogActions>
                }
                {currentBrand &&
                    <DialogTitle id="form-dialog-title" sx={{ display: 'flex' }}>
                        <Typography
                            color={colors.grey[100]}
                            fontWeight="bold"
                            sx={{ flex: 3 }}
                        >
                            {currentBrand && currentBrand.brand_id !== "" ? currentBrand.name : ("Add a Brand")}</Typography>
                        <Tabs value={currentTab} onChange={handleTabChange} aria-label="Edit Brand Options">
                            <Tab label="Overview" />
                            <Tab label="Plates" />
                            <Tab label="Locations" />
                        </Tabs>
                    </DialogTitle>
                }

                {/* Form Content */}
                {(currentBrand) &&
                    <DialogContent sx={{display: 'flex', flexDirection: "column", maxHeight: "100vh", overflow: "hidden"}}>
                    <CustomTabPanel value={currentTab} index={0}>
                        {/* Form Content Left Box with ratings */}
                        <Box sx={{ width: "100%", display: 'grid', gap: 5, gridTemplateColumns: '2fr 1fr' }}>
                            <Box>
                                <Box>
                                    <TextField
                                        label="Brand Name"
                                        value={name}
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setName(event.target.value);
                                        }}
                                    />
                                    <TextField
                                        label="Website"
                                        value={website}
                                        fullWidth
                                        sx={{ mt: 1 }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setWebsite(event.target.value);
                                        }}
                                    />
                                    <TextField
                                        label="Brand Description"
                                        value={description}
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        sx={{ mt: 1 }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setDescription(event.target.value);
                                        }}
                                    />
                                    {tags.state === "hasValue" &&
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            sx={{ mt: 1 }}
                                            options={tags.contents.map((tag) => (tag.tag))}
                                            getOptionLabel={option => option}
                                            value={brandTags}
                                            onChange={(event: any, newValue: string[] | null) => {
                                                newValue.forEach((newTag) => {
                                                    console.log(newTag);
                                                    console.log(tags.contents.findIndex((tag) => tag.tag === newTag));
                                                    if (tags.contents.findIndex((tag) => tag.tag === newTag) === -1)
                                                        putTag({ tag: newTag, tag_type: "plate_tag" });
                                                });
                                                setBrandTags(newValue);
                                            }}
                                            renderInput={params => (
                                                <TextField {...params} label="Tags" fullWidth />
                                            )}
                                        />
                                    }
                                </Box>

                                {/* <RatingGrid brand={currentBrand} /> */}
                            </Box>


                            {/* Right Column */}
                            <Box sx={{ mt: 1, pb: 8 }}>
                                {(restaurants.state === "hasValue") &&
                                    <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: '1fr'}}>
                                        {restaurants.contents.map((eatery) => 
                                            <LocationCard eatery={eatery} key={"location-card-" + eatery.restaurant_id} />
                                        )}
                                    </Box>
                                }
                            </Box>

                        </Box>
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={1}><BrandPlatesForm save={saveBrandPlates} /></CustomTabPanel>
                <CustomTabPanel value={currentTab} index={2}></CustomTabPanel>
                    </DialogContent>
                }
                {(currentBrand) &&
                    <DialogActions>
                    {loading &&
                        <Box sx={{ width: "100%" }}>
                            <LinearProgress color="secondary" />
                        </Box>}
                        <Button startIcon={<SaveIcon />} onClick={() => {
                            if (currentTab === 0) {
                                if (currentBrand.brand_id === "")
                                createBrand()
                                else
                                saveBrand()
                            }
                            // if (currentTab === 1) {
                            //     saveBrandPlates()
                            // }
                        }}
                        disabled={(currentTab !== 0)}
                        color="secondary">
                            <Typography
                                fontWeight="bold">Save</Typography>
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleLoaded}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export default BrandForm;