import { Backdrop, Box, Card, CardContent, CardMedia, CircularProgress, SxProps, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { getBrandPlateTemplates, selectedPlateTemplateId } from "../model/brand";
import PlateCard from "./PlateCard";
import { useEffect, useState } from "react";
import Plate from "../model/plates";
import CustomTabPanel from "./CustomTab";
import BrandPlateForm from "./BrandPlateForm";
import BrandPlatePhotoForm from "./BrandPlatePhotoForm";

const BrandPlatesForm = ({save}) => {
    const plates = useRecoilValueLoadable(getBrandPlateTemplates);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const setPlateTemplate = useSetRecoilState(selectedPlateTemplateId);
    const [plateToEdit, setPlateToEdit] = useState<Plate | null>(null);
    const [plateFormVisible, setPlateFormVisible] = useState<boolean>(true);
    const [viewUiLayout, setViewUiLayout] = useState<string>("repeat(4, 1fr)");
    const [formUiLayout, setFormUiLayout] = useState<string>('5fr 0fr');

    useEffect(() => {
        if (plateToEdit) {
            setFormUiLayout("4fr 2fr");
            setViewUiLayout("repeat(auto-fill,minmax(180px,1fr))");
            setPlateFormVisible(true);
        } else {
            setFormUiLayout("6fr 0fr");
            setViewUiLayout("repeat(auto-fill,minmax(180px,1fr))");
            setPlateFormVisible(false);
        }
    }, [plateToEdit, setFormUiLayout]);

    const editPlate = (plate) => {
        // setPlateFormVisible(true);
        // setPlateTemplate(plate.plate_template_id);
        // setPlateToEdit(plate);
        // return;
        if (plateToEdit && plate.plate_id === plateToEdit.plate_id) {
            setPlateToEdit(null);
            setPlateTemplate("");
            return;
        } else {
            setPlateFormVisible(false);
            setPlateTemplate(plate.plate_template_id);
            setPlateToEdit(plate);
            return;
        }
    }

    const [currentTab, setCurrentTab] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setCurrentTab(newValue);
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: "column"}}>
        <Box sx={{ display: 'grid', gap: 0, gridTemplateColumns: formUiLayout}}>
            {plates.state === "hasValue" &&
                <Box sx={{ display: 'grid', gap: 5, gridTemplateColumns: viewUiLayout,  maxHeight: "100%", overflowY: "auto", paddingRight: 1, paddingBottom: 30}}>
                    {plates.contents && plates.contents.map((plate) =>
                        <PlateCard plate={plate.template} onClick={() => editPlate(plate)} cardRaised={plateToEdit ? plate.template.plate_id === plateToEdit.plate_id : false} key={"plate-card-" + plate.template.plate_template_id} />
                    )}
                </Box>
            }
            {plateFormVisible && plates.state === "hasValue" &&
            <Box sx={{ display: 'flex', flexDirection: "column", overflow: "clip", position: "sticky", top: 0, maxHeight: "100vh" }}>
                <Tabs value={currentTab} onChange={handleTabChange} aria-label="Edit Brand Plate Options">
                    <Tab label="Details" />
                    <Tab label="Photos" />
                </Tabs>
                <CustomTabPanel value={currentTab} index={0} label={`edit-details-form-${plateToEdit ? plateToEdit.plate_id : "unselected"}`}>
                    {plateToEdit &&
                        <BrandPlateForm plate={plateToEdit} save={save} />
                    }
                </CustomTabPanel>
                <CustomTabPanel value={currentTab} index={1} label={`edit-photos-form-${plateToEdit ? plateToEdit.plate_id : "unselected"}`}>
                <BrandPlatePhotoForm plate={plateToEdit} save={save} />
                </CustomTabPanel>
            </Box>
            }
        </Box>
            {plates.state !== "hasValue" &&
             <Box sx={{ display: 'flex', gap: 0, alignItems: "center", justifyContent: "center", maxHeight: "100vh"}}>
            <CircularProgress color="primary" />
            </Box>
            }
        </Box>
    );
};

export default BrandPlatesForm;
