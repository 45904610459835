import { Autocomplete, Box, Checkbox, FormControl, FormGroup, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, TextField, useTheme, Button, Tooltip } from "@mui/material";
import Eatery, { blankEatery, getAllEateries, getBrandEateries } from "../model/eatery";
import Plate, { blankPlate, platesRefreshState, selectedPlateId } from "../model/plates";
import Tag, { getTags, putTag, tagRefreshState } from "../model/tag";
import { useState, useRef, useEffect } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { recoilState } from "../dataStructure";
import { tokens } from "../theme";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { RemoveCircle } from "@mui/icons-material";
import { pickImage, uploadPhoto } from "../model/photos";
import SaveIcon from '@mui/icons-material/Save';

function BrandPlatePhotoForm({plate, save}) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [appState, setAppState] = useRecoilState(recoilState);
    const [currentPlate, setCurrentPlate] = useState({ ...plate.template });
    const [plateId, setPlateId] = useState(currentPlate.plate_id);
    const [photos, setPhotos] = useState(currentPlate.photos);
    const [photoUrl, setPhotoUrl] = useState(currentPlate.photo_url);
    const [stickyPhoto, setStickyPhoto] = useState(currentPlate.stickyPhoto);
    const [currentPhoto, setCurrentPhoto] = useState(currentPlate.photo_url);
    const hiddenPhotoFileInput = useRef(null);
    const [updatedPlates, setUpdatedPlates] = useState<Plate[]>(plate.plates ? [...plate.plates] : []);
    const [loading, setLoading] = useState(false);

    const handleLoading = async () => {
        setLoading(true);
    };
    const handleLoaded = () => setLoading(false);
    const handleClose = () => {
        setAppState({ ...appState, plateDialogOpen: false });
    }
    useEffect(() => {
        if (updatedPhotos.length === plate.template.photos.length) return
        const plates = [];
        updatedPlates.forEach((plate) => {
            if (plate.photos.length === 0) {
                plates.push({...plate,
                    photos: photos,
                    photo_url: photoUrl,
                    photo_is_sticky: stickyPhoto});
            } else {
                let platePhotos = [...plate.photos];
                for (let pic in photos) {
                    if (!plate.photos.find((photo) => photo === photos[pic])) {
                        if (photos[pic] === photoUrl) {
                            platePhotos.unshift(photos[pic]);
                        }
                        else {
                            platePhotos.push(photos[pic]);
                        }
                    }
                }
                plates.push({...plate,
                    photo_url: photoUrl,
                    photo_is_sticky: stickyPhoto,
                    photos: platePhotos
                });
            }
        });
        setUpdatedPlates(plates);
    }, [photos, photoUrl, stickyPhoto]);

    useEffect(() => {
        console.log(updatedPlates);
    }, [updatedPlates]);
    async function uploadPhoto(event) {
        handleLoading();
        // await new Promise(r => setTimeout(r, 2000));
        let file = event.target.files[0];
        console.log(file);
        const results = await pickImage(file, "op-admin");
        
        console.log(results);
        newPhotoUrl = results.uri;
        updatedPhotos.push(newPhotoUrl);
        setPhotos(updatedPhotos);
        setCurrentPhoto(newPhotoUrl);
        // savePlate();
        handleLoaded();
    }
    function onDragEnd(result) {
        console.log(result);
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }
        const reorder = (list, startIndex, endIndex) => {
            const result = Array.from(list);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);

            return result;
        };
        const reorderedPhotos = reorder(
            photos,
            result.source.index,
            result.destination.index
        );

        setPhotos([...reorderedPhotos]);
    }
    let updatedPhotos = [...photos];
    let newPhotoUrl = "";
    return (
        <Box>
        {/* Photos Right Column move this to component */}
        {!loading &&
        <Box sx={{ mt: -1 }}>
        <Box sx={{ position: "relative" }}>
            {plateId !== "" &&
                <Box sx={{ position: "absolute", right: 5, top: 5 }}>
                    {/* <AddAPhotoIcon
                    onClick={event => { hiddenPhotoFileInput.current.click() }}
                    sx={{ color: colors.redAccent[400], fontSize: "3em", m: 0 }}></AddAPhotoIcon> */}
                    <Button variant="contained" color="secondary" sx={{ fontWeight: "Bold" }}  onClick={() => { hiddenPhotoFileInput.current.click(); }}>Add Photo</Button>
                    <input type="file"  
                        ref={hiddenPhotoFileInput}
                        name={plateId}
                        onChange={uploadPhoto}
                        hidden />
                </Box>
            }
            <img src={currentPhoto} style={{ objectFit: "cover", width: "100%", aspectRatio: "4/3", display: "block" }} />
            {plateId !== "" &&
                <Box sx={{ position: "absolute", p: 0, right: 0, bottom: 0, width: "100%", height: "2em", color: colors.redAccent[900], backgroundColor: "rgba(0,0,0,.6)" }}>
                    
                    {currentPhoto === photoUrl ?
                        <Tooltip sx={{ position: "relative", height: "2em", }} title="Current Default Photo">
                            <StarIcon sx={{ color: colors.redAccent[600], fontSize: "2em !important", m: 0 }}></StarIcon>
                        </Tooltip>
                        :
                        <Tooltip sx={{ position: "relative", height: "2em", }} title="Set as default photo">
                        <StarBorderIcon
                            onClick={() => {
                                setPhotoUrl(currentPhoto);
                                updatedPhotos.splice(updatedPhotos.indexOf(currentPhoto), 1);
                                updatedPhotos.unshift(currentPhoto);
                                setPhotos(updatedPhotos);
                                setStickyPhoto(true);
                            }}
                            sx={{ position: "absolute", left: 0, color: colors.redAccent[900], fontSize: "2em" }}></StarBorderIcon>
                        </Tooltip>
                    }
                    {currentPhoto !== photoUrl &&
                        <RemoveCircle
                            onClick={() => {
                                const delIndex = updatedPhotos.findIndex((photo) => photo = currentPhoto);
                                updatedPhotos.splice(updatedPhotos.indexOf(currentPhoto), 1);
                                setPhotos(updatedPhotos);
                            }}
                            sx={{ position: "absolute", right: 0, color: colors.redAccent[600], fontSize: "2em" }}></RemoveCircle>
                    }
                    {/* <Typography sx={{ fontSize: "9px", m: "0 40px 0 40px", maxWidth: "100%", textAlign: "center" }}>{currentPhoto}</Typography> */}
                </Box>
            }
        </Box>
        <Box>
            {(photos.length > 0) &&

                <DragDropContext onDragEnd={onDragEnd}>
                    <Box sx={{ display: 'grid', gap: 1, gridTemplateColumns: 'repeat(5, 1fr)', marginTop: 2 }}>
                        {photos.map((photo, index) => (

                            <Droppable droppableId={"photodrop_" + index} direction="horizontal" key={"photodrop_" + index} ignoreContainerClipping>
                                {provided => (
                                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                                        <Draggable
                                            {...provided.droppableProps}
                                            draggableId={photo}
                                            index={index}
                                            key={"box_" + photo}>
                                            {provided => (
                                                <Box ref={provided.innerRef} {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <img src={photo}
                                                        style={{ objectFit: "cover", width: "100%", aspectRatio: "4/ 3" }}
                                                        key={photo}
                                                        onClick={() => {
                                                            setCurrentPhoto(photo);
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        </Draggable>
                                        {provided.placeholder}
                                    </Box>
                                )
                                }
                            </Droppable>
                        ))}
                    </Box>
                </DragDropContext>
            }
        </Box>
    </Box>
}
<Button startIcon={<SaveIcon />}
                    onClick={() => {save({
                        ...currentPlate,
                        template: {
                            ...currentPlate,
                            photos: photos,
                            photo_url: photoUrl,
                            photo_is_sticky: stickyPhoto
                        }
                    },
                        updatedPlates)
                }
                    }
                    color="primary">Save Plate</Button>
    </Box>
    );
  }
export default BrandPlatePhotoForm;
