import { Tabs, Tab, Grid, Autocomplete, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, MenuItem, Select, Slider, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import { recoilState } from "../dataStructure";
import Eatery, { blankEatery, getAllEateries } from "../model/eatery";
import { pickImage } from "../model/photos";
import User, { blankUser, selectedUserId, usersRefreshState } from "../model/user";
import Tag, { getTags, putTag, tagRefreshState } from "../model/tag";
import { tokens } from "../theme";
// import RatingGrid from "./ratingGrid";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { RemoveCircle } from "@mui/icons-material";
import RatingGrid from "./ratingGrid";
import UserGrid from "./userGrid";
import UserReviewGrid from "./userReviewGrid";

function UserForm({ open }) {
    const theme = useTheme();
    const [appState, setAppState] = useRecoilState(recoilState);
    const restaurants = useRecoilValueLoadable<Eatery[]>(getAllEateries);
    const tags = useRecoilValueLoadable<Tag[]>(getTags);
    const [currentUser, setCurrentUser] = useState({ ...blankUser });
    const [userId, setUserId] = useState("");
    const [name, setName] = useState("");
    const [editUser, setEditUser] = useState(false);
    const [rating, setRating] = useState("0.0");
    const [ratingCount, setRatingCount] = useState("0");
    const [eatery, setEatery] = useState({ ...blankEatery });
    const [photos, setPhotos] = useState([]);
    const [photoUrl, setPhotoUrl] = useState("https://d3mfg3sbygk1hl.cloudfront.net/temp-user.png");
    const [stickyPhoto, setStickyPhoto] = useState(false);
    const [currentPhoto, setCurrentPhoto] = useState("");
    const [userTags, setUserTags] = useState([]);
    const [userStatus, setUserStatus] = useState(0);
    const setSelectedUserId = useSetRecoilState(selectedUserId);
    const setTagsRefresh = useSetRecoilState(tagRefreshState);
    const setUsersRefresh = useSetRecoilState(usersRefreshState);
    const hiddenPhotoFileInput = useRef(null);
    const [userUpdated, setUserUpdated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const handleLoading = async () => {
        setLoading(true);
    };
    const handleLoaded = () => setLoading(false);
    const handleClose = () => {
        setAppState({ ...appState, userDialogOpen: false });
        setEditUser(false);
        setActiveTab(0);
    }

    const toggleEditUser = () => {
        setEditUser(prevEditUser => !prevEditUser);
    };

    useEffect(() => {
        console.log(appState.currentUser);
        setCurrentUser({ ...appState.currentUser });
    }, [appState.currentUser]);

    useEffect(() => {
        console.log(appState.currentUser);
        setSelectedUserId(currentUser.user_id);
    }, [currentUser]);

    useEffect(() => {
        console.log("Active Tab:", activeTab);
    }, [activeTab]);

    let updatedPhotos = [...photos];
    let newPhotoUrl = "";

    useEffect(() => {
        // this instanitates the selected user when it changes
        if (!appState.currentUser) return
        console.log(appState.currentUser);
        setUserId(appState.currentUser.user_id);
        // setEatery(appState.currentUser.eatery || blankEatery);
        // setName(currentUser.name);

    }, [currentUser]);

    const marks = [
        {
            value: -2,
            label: 'Archive',
        },
        {
            value: -1,
            label: 'Moderate',
        },
        {
            value: 0,
            label: 'Approved',
        },
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 2,
            label: 'Featured',
        },
    ];

    function setRefresh() {
        setTagsRefresh(new Date().getTime().toString());
        setUsersRefresh(new Date().getTime().toString());
    }
    async function remove(): Promise<any> {
        // need buildout on backend
        return
    }
    async function saveUser(): Promise<any> {
        handleLoading();
        const updatedUser: User = {
            ...currentUser,
        }
        // needs buildout in model
        await new Promise(resolve => setTimeout(resolve, 500));
        handleLoaded();
        return
    }
    async function uploadPhoto(event) {
        handleLoading();

        let file = event.target.files[0];
        console.log(file);
        const results = await pickImage(file, "op-admin");
        // needs buildout in model

        saveUser();
    }


    return (
        <Box>
            <Dialog
                key={currentUser.user_id}
                autoFocus
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullScreen
                maxWidth="xl"
                sx={{
                    margin: "10px !important",
                    left: "270px",
                    borderRadius: "3px",
                    overflow: "hidden"
                }}
            >
                {/* Form Tital and Ratings Data */}
                {(currentUser) &&
                    <DialogActions>
                        {loading &&
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                            </Box>}
                        <Box sx={{
                            display: "flex",
                            width: "100%"
                        }}>
                            <Box sx={{
                                width: "50%",
                                flex: "1 1 50%"
                            }}>
                                <Button onClick={() => {
                                    if (userUpdated) {
                                        setUserUpdated(false);
                                        setRefresh();
                                    }
                                    handleClose();
                                }}
                                    sx={{

                                    }}
                                >
                                    <img
                                        src="icon-arrow-left.svg"
                                        alt="Close"
                                        width="15"
                                        height="15"
                                    />
                                    <Typography
                                        sx={{
                                            textTransform: "none",
                                            color: "#DB0032",
                                            marginLeft: "5px",
                                            fontWeight: "900",
                                            fontSize: "14px"
                                        }}>
                                        Back
                                    </Typography>
                                </Button>
                            </Box>

                            <Box sx={{
                                width: "50%",
                                flex: "1 1 50%",
                                display: "flex",
                                justifyContent: "flex-end"
                            }}>
                                {userStatus < 1 &&
                                    <Button onClick={() => {
                                        return window.confirm(`Are you sure you want to delete ${currentUser.first_name + " " + currentUser.last_name}?`) ? remove() : null;
                                    }}

                                        sx={{
                                            textTransform: "none",
                                            color: "#DB0032",
                                            marginLeft: "5px",
                                            fontWeight: "900",
                                            fontSize: "14px"
                                        }}>
                                        <img
                                            src="icon-x.svg"
                                            alt="Remove"
                                            width="15"
                                            height="15"
                                        />
                                        <Typography
                                            sx={{
                                                textTransform: "none",
                                                color: "#DB0032",
                                                marginLeft: "5px",
                                                fontWeight: "900",
                                                fontSize: "14px"
                                            }}>
                                            Remove
                                        </Typography>
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </DialogActions>
                }
                {currentUser &&
                    <DialogTitle id="form-dialog-title" sx={{
                        display: 'flex',
                        margin: "0",
                        padding: "0 18px 10px 18px",
                        lineHeight: "1"
                    }}>
                        <Typography
                            sx={{
                                flex: 3,
                                fontSize: "30px",
                                color: "#25282F",
                                fontWeight: "900",
                                margin: "0",
                                padding: "0",
                                lineHeight: "1"
                            }}
                        >
                            {currentUser && currentUser.user_id !== "" ? (currentUser.first_name + currentUser.last_name) : ("Add a User")}</Typography>
                    </DialogTitle>
                }

                <Box sx={{ borderBottom: "1px solid #F4F0EA", width: "100%" }}>
                    <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} sx={{
                        "& .MuiButtonBase-root": {
                            textTransform: "none",
                            fontWeight: "900",
                        }
                    }}>
                        <Tab label="Settings"></Tab>
                        <Tab label="Reviews"></Tab>
                    </Tabs>
                </Box>

                {/* Form Content */}
                {(currentUser) &&
                    <DialogContent>

                        {activeTab === 0 && (
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ border: "1px solid #F4F0EA", borderRadius: "6px", padding: "20px", marginBottom: "15px" }}>
                                    {editUser ? (
                                        <Box
                                            sx={{
                                                backgroundColor: "#F4F0EA",
                                                borderRadius: "6px",
                                                display: "flex",
                                                padding: "15px 25px",
                                                width: "100%",
                                                textAlign: "left",
                                                textTransform: "none",
                                                color: "black"
                                            }}>

                                            <Typography sx={{
                                                flex: "1"
                                            }}>
                                                Editing the user...</Typography>
                                            <Button onClick={() => {
                                                toggleEditUser();
                                            }}
                                                sx={{
                                                    backgroundColor: "white",
                                                    color: "#DB0032",
                                                    borderRadius: "4px",
                                                    display: "flex",
                                                    padding: "0 10px"
                                                }}>
                                                Cancel
                                            </Button>
                                            <Button onClick={() => {
                                                if (currentUser.user_id === "") {
                                                    // createUser();
                                                    toggleEditUser();
                                                } else {
                                                    saveUser();
                                                    toggleEditUser();
                                                }
                                            }}
                                                sx={{
                                                    backgroundColor: "#DB0032",
                                                    color: "white",
                                                    borderRadius: "4px",
                                                    display: "flex",
                                                    padding: "0 10px"
                                                }}>
                                                Save
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Button sx={{
                                            backgroundColor: "#F4F0EA",
                                            borderRadius: "6px",
                                            display: "flex",
                                            padding: "15px 25px",
                                            width: "100%",
                                            textAlign: "left",
                                            textTransform: "none",
                                            color: "black"
                                        }}
                                            onClick={toggleEditUser}
                                        >
                                            <Typography sx={{
                                                flex: "1"
                                            }}>
                                                Edit user details</Typography>
                                            <Box sx={{
                                                backgroundColor: "#DB0032",
                                                color: "white",
                                                borderRadius: "4px",
                                                display: "flex",
                                                padding: "0 10px"
                                            }}>
                                                Edit User
                                            </Box>
                                        </Button>
                                    )}

                                    <Box sx={{ display: "flex", padding: "20px 0", width: "100%" }}>
                                        <Box sx={{ width: "400px", display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center" }}>
                                            <Box sx={{ position: "relative", width: "200px", height: "200px", overflow: "hidden", borderRadius: "6px", marginTop: "10px", }}>
                                                <img src={process.env.REACT_APP_IMAGE_S3_BUCKET + currentUser.user_id + ".jpg"} style={{ objectFit: "cover", width: "100%", height: "100%", position: "absolute" }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: "100%" }}>


                                            {editUser ? (
                                                <Box>
                                                    <TextField
                                                        id="name-select-small"
                                                        label="First Name"
                                                        value={currentUser.first_name}
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setName(event.target.value);
                                                        }}
                                                    />
                                                    <TextField
                                                        id="name-select-small"
                                                        label="Last Name"
                                                        value={currentUser.last_name}
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            setName(event.target.value);
                                                        }}
                                                    />
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <Typography sx={{ fontSize: "22px", fontWeight: "900" }}>
                                                        {currentUser.first_name + " " + currentUser.last_name}
                                                    </Typography>
                                                    {currentUser.hometown &&
                                                        <Typography sx={{ fontSize: "22px", fontWeight: "900" }}>
                                                            {currentUser.hometown}
                                                        </Typography>
                                                    }
                                                    {currentUser.about &&
                                                        <Typography sx={{ fontSize: "22px", fontWeight: "900" }}>
                                                            {currentUser.about}
                                                        </Typography>
                                                    }
                                                </Box>
                                            )}

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {activeTab === 1 && (
                            <Box>

                                <UserReviewGrid user={currentUser} />
                            </Box>
                        )} 
                    </DialogContent>
                }

                {/* Bottom Buttons and Progress Bar */}
                {/* {(currentUser) &&
                    <DialogActions>
                        {loading &&
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress color="secondary" />
                            </Box>}
                        {userStatus < 1 &&
                            <Button onClick={(e) => {
                                return window.confirm(`Are you sure you want to delete ${currentUser.name}?`) ? remove() : null;
                            }}
                                color="warning">
                                Remove
                            </Button>
                        }
                        <Button onClick={(e) => {
                            if (userUpdated) {
                                setUserUpdated(false);
                                setRefresh();
                            }
                            handleClose();
                        }} color="warning">
                            Close
                        </Button>
                        <Button onClick={() => {
                            if (currentUser.user_id === "")
                                createUser()
                            else
                                saveUser()
                        }} color="secondary">
                            Save
                        </Button>
                    </DialogActions>
                } */}
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
                onClick={handleLoaded}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}

export default UserForm;