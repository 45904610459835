import { ButtonBase, Card, CardContent, CardMedia, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const PlateCard = ({ plate, onClick, cardRaised }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <Card key={"plate-card-" + plate.plate_template_id} sx={{ minHeight: "200px", backgroundColor: cardRaised ? colors.opbrand[100] : colors.opbrand[300] }} raised={cardRaised}>
            <ButtonBase onClick={onClick} sx={{ display: "block" }}>
                <CardMedia component="img" sx={{ width: "100%" }}
                    image={plate.photo_url}
                />
                <CardContent sx={{ color: cardRaised ? colors.grey[900] : colors.grey[100],display: 'flex', flexDirection: 'column', fontWeight: "bold" }}>
                    {plate.name}
                </CardContent>
            </ButtonBase>
        </Card>
    );
};

export default PlateCard;
